import React, { useState } from "react"
import ListStyles from "./ListStyles"
import cookie from "react-cookies"
import { getCookieExpirationDate } from "../../helpers/utils"

export default function ListGridToggle({ appProps }) {
  const listGridCookie = cookie.load('listGridOption')

  const [savedListGridOption, setSavedListGridOption] = useState(!!listGridCookie)
  const [currentOption, setCurrentOption] = useState(listGridCookie ? listGridCookie : appProps.productsPerRow)

  function saveListGridOption(option) {
    const expiration = getCookieExpirationDate(365);
    cookie.save('listGridOption', option, { expires: expiration })
    setSavedListGridOption(option)

    if ( option == 'list' ){
      let item = document.querySelectorAll('div.product-item');
      for (let elem of item) {
      	elem.classList.add('list');
      }
    } else {
      let item = document.querySelectorAll('div.product-item');
      for (let elem of item) {
      	elem.classList.remove('list');
      }
    }

  }

  let optionStyles = null
    if( currentOption == 'list') {
      optionStyles =
        <ListStyles
          productsPerRow={ 1 }
        />
    } else {
      optionStyles =
        <ListStyles
          productsPerRow={ appProps.productsPerRow }
        />
    }

  return (
    <>
      { optionStyles }
      <div className='list-grid-toggle'>
        <div  className={`icon list_icon ${currentOption == 'list' ? 'active' : '' }`}
              onClick={() => {
                saveListGridOption('list')
                setCurrentOption('list')
              }}>
        </div>
        <div  className={`icon grid_icon ${currentOption != 'list' ? 'active' : '' }`}
              onClick={() => {
                saveListGridOption('grid')
                setCurrentOption(appProps.productsPerRow)
              }}>
        </div>
      </div>
    </>
  )
}
