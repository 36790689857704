import React from 'react'
import OrderHistory from "../OrderHistory"

export default function Orders({
  appProps, pageTitle, listOrdersPath, listCompanyOrdersPath, showCompanyOrders
}) {
  return (
    <>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
      </div>

      <OrderHistory
        appProps={ appProps }
        listOrdersPath={ listOrdersPath }
        listCompanyOrdersPath={ listCompanyOrdersPath }
        showCompanyOrders={ showCompanyOrders }
      />
    </>
  )

}
