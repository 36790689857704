import React, { useEffect } from "react"
import ReactGA from "react-ga"

export default function GoogleLegacyTracking({ order, currency }) {

  useEffect( ()=> {
    ReactGA.ga('require', 'ecommerce');
    ReactGA.ga('ecommerce:addTransaction', {
      'id':       order.number,
      'revenue':  order.total_value,
      'tax':      order.vat_total_value
    });
    order.line_items.map((lineItem) => {
      return ReactGA.ga('ecommerce:addItem', {
        'id':       order.number,
        'name':     lineItem.sellable.presentation,
        'sku':      lineItem.sellable.sku,
        'category': lineItem.sellable.prototypeName,
        'quantity': lineItem.quantity,
        'currency': currency,
        'price':    lineItem.totalGross,
      });
    })
    ReactGA.ga('ecommerce:send');
    ReactGA.ga('ecommerce:clear');
  },[])

  return(
    <React.Fragment/>
  )

}
