export function calculateCartSubTotal(products){
  return products.reduce((sum, product) => sum + product.totalNet, 0.0)
}

export function calculateCartTax(products){
  return products.reduce((sum, product) => sum + product.totalTax, 0.0)
}

export function calculateCartWeight(products){
  return products.reduce((sum, product) => sum + product.totalWeight, 0.0)
}