import React, { useState, useEffect } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'

import { formatPriceWithCurrency } from "../../../helpers/utils"
import { default as CompanyBilling } from '../companies/steps/Billing'

export default function Billing(props) {
  const paymentMethods = props.paymentMethods.filter(paymentMethod => paymentMethod.cards.length > 0)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods.length > 0 ? paymentMethods[0] : null)
  const [cards, setCards] = useState(() => initializeCards())
  const [billingState, _setBillingState] = useState(props.currentBilling ? { vat: props.vat, billingAddress: { ...props.currentBilling } } : {})
  const [showAddressForm, setShowAddressForm] = useState(false)
  const [loading, setLoading] = useState(false)

  function initializeCards() {
    let initialCards = {}
    paymentMethods.forEach((p, i) => {
      initialCards[p.id] = p.cards
    })

    return initialCards
  }

  const setPrimaryCard = (card, paymentMethodId) => {
    const { setCurrentCardPath } = props

    if (!card.current) {
      let fd = new FormData()
      fd.append('card_details[id]', card.id)
      fd.append('card_details[payment_method_id]', paymentMethodId)

      Rails.ajax({
        type: 'POST',
        url: setCurrentCardPath,
        dataType: 'json',
        data: fd,
        success: res => {
          if (res.cards) {
            let newCards = { ...cards }
            newCards[paymentMethodId] = res.cards
            setCards(newCards)
          }
        },
        error: res => {
          appProps.flashMessage.show(res.error, 'error')
        }
      })
    }
  }

  const togglePaymentMethod = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod)
  }

  const destroyCard = (card, paymentMethodId) => {
    const { destroyCardPath } = props

    let fd = new FormData()
    fd.append('card_details[id]', card.id)

    Rails.ajax({
      type: 'POST',
      url: destroyCardPath,
      dataType: 'json',
      data: fd,
      success: res => {
        let newCards = { ...cards }
        newCards[paymentMethodId] = newCards[paymentMethodId].filter(c => c.id != card.id)
        setCards(newCards)
      },
      error: res => {
        appProps.flashMessage.show(res.error, 'error')
      }
    })
  }

  function setBillingState(updatedState) {
    _setBillingState({ ...billingState, ...updatedState })
  }

  function setBillingAddress(updatedAddress) {
    const newAddress = { ...billingState.billingAddress, ...updatedAddress }
    setBillingState({ billingAddress: newAddress })
  }

  function saveBillingAddress() {
    setLoading(true)
    const { saveAddressPath } = props
    let formData = new FormData()
    formData.append('company[vat]', billingState.vat)

    if (billingState.billingAddress) {
      formData.append('company[address_attributes][street]', billingState.billingAddress.address || '')
      formData.append('company[address_attributes][phone]', billingState.billingAddress.phone || '')
      formData.append('company[address_attributes][mobile_phone]', billingState.billingAddress.mobilePhone || '')
      formData.append('company[address_attributes][city]', billingState.billingAddress.city || '')
      formData.append('company[address_attributes][postal_code]', billingState.billingAddress.postalCode || '')
      formData.append('company[address_attributes][country_numeric_code]', billingState.billingAddress.country || '')
      formData.append('company[address_attributes][state_region_code]', billingState.billingAddress.state || '')

      if (billingState.billingAddress.id) {
        formData.append(`company[address_attributes][id]`, billingState.billingAddress.id)
      }
    }

    Rails.ajax({
      type: 'PATCH',
      url: saveAddressPath,
      data: formData,
      dataType: 'json',
      success: res => {
        if (res.company) {
          _setBillingState({
            vat: res.company.vat,
            billingAddress: { ...res.company.billingAddress }
          })
          if (showAddressForm) {
            setShowAddressForm(false)
          }
        }
      },
      error: res => {
        if (res.error) {
          appProps.flashMessage.show(res.error, 'error')
        }
      },
      complete: res => {
        setLoading(false)
      }
    })
  }

  return (
    <>
      <div className='section-title'>
        <h3>{ props.pageTitle }</h3>
      </div>

      <div className='card'>
        { billingState.billingAddress ?
          <div className='billing-info-wrapper'>
            <div className="heading flex-box items-center">
              <h3>{ props.pageTitle }</h3>
              { props.canEditBilling && !showAddressForm &&
                <div className="button plain" onClick={ () => setShowAddressForm(true) }>
                  <i className="fa-light fa-pencil" />
                </div>
              }
            </div>
            { !showAddressForm ?
              <div className='billing-address flex-column'>
                <div className='address flex-box flex-wrap'>
                  <div className='address-row flex-box flex-column flex-1'>
                    <div className='address-field-name'>
                      { props.appProps.translations.checkout.full_name }
                    </div>
                    <div>{ billingState.billingAddress.fullName }</div>
                  </div>
                  <div className='address-row flex-box flex-column flex-1'>
                    <div className='address-field-name'>
                      { props.appProps.translations.checkout.mobile_phone }
                    </div>
                    <div>{ billingState.billingAddress.mobile || billingState.billingAddress.mobilePhone }</div>
                  </div>
                  <div className='address-row flex-box flex-column flex-1'>
                    <div className='address-field-name'>
                      { props.appProps.translations.checkout.phone }
                    </div>
                    <div>{ billingState.billingAddress.phone }</div>
                  </div>
                </div>
                <div className='address flex-box flex-wrap'>
                  <div className='address-row flex-box flex-column flex-1'>
                    <div className='address-field-name'>
                      { props.appProps.translations.checkout.city }
                    </div>
                    <div>{ billingState.billingAddress.city }</div>
                  </div>
                  <div className='address-row flex-box flex-column flex-1'>
                    <div className='address-field-name'>
                      { props.appProps.translations.checkout.address }
                    </div>
                    <div>{ billingState.billingAddress.address }</div>
                  </div>
                  <div className='address-row flex-box flex-column flex-1' />
                </div>
              </div>
            : <CompanyBilling
                appProps={ props.appProps }
                company={ props.company }
                companyState={ billingState }
                setCompanyState={ setBillingState }
                setCompanyAddress={ setBillingAddress }
                saveSettings={ saveBillingAddress }
                countriesPath={ props.countriesPath }
                loading={ loading }
                setLoading={ setLoading }
              />
            }
          </div>
          :
          <div className='no_billing payment_icon'>
            <h2>{ props.appProps.translations.account.no_billing }</h2>
          </div>
        }
      </div>

      { paymentMethods.length > 0 &&
        <>
          <div className='section-title'>
            <h3>{ props.appProps.translations.account.billing_subtitle }</h3>
          </div>

          <div className='card'>
            <div className='payment-providers-wrapper'>
              <div className='vertical-tabs'>
                <div className='tab-head'>
                  { paymentMethods.map(paymentMethod => (
                    <div key={ paymentMethod.id }
                         className={ `vertical-tab-item ${ selectedPaymentMethod && (selectedPaymentMethod.id == paymentMethod.id) ? 'selected' : '' }` }
                         onClick={ () => togglePaymentMethod(paymentMethod) }>
                      { paymentMethod.title }
                    </div>
                  ))}
                </div>
                <div className='tab-body'>
                  <div id="payment-methods-list">
                    { selectedPaymentMethod && cards[selectedPaymentMethod.id] && cards[selectedPaymentMethod.id].length > 0 &&
                      <>
                        <div className="payment-method-title option">
                          <div className={ `name ${ selectedPaymentMethod.type }` }>
                            { selectedPaymentMethod.title }
                          </div>
                        </div>
                        <div className='tokenized-cards-wrapper'>
                          { cards[selectedPaymentMethod.id].map(card => (
                            <div
                              key={ card.id }
                              className={ `tokenized-card billing ${ card.current ? 'current' : '' }` }
                            >
                              <span
                                className="tokenized-card-details"
                                onClick={ () => setPrimaryCard(card, selectedPaymentMethod.id) }>
                                  <img src={ `https://storage.googleapis.com/reactive_files_eu/payment_processors/card_issuers/${ card.issuer }.svg` } style={{ width: 50 }}/>
                                  { ['amex', 'diners', 'discover', 'maestro', 'mastercard', 'visa'].includes(card.issuer) &&
                                    <div className='tokenized-card-pan'>
                                      { `********${ card.panLast }` }
                                    </div>
                                  }
                              </span>
                              { !card.current && <span className="delete-card-icon" onClick={ () => destroyCard(card, selectedPaymentMethod.id) }/> }
                            </div>
                            ))
                          }
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }

      { loading && <Loader /> }

    </>
  )
}
