import React from "react"

export default function StoreLocations({ appProps, storeLocations }) {

  return (
    <div className="all-stores">
      {
        storeLocations.map((location, idx) => {
          return (
            <div className="store flex-box items-center content-space-between" key={idx} style={{ padding: '10px 0px' }}>
              <div className="store-name flex-box items-start flex-column">
                <div>
                  { location.title }
                </div>
                { location.address &&
                  <div className='store-address addresses_icon flex-box items-center pointer'
                       onClick={ ()=> window.open(`https://www.google.com/maps/place/${location.address.split(' ').join('+')}`) }>
                    { location.address }
                  </div>
                }
              </div>
              <div className={`availability ${location.inStock ? 'in-stock' : 'out-of-stock' }`}>
                { location.inStock ? appProps.translations.products.in_stock : appProps.translations.products.out_of_stock }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}
