import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { StripeCheckoutForm, BankDetails } from "@reactiveonline/frontend_shared_components"

import { formatPriceWithCurrency } from "../../../helpers/utils"

export default class PaymentMethod extends React.Component {
  constructor(props) {
    super(props)

    if(this.props.stripePublishableKey) {
      this.stripePromise = loadStripe(this.props.stripePublishableKey)
    }
  }

  handleClick() {
    const { paymentMethod, updateSelectedPayment, clearErrors } = this.props
    clearErrors()
    updateSelectedPayment(paymentMethod)
  }

  render() {
    const {
      paymentMethod, selectedMethod, showCost, submitStripeCard, setStripeError,
      completeOrder, stripePaymentIntentClientSecret, stripePaymentIntentActionType, stripeSetupIntentClientSecret, stripeSetupIntentActionType, currency, orderId, appProps
    } = this.props
    const selected = selectedMethod.id === paymentMethod.id
    const selectClass = "checked_icon selected"

    return (
      <React.Fragment>
        <div className={`option ${selected && selectClass}`} onClick={ () => { this.handleClick() }}>
          <div className={`name ${paymentMethod.type}`}>
            { paymentMethod.presentation }
          </div>

          {
            paymentMethod.gross_handling_fee && paymentMethod.gross_handling_fee > 0 && showCost &&
              <div className="cost">
                { `+ ${formatPriceWithCurrency(paymentMethod.gross_handling_fee.toFixed(2), currency)}` }
              </div>
          }

          <div dangerouslySetInnerHTML={{__html: paymentMethod.description}} className="description"></div>
          { selected && paymentMethod.type === 'BankTransfer' &&
            <BankDetails
              appProps={ appProps }
              bankDetails={ paymentMethod.bankDetails }
              hideExtraInfo={ true }
            />
          }
        </div>
        {paymentMethod.type === "StripePayment" && selected &&
          <Elements stripe={this.stripePromise}>
            <StripeCheckoutForm
              appProps={ appProps }
              submitStripeCard={submitStripeCard}
              setStripeError={setStripeError}
              completeOrder={completeOrder}
              stripePaymentIntentClientSecret={stripePaymentIntentClientSecret}
              stripePaymentIntentActionType={stripePaymentIntentActionType}
              stripeSetupIntentClientSecret={stripeSetupIntentClientSecret}
              stripeSetupIntentActionType={stripeSetupIntentActionType}
              orderId={ orderId }
              type={ this.props.type }
              paymentMethodId={ paymentMethod.id }
              cardsPath = { this.props.cardsPath }
              setIsLoading = { this.props.setLoading }
              setCurrentCardPath = { this.props.setCurrentCardPath }
              billingDetails={ this.props.stripeBillingDetails }
            />
          </Elements>
        }
      </React.Fragment>
    )
  }
}
