import React, { useState, useEffect, useCallback } from "react"
import ContentLoader, { Facebook } from "react-content-loader"
import { Button } from "@material-ui/core"
import { initiateCheckoutAnalytics } from './../../helpers/analytics'
import { initiateLegacyCheckoutAnalytics } from './../../helpers/legacy_analytics'
import ProductPresentableTitle from '../shared/ProductPresentableTitle'

export default function MiniCart({ appProps, lineItemsCount }) {
  const [openCart, setOpenCart]         = useState(false)
  const [currentOrder, setCurrentOrder] = useState(null)
  const [loading, setLoading]           = useState(false)
  const [activeOrder, setActiveOrder]   = useState(lineItemsCount > 0 || false)
  const [lineItems, setLineItems] = useState(lineItemsCount)

  useEffect( () => {
    setLineItems(lineItemsCount)
    setActiveOrder(lineItemsCount > 0)
  }, [lineItemsCount])

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setOpenCart(false)
    }
  }, []);

  function fetchOrder() {
    setLoading(true)

    Rails.ajax({
      type: "GET",
      url: appProps.fetchOrderPath,
      dataType: "json",
      success: res => {
        if (res.order) {
          setCurrentOrder(res.order)
          setLineItems(res.order.lineItems.length)
        } else {
          setCurrentOrder(null)
          setActiveOrder(false)
          setOpenCart(false)
          setLineItems(0)
        }
        setLoading(false)
      },
      error: res => {
        setLoading(false)
      }
    })
  }

  function removeFromCart(line_item_id){
    setLoading(true)
    Rails.ajax({
      url: `/line_items/${line_item_id}/delete`,
      type: 'POST',
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      success: res => {
        setLoading(false)
        fetchOrder()
      }
    })
  }

  return (
    <>
      { (activeOrder && lineItems > 0) &&
        <a className="pointer cart_icon cart-drop" onClick={(e) => {
          e.preventDefault()
          fetchOrder()
          setOpenCart(prevState => !prevState)
        }}>
          <div className="count">
            { lineItems }
          </div>
        </a>
      }

      { openCart &&
        <>
          <div className={`cart-menu-overlay ${appProps.miniCartType}`} onClick={ ()=> setOpenCart(prevState => !prevState) } />
          <div className={`cart-menu ${appProps.miniCartType}`} style={{ top: appProps.headerHeight }}>
            { appProps.miniCartType == 'burger_opener' &&
              <div onClick={ ()=> setOpenCart(false) } className='pointer close_icon flex-box items-center content-center'/>
            }
            <div className='title'>
              <b>{ appProps.translations.cart.cart_heading }</b>
            </div>
          { loading ?
              <ContentLoader>
                <rect x="10" y="27"  rx="10" ry="10" width="100" height="19" />
                <rect x="10" y="57"  rx="17.5" ry="17.5" width="100" height="32" />
                <rect x="10" y="105" rx="10" ry="10" width="100" height="19" />
              </ContentLoader>
            :
              <>
                <div className='line-items'>
                  { currentOrder && currentOrder.lineItems.map( (line_item, index) => {
                    return (
                      <div key={ index } className='line-item flex-box' style={{ marginTop: 10 }}>
                        <div className='mini-cart-image'>
                          { line_item.sellable.images.length > 0 &&
                            <img src={ line_item.sellable.images[0].thumbnail } />
                          }
                        </div>
                        <div className='mini-cart-product flex-box flex-column flex-1'>
                          <div className='product-title flex-box content-start'>
                            <ProductPresentableTitle appProps={appProps} entity={line_item.sellable} entityIndex={index} />
                          </div>
                          <div className='product-quantity'>
                            { line_item.quantity } x { line_item.displayUnitGross }
                          </div>
                        </div>
                        <div className="delete-item">
                          <Button className="button plain delete-button"
                            onClick={ () => removeFromCart(line_item.id) }>
                          </Button>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className='totals flex-box flex-column'>
                  <div className='flex-box items-center content-space-between'>
                    <div>{ appProps.translations.cart.subtotal }</div>
                    <div>{ currentOrder ? currentOrder.subtotal : '' }</div>
                  </div>
                  <div className='flex-box items-center content-space-between'>
                    <div>{ appProps.translations.cart.vat }</div>
                    <div>{ currentOrder ? currentOrder.vatTotal : '' }</div>
                  </div>
                  <div className='flex-box items-center content-space-between'>
                    <div><b>{ appProps.translations.cart.total }</b></div>
                    <div><b>{ currentOrder ? currentOrder.total : '' }</b></div>
                  </div>
                </div>
              </>
            }
            <div className='button-wrapper'>
              <button className='button' onClick={ (e) =>
                  (window.location.href = appProps.checkoutPath,
                  appProps.googleMeasurementId ? initiateCheckoutAnalytics(currentOrder, e, appProps) : initiateLegacyCheckoutAnalytics(currentOrder, e, appProps))
              }>
                { appProps.translations.checkout.proceed_to_checkout }
              </button>
            </div>
          </div>
        </>
      }
    </>
  )
}
