import React, { useState, useEffect } from "react"
import ReactPixel from "react-facebook-pixel"
import ReactGA from "react-ga"
import GoogleTracking from "./GoogleTracking"
import GoogleLegacyTracking from "./GoogleLegacyTracking"

export default function OrderTrackers({
  order, currency, renderTrackers, skroutzAnalyticsActive, facebookPixelId, googleAnalyticsId, googleMeasurementId
}) {

  useEffect( ()=> {
    if ( renderTrackers ) {
      if( facebookPixelId ) {
        ReactPixel.track('Purchase', {
          content_type: 'product',
          currency:     `${currency.symbol}`,
          value:        `${order.total_value}`,
          content_ids:  `${order.number}`,
          num_items:    `${order.line_items.count}`,
          contents:     order.line_items.map((lineItem) => {
            return {
              name:                 lineItem.sellable.presentation,
              id:                   lineItem.sellable.sku,
              quantity:             lineItem.quantity,
              item_price:           lineItem.totalNet,
              product_vat:          lineItem.totalTax,
              total_product_vat:    lineItem.totalGross,
              total:                lineItem.totalGross
            }
          })
        })
      }
    }

    // Skroutz Analytics data
    if ( skroutzAnalyticsActive != null && skroutzAnalyticsActive != false) {
      // Send the Order data
      skroutz_analytics('ecommerce', 'addOrder', {
        order_id:      order.number,                       // Order ID. Required.
        revenue:       order.total,                        // Grand Total. Includes Tax and Shipping. Does not include payment costs.
        shipping:      order.ship_total,                   // Total Shipping Cost. Does not include payment costs.
        tax:           order.vat_total,                    // Total Tax.
        paid_by:       order.payment_method.presentation,  // [Optional] Payment method type, e.g. bank_transfer.
        paid_by_descr: order.payment_method.description    // [Optional] Payment method description, e.g. Bank transfer.
      });
      // Send the Line item Data
      order.line_items.map( (lineItem) => {
        skroutz_analytics('ecommerce', 'addItem', {
          order_id:   order.number,                         // Order ID. Required.
          product_id: lineItem.sellable.id,                 // Product ID. Required.
          name:       lineItem.sellable.presentation,       // Product Name. Required.
          price:      lineItem.sellable.displayTotalPrice,  // Price per Unit. Required.
          quantity:   lineItem.quantity                     // Quantity of Items. Required.
        });
      })
    }
  },[])

  return(
    <>
      { googleMeasurementId ?
        <GoogleTracking
          order={ order }
          currency={ currency }
        />
        :
        <>
          { googleAnalyticsId &&
            <GoogleLegacyTracking
              order={ order }
              currency={ currency }
            />
          }
        </>
      }
    </>
  )

}
