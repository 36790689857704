import React, { useState, useEffect } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'

export default function Users({ appProps, getCompanyUsersPath }) {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    Rails.ajax({
      type: 'GET',
      url: getCompanyUsersPath,
      success: res => {
        if(res.usersData) {
          setUsers(res.usersData)
        }
        setLoading(false)
      },
      error: res => {
        setLoading(false)
      }
    })
  }, [])

  return (
    <>
      <div className="heading" style={{ marginBottom: 20 }}>
        <h2>{ appProps.translations.company.users_tab }</h2>
        <div>{ appProps.translations.company.users_tab_subtitle }</div>
      </div>

      <div className="order_history_wrapper">
        <div className="history_headers">
          <div className="row email">
            <h6>{ appProps.translations.account.email_address }</h6>
          </div>
          <div className="row name">
            <h6>{ appProps.translations.checkout.full_name }</h6>
          </div>
        </div>

        {
          users.map((user, index) => {
            return (
              <div key={index} className="history_rows">
                <div className="row date">
                  <h6>{user.email}</h6>
                </div>
                <div className="row number">
                  <h6>{user.fullname && user.fullname !== '' ? user.fullname : (`${user.firstName || ''} ${user.lastName || ''}`.trim() || '-')}</h6>
                </div>
              </div>
            )
          })
        }
      </div>

      <div style={{ marginTop: 50 }}>
        { loading && <Loader size={'medium'} position={'center'} /> }
      </div>
    </>
  )

}
