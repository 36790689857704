import React from 'react'
import { Select, MenuItem, Box, Chip } from '@material-ui/core'

export default class PromotionRule extends React.Component {
  constructor(props){
    super(props)

    this.state={
      availableValues: [],
      id: this.props.rule.id,
      type: this.props.rule.type,
      values: this.props.rule.values
    }

    this.destroy = this.destroy.bind(this)
    this.getAvalableValues = this.getAvalableValues.bind(this)
    this.ruleChanged = this.ruleChanged.bind(this)
    this.save = this.save.bind(this)
    this.setType = this.setType.bind(this)
    this.setValues = this.setValues.bind(this)
  }

  componentDidMount(){
    this.getAvalableValues(null, this.state.type)
  }

  componentDidUpdate(prevProps, prevState){
    this.getAvalableValues(prevState.type, this.state.type)
  }

  destroy(){
    this.props.destroyRule(this.state.id)
  }

  getAvalableValues(prevType, currentType){
    if( (!currentType) || (prevType === currentType)){ return }

    Rails.ajax({
      type: "GET",
      url: `/letmein/promotion_rules/rule_values?rule=${currentType}`,
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      success: res => {
        this.setState({availableValues: res.values})
      }
    })
  }

  ruleChanged(){
    if(this.props.rule.type != this.state.type){
      return true
    }

    if (this.props.rule.values.size != this.state.values.size){
      return true
    }

    for(var [key, val] of this.props.rule.values){
      if(!this.state.values.has(key)) return true

      if(val._destroy != this.state.values.get(key)._destroy) return true
    }
    return false
  }

  save(){
    const { id, type, values, _destroy } = this.state
    this.props.saveRule(id, type, values, _destroy)

    if(this.props.isTemplate){
      this.setState({
        availableValues: [],
        type: null,
        values: []
      })
    }
  }

  setType(selected){
    let values = new Map(this.state.values)

    values.forEach((valueHash, promotable) => {
      if(valueHash.record_id){
        values.set(promotable, { record_id: valueHash.record_id, _destroy: '1'})
      }else{
        values.delete(promotable)
      }
    })

    this.setState({
      type: selected.target.value.value,
      values: values
    })
  }

  setValues(selected){
    let values = new Map()

    let vIndex
    this.state.values.forEach((details, promotable) => {
      vIndex = selected.findIndex(selectable => selectable.value == promotable.id && selectable.promotable_type == promotable.type)

      if(vIndex != -1){
        values.set(promotable, {record_id: details.record_id, _destroy: undefined})
      }else if(details.record_id){
        values.set(promotable, { record_id: details.record_id, _destroy: '1'})
      }
    })

    const promotables = Array.from(values.keys())
    let index
    for(const val of selected){
      index = promotables.findIndex(promotable => promotable.id == val.value && promotable.type == val.promotable_type)

      if(index === -1){
        values.set({id: val.value, type: val.promotable_type}, {})
      }
    }

    this.setState({values: values})
  }

  render(){
    const ruleOptions = this.props.rule_types.map(rule => {
      return { value: rule[0], label: rule[1] }
    })
    const valueOptions = this.state.availableValues.map(v => {
      return { value: v[0], label: v[1], promotable_type: v[2].promotable_type }
    })

    const selectedType = ruleOptions.find(q => q.value === this.state.type) || null
    let stateValues = []

    this.state.values.forEach((v, promotable) => {
      if(!v._destroy){
        stateValues.push(promotable.id)
      }
    })

    return (
      <div className="row">
        <div id="new-rule-type" className='input-field col s4'>
          <label htmlFor="type" className='active'>Type</label>
          <Select
            className='select2'
            style={{ minWidth: 200 }}
            disabled={ this.props.disabled }
            value={ selectedType || '' }
            onChange={ this.setType }
          >
            { ruleOptions.map( (option, index) => (
              <MenuItem key={ index } value={ option }>
                { option.label }
              </MenuItem>
            ))}
          </Select>
        </div>

        { valueOptions.length > 0 &&
          <div id="new-rule-values" className='input-field col s4'>
            <label htmlFor="new-rule-values" className='active'>{this.props.rule_value_labels[this.state.type]}</label>

            <Select
              className='select2'
              style={{ minWidth: 200 }}
              disabled={ this.props.disabled }
              multiple
              value={ valueOptions.filter(q => stateValues.includes(q.value)) }
              onChange={ event => this.setValues(event.target.value) }
              renderValue={ selected => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  { selected.map( item => (
                    <Chip key={ item.value } label={ item.label }/>
                  ))}
                </Box>
              )}
            >
              { valueOptions.map( (option, index) => (
                <MenuItem key={ index } value={ option }>
                  { option.label }
                </MenuItem>
              ))}
            </Select>
          </div>
        }

        { this.ruleChanged() &&
          <div className="col s4">
            <button id="add-new-rule" type="button" className="btn" onClick={this.save}> Add new rule </button>
          </div>
        }

        { this.props.destroyRule && !this.props.disabled &&
          <div className="col s4">
            <button id="destroy-rule" type="button" className="btn" onClick={this.destroy}> <i className='fa-light fa-trash'></i> </button>
          </div>
        }
      </div>
    )
  }
}
