import React from 'react'
import OrderHistoryList from './OrderHistoryList'

export default function OrderHistory({
  appProps, listOrdersPath, listCompanyOrdersPath, showCompanyOrders
}) {
  return (
    <>
      <div className='card'>
        <OrderHistoryList
          appProps={ appProps }
          listOrdersPath={ listOrdersPath }
          listHeadingTitle={ appProps.translations.account.order_history }
          listHeadingSubtitle={ appProps.translations.account.order_history_subtitle }
          noOrdersTitle={ appProps.translations.orders.no_orders_message }
          showEmail={ false }
        />
      </div>

      { showCompanyOrders &&
        <div className='card'>
          <OrderHistoryList
            appProps={ appProps }
            listOrdersPath={ listCompanyOrdersPath }
            listHeadingTitle={ appProps.translations.account.company_order_history }
            listHeadingSubtitle={ appProps.translations.account.company_order_history_subtitle }
            noOrdersTitle={ appProps.translations.orders.no_company_orders_message }
            showEmail
          />
        </div>
      }
    </>
  )
}
