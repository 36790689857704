export function createOrderForm(
  user,
  email,
  delivery,
  shipping,
  billing,
  isDeliveryAddressUsedAsBilling,
  cart,
  contactlessEnabled,
  installments,
  issueInvoice,
  stripePaymentMethodId = null
){
  const { deliveryOption, storeId, orderNotes, ...deliveryAddress } = delivery
  const { selectedMethod, ...billingAddress } = billing
  const shipping_method = shipping.selectedMethod
  const selected_smartpoint = shipping.selectedSmartpoint

  let form = new FormData
  form.append("order[email]", email)
  form.append("order[special_instructions]", orderNotes)

  if(contactlessEnabled) form.append("order[contactless_delivery]", contactlessEnabled)
  if(user.id) form.append("order[user_id]", user.id)

  const customerDetailsPresent = delivery.fullName && (delivery.phone || delivery.mobile)

  if(shipping_method.id && customerDetailsPresent){
    form.append("order[shipments_attributes][][method_type]", `Shop::Shipping::${shipping_method.id.split("_@_")[0]}`)
    form.append("order[shipments_attributes][][method_id]", shipping_method.id.split("_@_")[1])
    form.append("order[shipments_attributes][][api_integration_entity]", JSON.stringify(shipping_method.api_integration_entity || null))
    form.append("order[shipments_attributes][][smartpoint]", JSON.stringify(selected_smartpoint || null))
  }

  if(shipping.multipleShipmentsOptionEnabled) {
    form.append('multiple_shipments_option_enabled', shipping.multipleShipmentsOptionEnabled)
  }

  if(customerDetailsPresent) {
    form.append(`order[delivery_address_attributes][full_name]`, deliveryAddress.fullName)
    form.append(`order[delivery_address_attributes][phone]`, (deliveryAddress.phone ? deliveryAddress.phone.trim() : deliveryAddress.phone) || "")
    form.append(`order[delivery_address_attributes][mobile_phone]`, (deliveryAddress.mobile ? deliveryAddress.mobile.trim() : deliveryAddress.mobile) || "")
    form.append('order[store_id]', deliveryOption === 'pickup' ? storeId : '')

    if(deliveryOption === "delivery") {
      form.append(`order[delivery_address_attributes][city]`, deliveryAddress.city)
      form.append(`order[delivery_address_attributes][state_region_code]`, deliveryAddress.area)
      form.append(`order[delivery_address_attributes][street]`, deliveryAddress.address)
      form.append(`order[delivery_address_attributes][postal_code]`, deliveryAddress.postalCode)
      form.append(`order[delivery_address_attributes][country_numeric_code]`, deliveryAddress.country)
    }
  }

  if(installments) {
    form.append('installments', installments)
  }

  if(selectedMethod.id){
    form.append('order[payment_method_id]', selectedMethod.id)
    if(!isNaN(selectedMethod.gross_handling_fee)) form.append('order[handling_fee]', selectedMethod.gross_handling_fee)

    if(selectedMethod.type === "StripePayment") {
      form.append('stripe_payment_method_id', stripePaymentMethodId)
    }
  }

  if (selectedMethod.billingAddressRequired || issueInvoice){
    if(isDeliveryAddressUsedAsBilling){
      form.append(`order[billing_address_attributes][city]`, deliveryAddress.city)
      form.append(`order[billing_address_attributes][country_numeric_code]`, deliveryAddress.country)
      form.append(`order[billing_address_attributes][full_name]`, deliveryAddress.fullName)
      form.append(`order[billing_address_attributes][mobile_phone]`, (deliveryAddress.mobile ? deliveryAddress.mobile.trim() : deliveryAddress.mobile) || "")
      form.append(`order[billing_address_attributes][phone]`, (deliveryAddress.phone ? deliveryAddress.phone.trim() : deliveryAddress.phone) || "")
      form.append(`order[billing_address_attributes][postal_code]`, deliveryAddress.postalCode)
      form.append(`order[billing_address_attributes][state_region_code]`, deliveryAddress.area)
      form.append(`order[billing_address_attributes][street]`, deliveryAddress.address)
    }else{
      form.append(`order[billing_address_attributes][city]`, billingAddress.city)
      form.append(`order[billing_address_attributes][country_numeric_code]`, billingAddress.country)
      form.append(`order[billing_address_attributes][full_name]`, billingAddress.fullName)
      form.append(`order[billing_address_attributes][mobile_phone]`, (billingAddress.mobile ? billingAddress.mobile.trim() : billingAddress.mobile) || "")
      form.append(`order[billing_address_attributes][phone]`, (billingAddress.phone ? billingAddress.phone.trim() : billingAddress.phone) || "")
      form.append(`order[billing_address_attributes][postal_code]`, billingAddress.postalCode)
      form.append(`order[billing_address_attributes][state_region_code]`, billingAddress.area)
      form.append(`order[billing_address_attributes][street]`, billingAddress.address)
    }
  }


  if(cart){
    if(typeof cart.shippingCost !== 'undefined') form.append('cart_attributes[shipping_cost]', cart.shippingCost)
    if(typeof cart.weight !== 'undefined') form.append('cart_attributes[weight]', cart.weight)
  }

  return form
}
