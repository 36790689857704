import React, { useState, useEffect } from "react"

export default function CarrierTrackingInfo({ appProps, orderNo }) {
  const [trackingInfo, setTrackingInfo] = useState({})

  useEffect( ()=> {
    if (orderNo) {
      fetchTrackingInfo()
    }
  },[])

  function fetchTrackingInfo(){
    Rails.ajax({
      type: "GET",
      url: `/orders/fetch_tracking_info?orderNumber=${orderNo}`,
      dataType: "json",
      success: res => {
        setTrackingInfo(res)
      },
      error: res =>{
        setTrackingInfo({})
      }
    })
  }
  
  return (
    <>
      { trackingInfo.trackings &&
        <div className='flex-box flex-column'>
          { trackingInfo.trackings.map( (shipment, index) => {
            return (
              <React.Fragment key={ index }>
                <div>
                  <b>{ appProps.translations.shipments.detailed_tracking_heading }</b>
                </div>
                <div className='flex-box flex-column card'>
                  <div className='flex-box'>
                    <div className='flex-box flex-1 flex-column'>
                      <div><b>{ appProps.translations.shipments.carrier_checkpoint }</b></div>
                    </div>
                    <div className='flex-box flex-1 flex-column'>
                      <div><b>{ appProps.translations.shipments.carrier_date }</b></div>
                    </div>
                    <div className='flex-box flex-1 flex-column'>
                      <div><b>{ appProps.translations.shipments.carrier_details }</b></div>
                    </div>
                    <div className='flex-box flex-1 flex-column'>
                      <div><b>{ appProps.translations.shipments.carrier_notes }</b></div>
                    </div>
                  </div>
                  { shipment.summary.map( (summary, index) => {
                    return (
                      <div key={ index } className='flex-box' style={{ paddingTop: 10 }}>
                        <div className='flex-box flex-1 flex-column'>
                          <div>{ summary.checkpoint_location }</div>
                        </div>
                        <div className='flex-box flex-1 flex-column'>
                          <div>{ summary.checkpoint_date_time }</div>
                        </div>
                        <div className='flex-box flex-1 flex-column'>
                          <div>{ summary.checkpoint_action }</div>
                        </div>
                        <div className='flex-box flex-1 flex-column'>
                          <div>{ summary.checkpoint_notes }</div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </React.Fragment>
            )
          })}
        </div>
      }
    </>
  )

}
