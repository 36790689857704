import React, { useState, useRef } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'
import General from '../companies/steps/General'
import Billing from '../companies/steps/Billing'
import Files from '../companies/steps/Files'
import Users from '../companies/steps/Users'

export default function Index({
  appProps, pageName, pageTitle, company, countriesPath, saveCompanyPath, destroyFilePath, getCompanyUsersPath,
  requirePhone, requireMobilePhone
}) {
  const [companyState, _setCompanyState] = useState({ ...company })
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)

  const fileInputRef = useRef(null)

  function setCompanyState(updatedState) {
    _setCompanyState({ ...companyState, ...updatedState })
  }

  function setCompanyAddress(updatedAddress) {
    const newAddress = { ...companyState.billingAddress, ...updatedAddress }
    setCompanyState({ billingAddress: newAddress })
  }

  function save() {
    setLoading(true)
    let formData = new FormData()
    formData.append('company[name]', companyState.name)
    formData.append('company[vat]', companyState.vat)
    formData.append('company[internal_notes]', companyState.internalNotes)

    if (companyState.billingAddress) {
      formData.append('company[address_attributes][full_name]', companyState.name || '')
      formData.append('company[address_attributes][street]', companyState.billingAddress.address || '')
      formData.append('company[address_attributes][phone]', companyState.billingAddress.phone || '')
      formData.append('company[address_attributes][mobile_phone]', companyState.billingAddress.mobilePhone || '')
      formData.append('company[address_attributes][city]', companyState.billingAddress.city || '')
      formData.append('company[address_attributes][postal_code]', companyState.billingAddress.postalCode || '')
      formData.append('company[address_attributes][country_numeric_code]', companyState.billingAddress.country || '')
      formData.append('company[address_attributes][state_region_code]', companyState.billingAddress.state || '')
      formData.append('company[address_attributes][usage]', 'billing')

      if (companyState.billingAddress.id) {
        formData.append(`company[address_attributes][id]`, companyState.billingAddress.id)
      }
    }

    for (var i = 0; i < files.length; i++) {
      formData.append(`files[${ i }]`, files.item(i))
    }

    Rails.ajax({
      type: companyState.id ? 'PATCH' : 'POST',
      url: saveCompanyPath,
      data: formData,
      dataType: 'json',
      success: res => {
        if (res.company) {
          _setCompanyState({
            ...res.company,
            billingAddress: { ...res.company.billingAddress },
            files: [...res.company.files],
          })
          setFiles([])
          if (fileInputRef.current) {
            fileInputRef.current.value = ""
          }

          appProps.flashMessage.show(appProps.translations.general.saved, 'success')
        }
      },
      error: res => {
        if (res.error) {
          appProps.flashMessage.show(res.error, 'error')
        }
      },
      complete: res => {
        setLoading(false)
      }
    })
  }

  function deleteFile(fileId){
    setLoading(true)
    let data = new FormData()
    data.append("file", fileId)

    Rails.ajax({
      url: destroyFilePath,
      type: 'PATCH',
      dataType: 'json',
      data: data,
      success: res => {
        setLoading(false)
        _setCompanyState({
          ...companyState,
          files: companyState.files.filter( f => f.id != fileId ),
        })
        appProps.flashMessage.show(appProps.translations.general.saved, 'success')
      }
    })
  }

  return (
    <>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
        { appProps.user.isCompanyMaster && !appProps.user.companyActive && <h6 style={{color: 'red'}}>{ appProps.translations.account.company_pending_approval }</h6> }
      </div>

      <div className={ `companies-wrapper ${pageName}` }>

        <div className="card">
          <General
            appProps={ appProps }
            company={ company }
            companyState={ companyState }
            setCompanyState={ setCompanyState }
            saveSettings={ save }
            loading={ loading }
            setLoading={ setLoading }
          />
        </div>

        <div className="card">
          <Billing
            appProps={ appProps }
            company={ company }
            companyState={ companyState }
            setCompanyState={ setCompanyState }
            setCompanyAddress={ setCompanyAddress }
            saveSettings={ save }
            countriesPath={ countriesPath }
            loading={ loading }
            requirePhone={ requirePhone }
            requireMobilePhone={ requireMobilePhone }
            setLoading={ setLoading }
          />
        </div>

        {appProps.user.isCompanyMaster &&
          <div className="card">
            <Files
              appProps={ appProps }
              companyState={ companyState }
              files={ files }
              setFiles={ setFiles }
              saveSettings={ save }
              loading={ loading }
              deleteFile={ (e)=> deleteFile(e) }
              setLoading={ setLoading }
              fileInputRef={ fileInputRef }
              destroyFilePath={ destroyFilePath }
            />
          </div>
        }

        {appProps.user.isCompanyMaster &&
          <div className="card">
            <Users
              appProps={ appProps }
              getCompanyUsersPath={ getCompanyUsersPath }
            />
          </div>
        }
      </div>

      { loading &&
        <Loader
          absolute
          size={ 'large' }
          position={ 'center' }
          fullPage
        />
      }
    </>
  )
}
