import React from 'react'
import { Modal } from '@reactiveonline/frontend_shared_components'

export default function PendingOrderModal({ translations, newOrderEnc, closePendingOrderModal }) {

  return (
    <Modal
      visible
      mode='small'
      acceptAction={ () => { window.location.href = `/checkout?ordrep=${newOrderEnc}` } }
      closeModal={ closePendingOrderModal }
      abortAction={ closePendingOrderModal }
      abortText={ translations.general.noText }
      saveText={ translations.general.yesText }
    >
      <div>
        { translations.orders.replace_pending_order }
      </div>
    </Modal>
  )
}
