import React from 'react'
import { Content } from '@reactiveonline/frontend_shared_components/builder'

export default function Preview({ appProps, getBuilderContentUrl }) {
  return (
    <Content
      appProps={ appProps }
      getBuilderContentUrl={ getBuilderContentUrl }
    />
  )
}
