import React, { useState, useEffect } from "react"

import MiniCart from "./MiniCart"
import GlobalProductSearch from '../shared/GlobalProductSearch'
import { AuthorizableLink, LogoutLink } from '@reactiveonline/frontend_shared_components'

export default function HeaderIcons({ appProps, order }) {
  const { GlobalProductSearch } = appProps
  const [isCheckout, setIsCheckout] = useState(window.location.href.indexOf("checkout") > -1)
  const [currentOrder, setCurrentOrder] = useState(order || appProps.order)
  const [selectedDropMenu, setSelectedDropMenu] = useState(null)
  const [openMenu, setOpenMenu] = useState(false)

  const localePrefix = appProps.currentLocale ? `/${appProps.currentLocale}` : ''
  const { translations, registrationEnabled } = appProps
  const profileData = [
    { title: translations.account.manage_account, icon: null, read: false, timestamp: null, path: appProps.accountPath },
    { title: translations.account.order_history, icon: null, read: false, timestamp: null, path: appProps.ordersPath },
    { title: translations.account.billing, icon: null, read: false, timestamp: null, path: appProps.billingPath },
    { title: translations.account.privacy_policy, icon: null, read: false, timestamp: null, path: appProps.privacyPolicyPath },
    appProps.user && appProps.user.belongsToCompany && { title: translations.company.company, icon: null, read: false, timestamp: null, path: appProps.companyInfoPath },
    { title: translations.account.delete_account, icon: null, read: false, timestamp: null, path: appProps.deleteAccountPath },
    { title: translations.general.logout, icon: null, read: false, timestamp: null, path: appProps.logoutPath, requestType: 'delete' },
  ]

  let loginData = [{ title: translations.account.login_button, icon: null, read: false, timestamp: null, path: `${localePrefix}/account` }]
  if(registrationEnabled) {
    loginData.push({ title: translations.account.register_button, icon: null, read: false, timestamp: null, path: `${localePrefix}/account` })
  }

  function escFunction(event){
    if (event.key === "Escape") {
      setOpenMenu(false)
    }
  }
  useEffect( ()=>{
    document.addEventListener("keydown", escFunction, false);
  },[openMenu])

  return (
    <>
      <div className="header-icons-container">
        <ul>
          <GlobalProductSearch
            appProps={ appProps }
          />
          { !isCheckout &&
            <li className="cart-dropmenu mini_cart">
              <div id="mini-cart-ajax-container">
                <MiniCart
                  appProps={ appProps }
                  lineItemsCount={ appProps.lineItemsCount }
                />
              </div>
            </li>
          }
          <li className="account">
            {appProps.userFormLoginType ?
              <AuthorizableLink
                appProps={ appProps }
                linkClass="account_icon my-account"
                linkRef={ appProps.accountPath }
              /> :
              <a className="account_icon my-account" href={ appProps.accountPath }></a>
            }
          </li>
          <li className="logout">
            { appProps.user &&
              <LogoutLink
                logoutPath={ appProps.logoutPath }
                redirectAfterAuthPath={ appProps.redirectAfterAuthPath }
              />
            }
          </li>
        </ul>
      </div>
    </>
  )
}
