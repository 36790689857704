import { Button } from "@material-ui/core"
import React from "react"
import Select from "react-select"
import ContentLoader, { Facebook } from "react-content-loader"
import { SliderImages } from '@reactiveonline/frontend_shared_components'

import ProductPresentableTitle from "../shared/ProductPresentableTitle"
import { formatPriceWithCurrency, customReactSelectStyles, customReactSelectTheme } from "../../helpers/utils"

export default class Cart extends React.Component {

  removeFromCartClicked(e, line_item_id){
    const { removeFromCart } = this.props
    if(!e.target.disabled){
      e.target.disabled = true
      removeFromCart(line_item_id)
    }
  }

  render() {
    const {
      products, shippingCost, shippingCostNet, subTotal, tax, total, loading, updateLineItemQuantity,
      cartEditable, paymentCost, paymentCostNet, currency, appProps
    } = this.props

    let productItems =
      products.map((line_item, index) => {
        const product = line_item.sellable

        let maxQuantity = appProps.maxProductQuantity && product.maxPurchasableQuantity ? Math.min(...[appProps.maxProductQuantity, product.maxPurchasableQuantity]) : Math.max(...[appProps.maxProductQuantity, product.maxPurchasableQuantity])
        maxQuantity = product.totalStock === null || product.totalStock > maxQuantity ? maxQuantity : product.totalStock
        const stock = Array.from(Array(maxQuantity).keys())

        const productImages = product.images.length > 0 ? product.images.map( image => {
          return {
            original: image.preview,
            thumbnail: image.thumbnail,
            fullscreen: image.banner,
          }
        }
        ) : [{
          original: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
          thumbnail: '',
          fullscreen: ''
        }]

        return (
          <div className="item-row" key={index}>
            <div className="item-contents product-image">
              <SliderImages
                images={ productImages }
                showThumbnails={ false }
                showFullscreenButton={ false }
                showNav={ false }
                showBullets={ false }
                lightbox={ false }
                thumbnailPosition={ 'bottom' }
              />
            </div>

            <div className="item-contents product-info">
              <div className="flex-box content-space-between">
                <div className="content-item name flex-box flex-column">
                  <div>
                    {`${line_item.quantity} x `} <ProductPresentableTitle appProps={appProps} entity={product} entityIndex={index} />
                  </div>
                  { line_item.sellableType == "Shop::Bundle" &&
                    <div>
                      { line_item.sellable.bundleItems.map((bundle_item, index) => {
                        return (
                          <div key={ index }>
                            <div>
                              {`${bundle_item.quantity} x `}
                              <ProductPresentableTitle appProps={appProps} entity={bundle_item} entityIndex={index} />
                            </div>
                          </div>
                        )})
                      }
                    </div>
                  }
                </div>

                { cartEditable && line_item.totalGross !== 0 &&
                  <Button className="button plain delete-button"
                    onClick={ (e) => this.removeFromCartClicked(e, line_item.id) }>
                  </Button>
                }
              </div>
              <div className="content-item options">
                <ul>
                  { line_item.options.map((option, index) => {
                    return( <li key={ index }>{ option.optionTypeName }: <b>{ option.name }</b></li> )
                  })}
                </ul>
              </div>

              { cartEditable && line_item.totalGross !== 0 &&
                <div className="content-item quantity cart-quantity-wrapper">
                  <div className="selectable-quantity">
                    <Select
                      theme={customReactSelectTheme}
                      styles={customReactSelectStyles}
                      isSearchable={ false }
                      options={stock.map((i, index) => {
                        return {value: i+1, label: `${i+1}`}
                      })}
                      defaultValue={{value: line_item.quantity, label: `${line_item.quantity}`}}
                      onChange={(selected) => {
                        updateLineItemQuantity(line_item.id, selected.value)
                      }}
                    />
                  </div>
                  <div>{`x ${line_item.displayUnitGross}`}</div>
                </div>
              }

              <div className="content-item price">
                { line_item.displayTotalGross }
              </div>
            </div>
          </div>
        )
      })

    return (
      <div className="cart card">

        <div className="checkout-headers">
          <h3>
            { appProps.translations.cart.cart_heading }
          </h3>
        </div>

        <div className="cart-body">
          { productItems }
        </div>

        <div className="cart-totals">
          <div className="labels">
            <div className="label">
              { appProps.translations.cart.subtotal }
            </div>
            { shippingCostNet > 0.0 &&
              <div className="label">
                { appProps.translations.cart.delivery_cost }
              </div>
            }
            { paymentCostNet !== undefined && paymentCostNet > 0.0 &&
              <div className="label">
                { appProps.translations.cart.cod_cost }
              </div>
            }
            <div className="label">
              { appProps.translations.cart.vat }
            </div>
            <div className="label total">
              <b>
                { appProps.translations.cart.total }
              </b>
            </div>
          </div>
          { loading ?
              <div className="totals">
                <ContentLoader>
                  <rect x="10" y="27"  rx="10" ry="10" width="100" height="19" />
	                <rect x="10" y="57"  rx="17.5" ry="17.5" width="100" height="32" />
                  <rect x="10" y="105" rx="10" ry="10" width="100" height="19" />
                </ContentLoader>
              </div>
            :
              <div className="totals">
                <div className="cost">{ formatPriceWithCurrency(subTotal.toFixed(2), currency) }</div>
                {
                  shippingCostNet > 0.0 &&
                    <div className="cost">{ formatPriceWithCurrency(shippingCostNet.toFixed(2), currency) }</div>
                }
                {
                  paymentCostNet !== undefined && paymentCostNet > 0.0 &&
                    <div className="cost">{ formatPriceWithCurrency(paymentCostNet.toFixed(2), currency) }</div>
                }
                <div className="cost">{ formatPriceWithCurrency(tax.toFixed(2), currency) }</div>
                <div className="cost total">
                  <b>{ formatPriceWithCurrency(total.toFixed(2), currency) }</b>
                </div>
              </div>
          }
        </div>

        { this.props.children }
      </div>
    )
  }
}
