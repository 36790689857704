import React, { useState, useEffect, useRef } from "react"
import { Loader } from '@reactiveonline/frontend_shared_components'

import OrderHistoryItem from "./shared/OrderHistoryItem"

export default function OrderHistoryList({
  appProps, listOrdersPath, listHeadingTitle, listHeadingSubtitle, showEmail, noOrdersTitle
}) {
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [checkFetched, setCheckFetched] = useState(false)

  const didMountRef = useRef(false)

  useEffect(() => {
    if (!didMountRef.current) {
      fetchOrders(1)
    } else {
      didMountRef.current = true
    }
  }, [])

  function fetchOrders(page) {
    setLoading(true)

    Rails.ajax({
      type: "GET",
      url: `${ listOrdersPath }?page=${ page }`,
      dataType: "json",
      success: res => {
        let newOrders = [...orders, ...res.orders]

        setPage(page)
        setTotalPages(res.totalPages)
        setOrders(newOrders)
        setLoading(false)
        setCheckFetched(true)
      },
      error: res => {
        setLoading(false)
      }
    })
  }

  function renderOrders() {
    return (
      <>
        <div className="heading">
          <h3>{ listHeadingTitle }</h3>
          <h5>{ listHeadingSubtitle }</h5>
        </div>

        <div className="order_history_wrapper">
          <div className="history_headers">
            <div className="row date">
              <h6>{ appProps.translations.orders.date }</h6>
            </div>
            <div className="row number">
              <h6>{ appProps.translations.orders.order_number }</h6>
            </div>
            <div className="row total">
              <h6>{ appProps.translations.orders.order_total }</h6>
            </div>
            <div className="row delivered">
              <h6>{ appProps.translations.orders.order_status }</h6>
            </div>
            { showEmail &&
              <div className="row delivered">
                <h6>{ appProps.translations.account.email_address }</h6>
              </div>
            }
            <div className="row actions">
              {/* Rendering view icon in this column without heading */}
            </div>
          </div>

          {
            orders.map((order, index) => (
              <OrderHistoryItem
                key={ index }
                order={ order }
                appProps={ appProps }
                showEmail={ showEmail }
              />
            ))
          }

          { page < totalPages &&
            <div className="flex-box item-center content-center" style={{marginTop: 40}}>
              <button className="button" onClick={ () => fetchOrders(page + 1) }>
                { appProps.translations.general.read_more_button }
              </button>
            </div>
          }
        </div>
      </>
    )
  }

  let noOrdersView = null
  if (checkFetched) {
    noOrdersView =
      <div className="no_data orders_icon flex-box flex-column items-center">
        <h2>
          { noOrdersTitle || appProps.translations.orders.no_orders_message }
        </h2>
      </div>
  }

  return (
    <div id="order_history" className="tab_content" style={{ position: 'relative' }}>
      { orders.length > 0 ? renderOrders() : noOrdersView }

      { loading && <Loader size={'medium'} position={'center'} /> }
    </div>
  )
}
