import React from 'react'

export default function ProductStock({ appProps, stock, inStock }) {

  return (
    <div className='stock-wrapper'>
      <div className='stock'>
        { inStock && stock &&
          <>
            { stock.quantity == undefined || stock.quantity > 0 &&
              <div className='stock-quantity'>
                { stock ? stock.quantity : '' }
              </div>
            }
            <div className='stock-message'>
              { stock.quantity == undefined || stock.quantity > 0 ? appProps.translations.products.in_stock : appProps.translations.products.low_stock }
            </div>
          </>
        }

        { !inStock &&
          <div className='stock-message out-of-stock'>
            { appProps.translations.products.out_of_stock }
          </div>
        }
      </div>
    </div>
  )
}
