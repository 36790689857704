import React, { useState, useEffect } from 'react'

export default function Coupons({ appProps, orderTotal, couponAdjustments, setCouponsAdjustments, hideActions, setTotalWithAdjustments, translations, setLoading, orderId }) {
  const [inputText, setInputText] = useState('')
  const [newTotal, setNewTotal] = useState(orderTotal)

  useEffect( () => {
    setNewTotal(orderTotal)
  }, [couponAdjustments, orderTotal])

  function applyCoupon() {
    if (inputText.trim().length) {
      setLoading(true)
      let fd = new FormData()
      fd.append('coupon_code', inputText)
      if (orderId) fd.append('order_id', orderId)

      Rails.ajax({
        type: 'POST',
        url: '/checkout/apply_coupon',
        dataType: 'json',
        data: fd,
        success: res => {
          if (res.message.type === 'success') {
            appProps.flashMessage.show(res.message.value, 'success')
          } else {
            appProps.flashMessage.show(res.message.value, 'error')
          }
          if (res.applied) {
            setCouponsAdjustments([...couponAdjustments, res.coupon])
            setTotalWithAdjustments(res.newTotal)
            setNewTotal(res.newTotal)
            setInputText('')
          }
          setLoading(false)
        },
        error: res => {
          setLoading(false)
        }
      })
    }
  }

  function removeCoupon(orderAdjustmentId) {
    setLoading(true)
    let fd = new FormData()
    fd.append('order_adjustment_id', orderAdjustmentId)
    if (orderId) fd.append('order_id', orderId)

    Rails.ajax({
      type: 'POST',
      url: '/checkout/remove_coupon',
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.coupons) {
          setCouponsAdjustments(res.coupons)
          setNewTotal(res.newTotal)
        }
        setLoading(false)
      },
      error: res => {
        setLoading(false)
      }
    })
  }

  function renderAppliedCoupons() {
    return couponAdjustments.map( coupon => (
      <div key={ coupon.orderAdjustmentId } className='coupon-row'>
        <div className='coupon-code'>
          { coupon.code }
          <b>&nbsp;({ `-${ coupon.discount }` })</b>
        </div>
        { !hideActions &&
          <i className={ `remove_icon icon ${ orderId ? 'fa-light fa-trash' : '' }` } onClick={ () => removeCoupon(coupon.orderAdjustmentId) } />
        }
      </div>
    ))
  }

  return (
    <div className='coupons-wrapper'>
      { (!hideActions || (hideActions && couponAdjustments.length > 0)) &&
        <div className='checkout-headers'>
          <h3>{ translations.checkout.coupons_title }</h3>
        </div>
      }

      <div className='coupons'>
        { renderAppliedCoupons() }
        { couponAdjustments.length > 0 &&
          <div className='coupon-totals coupon-row'>
            <div className='label'>
              { `${ translations.checkout.coupons_total }:` }
            </div>
            <div className='value total'>
              { newTotal }
            </div>
          </div>
        }
      </div>

      { !hideActions &&
        <div className='coupons-input-wrapper'>
          <div className='section-heading'>
            <div className='heading-text'>
              { translations.checkout.coupons_description }
            </div>
          </div>
          <div className='coupons-fields flex-box items-center'>
            <input
              type='text'
              placeholder={ translations.checkout.coupons_placeholder }
              className='input-form coupon-input'
              value={ inputText }
              onChange={ event => setInputText(event.target.value) }
            />

            <button
              type='button'
              className='button btn plain'
              onClick={ () => applyCoupon() }
            >
              { translations.checkout.coupons_apply }
            </button>
          </div>
        </div>
      }
    </div>
  )
}
