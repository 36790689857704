import React, { useState, useEffect } from "react"
import OrderTrackers from "./OrderTrackers"
import ProductPresentableTitle from "../shared/ProductPresentableTitle"
import { AuthorizableLink, BankDetails } from '@reactiveonline/frontend_shared_components'
import { getTranslatableField } from "../../helpers/utils"

export default function Complete({
  appProps, order, paymentMethodTitle, deliveryAddress, billingAddress, shipmentMethodTitle, couponAdjustments,
  skroutzAnalyticsActive, renderTrackers, bankDetails
}) {

  function printOrder() {
    window.print();
    return false;
  }

  return (
    <>
      <div className="main-container">
        <div className="checkouts-wrapper order-complete flex-box flex-wrap">
          <div className='column-success'>

            <div className='card flex-box flex-column items-center content-center hide-from-print'>
              <h1>{ appProps.translations.orders.thank_you_for_your_order }</h1>
              <div style={{ fontSize: 15, marginTop: 10 }}>{ appProps.translations.orders.confirmation_and_updates_email } { order.email }</div>

              { !appProps.user &&
                <div className="account">
                  {appProps.userFormLoginType ?
                    <AuthorizableLink
                      appProps={ appProps }
                      linkContent={ <div> { appProps.translations.checkout.no_account } <b>{ appProps.translations.checkout.register_now }</b> </div>}
                      linkRef={ appProps.accountPath }
                      authorizableScreen={ 'register' }
                    /> :
                    <a className="account_icon my-account" href={ appProps.accountPath }></a>
                  }
                </div>
              }
            </div>

            <div className='card cart-block'>
              <div style={{ marginBottom: 20 }}>
                <b>{ appProps.translations.orders.order_contents }</b>
              </div>

              { order.line_items.map((line_item, index) => {
                  return (
                    <div key={index}>
                      <div className='flex-box' style={{ padding: '20px 0px' }}>
                        <div className="item-contents product-image flex-1">
                          <img style={{ height: 60 }} src={ line_item.sellable.images.length > 0 ? line_item.sellable.images[0].thumbnail : "https://storage.googleapis.com/reactive_files_eu/no-image.jpg" } />
                        </div>
                        <div className="flex-1 flex-box flex-column">
                          <div>
                            <ProductPresentableTitle appProps={appProps} entity={line_item.sellable} entityIndex={index} />
                          </div>
                          { line_item.sellableType == "Shop::Bundle" &&
                            <div>
                              { line_item.sellable.bundleItems.map((bundle_item, index) => {
                                return (
                                  <div key={ index }>
                                    <div className="flex-1">
                                      {`${bundle_item.quantity} x `}
                                      <ProductPresentableTitle appProps={appProps} entity={bundle_item} entityIndex={index} />
                                    </div>
                                  </div>
                                )})
                              }
                            </div>
                          }
                        </div>
                        <div className="flex-1">
                          <ul>
                            { line_item.options.map( (option, index) => {
                              return (
                                <li key={index}>
                                  { option.optionTypeName || '-' }:
                                  &nbsp;<b>{ option.name }</b>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                        <div className="flex-1">
                          x { line_item.quantity }
                        </div>
                        <div className="flex-1">
                          { line_item.displayTotalGross }
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>

            <div className="flex-box flex-column content-end card" style={{ width: '100%' }}>
              <div style={{ marginBottom: 20 }}>
                <b>{ appProps.translations.orders.order_total }</b>
              </div>

              <div className="flex-box content-space-between" style={{ marginBottom: 5 }}>
                <div>{ appProps.translations.cart.subtotal }:</div>
                <div className='bold'>{ order.subtotal }</div>
              </div>

              { order.shipping_cost_net &&
                <div className="flex-box content-space-between" style={{ marginBottom: 5 }}>
                  <div>{ appProps.translations.cart.delivery_cost }:</div>
                  <div className='bold'>{ order.shipping_cost_net }</div>
                </div>
              }


              { order.handling_fee_net &&
                <div className="flex-box content-space-between" style={{ marginBottom: 5 }}>
                  <div>{ appProps.translations.cart.handling_fee }:</div>
                  <div className='bold'>{ order.handling_fee_net }</div>
                </div>
              }
              <div className="flex-box content-space-between" style={{ marginBottom: 5 }}>
                <div>{ appProps.translations.cart.vat }:</div>
                <div className='bold'>{ order.vat_total }</div>
              </div>

              { order.couponAdjustments.length > 0 ?
                <>
                  <div className="flex-box content-space-between" style={{ marginBottom: 5 }}>
                    <div>
                      { `${appProps.translations.checkout.coupons_title} (${order.couponAdjustments.map(couponAdjustment => couponAdjustment.code).join(', ')})` }:
                    </div>
                    <div className="flex-box flex-column items-end">
                      { order.couponAdjustments.map(couponAdjustment => (
                        <div key={ couponAdjustment.orderAdjustmentId } style={{ marginBottom: 5 }}>
                          -{ couponAdjustment.discount }
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex-box items-center content-space-between" style={{ marginTop: 10 }}>
                    <div><b>{ appProps.translations.cart.total }:</b></div>
                    <div><b>{ order.totalWithAdjustments }</b></div>
                  </div>
                </>
              :
                <div className="flex-box items-center content-space-between" style={{ marginTop: 10 }}>
                  <div><b>{ appProps.translations.cart.total }:</b></div>
                  <div><b>{ order.total }</b></div>
                </div>
              }
            </div>

            <div className="order-detail-block hide-from-print">
              <div className="order-detail-block-title">
                { appProps.translations.orders.any_questions }
              </div>
              <div className="order-detail-block-description">
                { appProps.translations.orders.at_your_disposal }
              </div>
            </div>

          </div>

          <div className='column-details'>
            <div className='flex-box flex-column card'>

              <div className='detail-item flex-box items-center content-space-between'>
                <div className="flex-1">
                  { appProps.translations.orders.order_number }
                </div>
                <div className="bold">
                  { order.number }
                </div>
              </div>
              <div className='detail-item flex-box items-center content-space-between'>
                <div className="flex-1">
                  { appProps.translations.orders.date }
                </div>
                <div className="bold">
                  { order.completed_at_formatted ? order.completed_at_formatted : order.created_at_formatted }
                </div>
              </div>

              <div className='flex-box items-center content-space-between'
                   style={ order.payment_method.type == 'BankTransfer' && order.payment_method.description ? {} : { marginBottom: 10 } }>
                <div className="flex-1">
                  { appProps.translations.orders.payment_status }
                </div>
                <div className="bold">
                  { paymentMethodTitle }
                </div>
              </div>

              { order.payment_method.type == 'BankTransfer' && order.payment_method.description &&
                <div className='detail-item'>
                  <div className="order-detail-block-description" dangerouslySetInnerHTML={{ __html: order.payment_method.description }} />
                </div>
              }

              { !!bankDetails.length &&
                <BankDetails
                  appProps={ appProps }
                  bankDetails={ bankDetails }
                />
              }

              <div className='detail-item flex-box items-center content-space-between'>
                <div className="flex-1">
                  { appProps.translations.orders.shipping_method_section_title }
                </div>
                <div className="bold">
                  { shipmentMethodTitle }
                  <div className="shipment-description">
                    { order.shipment.method_type == 'Shop::Shipping::LocalPickup' ?
                      <div dangerouslySetInnerHTML={{ __html: '' }} />
                      :
                      <div dangerouslySetInnerHTML={{ __html: order.shipment.description }} />
                    }
                  </div>
                </div>
              </div>

              { order.contactless_delivery &&
                <div className="detail-item flex-box items-center content-space-between">
                  <div>{ appProps.translations.checkout.contactless_delivery_label }:</div>
                  <div className='checked_icon bold' />
                </div>
              }

              { order.delivery_address && order.delivery_address.phone &&
                <div className='detail-item flex-box items-center content-space-between'>
                  <div className="flex-1">
                    { appProps.translations.checkout.phone }
                  </div>
                  <div className="bold">
                    { order.delivery_address.mobile }
                  </div>
                </div>
              }

              { order.delivery_address && order.delivery_address.mobile &&
                <div className='detail-item flex-box items-center content-space-between'>
                  <div className="flex-1">
                    { appProps.translations.checkout.mobile_phone }
                  </div>
                  <div className="bold">
                    { order.delivery_address.mobile }
                  </div>
                </div>
              }

              { deliveryAddress &&
                <div className='detail-item flex-box flex-column'>
                  <div className="flex-1">
                    { appProps.translations.orders.delivery_address_section_title }
                  </div>
                  <div className="bold">
                    { deliveryAddress }
                  </div>
                </div>
              }

              { billingAddress &&
                <div className='detail-item flex-box flex-column'>
                  <div className="flex-1">
                    { appProps.translations.company.billing_address }
                  </div>
                  <div className="bold">
                    { billingAddress }
                  </div>
                </div>
              }

              { order.pickupStore &&
                <div className='detail-item flex-box items-center content-space-between'>
                  <div className="flex-1">
                    { appProps.translations.checkout.pickup_store }
                  </div>
                  <div className="bold">
                    { order.pickupStore }
                  </div>
                </div>
              }

              { order.special_instructions &&
                <div className='detail-item flex-box flex-column'>
                  <div className="flex-1">
                    { appProps.translations.checkout.order_notes }
                  </div>
                  <div className="bold">
                    { order.special_instructions }
                  </div>
                </div>
              }

            </div>

            { order.invoice &&
              <div className='flex-box flex-column card'>
                <div className='bold' style={{ fontSize: 17, marginBottom: 20 }}>
                  { appProps.translations.invoices.invoice }
                </div>

                <div className='detail-item flex-box flex-column'>
                  <div className="flex-1">
                    { appProps.translations.invoices.companyName }
                  </div>
                  <div className="bold">
                    { order.invoice.company_name }
                  </div>
                </div>

                { order.invoice.vat_number &&
                  <div className='detail-item flex-box flex-column'>
                    <div className='flex-1'>
                      { appProps.translations.invoices.vatNumber }
                    </div>
                    <div className="bold">
                      { order.invoice.vat_number }
                    </div>
                  </div>
                }

                { billingAddress &&
                  <div className='detail-item flex-box flex-column'>
                    <div className='flex-1'>
                      { appProps.translations.company.billing_address }
                    </div>
                    <div className="bold">
                      { billingAddress }
                    </div>
                  </div>
                }

                { order.invoice.additional_info &&
                  <div className='detail-item flex-box flex-column'>
                    <div className="flex-1">
                      { appProps.translations.invoices.additionalInfo }
                    </div>
                    <div className="bold">
                      <div dangerouslySetInnerHTML={{ __html: order.invoice.additional_info.replace(/\n/g, "<br>") }} />
                    </div>
                  </div>
                }
              </div>
            }

            <div className="flex-box content-end">
              <div className='flex-box items-center content-center pointer button inverted' onClick={ ()=> printOrder() } id="print_order">
                <i className='fa-light fa-print' style={{ marginRight: 5 }} />
                <div>{ appProps.translations.orders.print_order }</div>
              </div>
            </div>

          </div>

        </div>
      </div>

      <OrderTrackers
        renderTrackers={ renderTrackers }
        order={ order }
        currency={ appProps.currency }
        skroutzAnalyticsActive={ skroutzAnalyticsActive }
        facebookPixelId={ appProps.facebookPixelId }
        googleAnalyticsId={ appProps.googleAnalyticsId }
        googleMeasurementId={ appProps.googleMeasurementId }
      />
    </>
  )
}
