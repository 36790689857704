import React, { useState, useEffect } from "react"
import Select from "react-select"
import { SliderImages, Loader } from '@reactiveonline/frontend_shared_components'

import ProductShowBottom from "../products/ProductShowBottom"
import RelatedProducts from "../products/RelatedProducts"
import SimilarProducts from "../products/SimilarProducts"
import AddToCart from "../products/AddToCart"
import ProductStock from "../products/ProductStock"
import ProductItem from "../products/ProductItem"
import { customReactSelectStyles, customReactSelectTheme, getTranslatableField } from "../../helpers/utils"
import { loadProductViewAnalytics } from '../../helpers/analytics'

export default function Bundle({
  appProps, bundle, category, currency, isOnSlider, productsPerRowClass, bundlePageLayout,
  bundlePageBottomLayout, addCartText, bundleItemsPath, hasBuilderContent, getBuilderContentUrl,
  similarItemsPath, relatedProductsPath
}) {

  const upcEntry = bundle.attributes.filter(attr => attr.prototypeAttributeKey === "UPC")[0]
  let upcValue = ''
  if(upcEntry) {
    upcValue = getTranslatableField(upcEntry, 'title', appProps.currentLocale)
  }

  let bundleImages = bundle.images.length > 0 ? bundle.images.map( image => {
    return {
      original: image.preview,
      thumbnail: image.thumbnail,
      fullscreen: image.banner,
    }
  }
  ) : [{
    original: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    thumbnail: '',
    fullscreen: ''
  }]

  const [displayPrice, setDisplayPrice] = useState(bundle.displayPrice)
  const [displayTaxPrice, setDisplayTaxPrice] = useState(bundle.displayTaxPrice)
  const [displayTotalPrice, setDisplayTotalPrice] = useState(bundle.displayTotalPrice)
  const [displayCompareToPrice, setDisplayCompareToPrice] = useState(bundle.displayCompareToPrice)
  const [sku, setSku] = useState(bundle.sku)
  const [upc, setUpc] = useState(upcValue)
  const [inStock, setInStock] = useState(bundle.inStock)
  const [stock, setStock] = useState(bundle.totalStock)
  const [onSale, setOnSale] = useState(bundle.onSale)
  const [onSalePercentage, setOnSalePercentage] = useState(bundle.onSalePercentage)
  const [loading, setLoading] = useState(false)
  const [selectedQuantity, setSelectedQuantity] = useState(1)
  const [images, setImages] = useState(bundleImages)
  const [bundleItems, setBundleItems] = useState([])
  const [similarItems, setSimilarItems] = useState([])
  const [relatedProducts, setRelatedProducts] = useState([])

  useEffect(() => {
    loadProductViewAnalytics(bundle, appProps)
  }, []);

  useEffect(() => {
    setLoading(true)
    Rails.ajax({
      type: "GET",
      url: bundleItemsPath,
      dataType: "json",
      success: res => {
        setBundleItems(res.items)
      },
      complete: res => {
        setLoading(false)
      }
    })
  }, []);

  useEffect(() => {
    setLoading(true)
    Rails.ajax({
      type: "GET",
      url: similarItemsPath,
      dataType: "json",
      success: res => {
        setSimilarItems(res.items)
      },
      complete: res => {
        setLoading(false)
      }
    })
  }, []);

  useEffect(() => {
    setLoading(true)
    Rails.ajax({
      type: "GET",
      url: `${ relatedProductsPath }?type=bundle`,
      dataType: "json",
      success: res => {
        setRelatedProducts(res.items)
      },
      complete: res => {
        setLoading(false)
      }
    })
  }, []);

  function getMaxQuantity(maxPurchasableQuantity, maxProductQuantity = appProps.maxProductQuantity) {
    if (maxProductQuantity && maxPurchasableQuantity) {
      return Math.min(...[maxProductQuantity, maxPurchasableQuantity])
    }
    return Math.max(...[maxProductQuantity, maxPurchasableQuantity])
  }

  let defaultQuantityOption = {value: 1, label: '1'}

  const bundleSubtitle = getTranslatableField(bundle, 'subtitle', appProps.currentLocale)
  const maxQuantity = getMaxQuantity(bundle.maxPurchasableQuantity)

  return (
    <>
      <div id='product_page_above_product'></div>
      <div className={ `product-show-wrapper ${bundlePageLayout != 'image_top' && 'main-container'} ${bundlePageLayout}` }>
        <div className="product-image" style={{ position: 'relative' }}>
          <SliderImages
            images={ images }
            showThumbnails={ images && images.length > 1 && true }
            showFullscreenButton={ false }
            showNav={ images && images.length > 1 && true }
            showBullets={ images && images.length > 1 && true }
            lightbox={ true }
            thumbnailPosition={ images && images.length > 1 ? appProps.thumbnailsPosition : 'bottom' }
          />

          { bundle.badges &&
            <div className='flex-box items-center product-badges-wrapper'>
              { bundle.badges && bundle.badges.map( (badge, index) => (
                <img
                  key={ index }
                  src={ badge.source }
                  className='product-badge'
                />
              ))}
            </div>
          }

          { onSale &&
            <div className='on-sale-badge flex-box items-center content-center'>
              { onSale && `-${Math.round(onSalePercentage)}%` }
            </div>
          }
        </div>
        <div className={`product-details ${bundlePageLayout === 'image_top' && 'main-container'}`}>
          <div className="product-info">
            <h1>
              { getTranslatableField(bundle, 'title', appProps.currentLocale, false) }
            </h1>
            <div id='product_page_below_title'></div>

            { bundleSubtitle && bundleSubtitle !== '' &&
              <div dangerouslySetInnerHTML={{__html: bundleSubtitle}}></div>
            }

            { bundle.webshorts[appProps.currentLocale] && bundle.webshorts[appProps.currentLocale].length > 0 &&
              <div style={{ marginBottom: 15 }}>
                { bundle.webshorts[appProps.currentLocale].join(', ') }
              </div>
            }

            { bundle.showPrice &&
              <>
                <div className='display-price-wrapper flex-box items-center'>
                  <div className='display-price'>
                    { displayTotalPrice }
                  </div>
                  { onSale &&
                    ( appProps.showTextInsteadOfCompareToPrice ?
                      <div className='compare-to-price-text'>
                        { appProps.translations.products.discount_price }
                      </div> :
                      <div className='compare-to-price'>
                        { displayCompareToPrice }
                      </div>
                    )
                  }
                </div>
                { bundle.hasTax && !bundle.appliedTax.includedInPrice &&
                  <div className="tax-amount">
                    { `(${appProps.translations.products.includes} ${bundle.appliedTax.title} ${Math.round(bundle.appliedTax.rate * 100)}%)` }
                  </div>
                }
              </>
            }
            <div id='product_page_below_price'></div>

            { sku &&
              <div className='product-code'>
                <div className='label'>{ appProps.translations.products.sku }:</div>
                <div className='value'>{ sku }</div>
              </div>
            }
            { upc &&
              <div className='product-code'>
                <div className='label'>{ appProps.translations.products.upc }:</div>
                <div className='value'>{ upc }</div>
              </div>
            }

            { appProps.stockAllowed && appProps.showStockInfo &&
              <ProductStock
                appProps={ appProps }
                stock={ bundle.stock }
                inStock={ bundle.inStock }
              />
            }
          </div>

          <div className="product-options-wrapper">

            {(!appProps.stockAllowed || (appProps.stockAllowed && inStock && bundle.showPrice)) &&
              <div className="product-option-wrapper quantity-dropdown-wrapper">
                <div className='option-type-title-wrapper'>
                  { appProps.translations.cart.quantity }
                </div>
                <Select
                  theme={customReactSelectTheme}
                  styles={customReactSelectStyles}
                  isSearchable={ false }
                  options={ Array(stock === null || stock > maxQuantity ? maxQuantity : stock).fill().map( (x, i) => ({ value: i + 1, label: `${ i + 1 }` })) }
                  defaultValue={defaultQuantityOption}
                  onChange={(selected) => {
                    setSelectedQuantity(selected.value)
                  }}
                />
              </div>
            }
            <div id='product_page_below_quantity'></div>

            {(!appProps.stockAllowed || (appProps.stockAllowed && inStock)) &&
              <AddToCart
                appProps={ appProps }
                productId={ bundle.id }
                quantity={ selectedQuantity }
                addCartText={ appProps.translations.products.add_to_cart_button }
                currency={ currency }
                product={ bundle }
                isBundle
              />
            }
          </div>
        </div>
      </div>

      { loading ? <Loader size={'medium'} position={'center'} /> :
        <>
          { bundleItems.length > 0 &&
            <div className="main-container similar-products-wrapper" style={{ marginBottom: 10 }}>
              <div className="heading">
                <h2>
                  { appProps.translations.products.included_items }
                </h2>
              </div>
              <div className="items-list grid">
                { bundleItems.map((item, index) => {
                    return (
                      <ProductItem
                        key={ index }
                        appProps={ appProps }
                        product={ item }
                        currency={ currency }
                        showStockInfo={ false }
                        isOnSlider={ false }
                        quickViewActions={ appProps.productQuickActions }
                        productsPerRowClass={ 'four_items' }
                        isBundleItem
                      />
                    )
                  })
                }
              </div>
            </div>
          }
        </>
      }

      <ProductShowBottom
        appProps={ appProps }
        product={ bundle }
        category={ category }
        productPageBottomLayout={ bundlePageBottomLayout }
        hasBuilderContent={ hasBuilderContent }
        getBuilderContentUrl={ getBuilderContentUrl }
        hasContainer={ true }
      />
      <div id='product_page_above_similars'></div>

      { similarItems.length > 0 &&
        <SimilarProducts
          appProps={ appProps }
          similarProducts={ similarItems }
        />
      }

      <div id='product_page_above_related'></div>
      { relatedProducts.length > 0 &&
        <RelatedProducts
          appProps={ appProps }
          relatedProducts={ relatedProducts }
        />
      }
    </>
  )
}
