import React from "react"

export default class CustomSelect extends React.Component {

  render () {
    const { options, translations, value, onClick } = this.props

    return (
      <div className="select-box">
        <div className="select-box__current" tabIndex="1">
          <div className="select-box__value">
            <input
              className="select-box__input"
              defaultValue={ value }
              defaultChecked="checked"
            />{ value }
            <p className="select-box__input-text">{ value }</p>
          </div>
          <i className="select-box__icon down_icon" aria-hidden="true"></i>
        </div>
        <ul className="select-box__list">
        {
          options && options.map( (option, index) => {
            return (
              <li
                key={index}
                onClick={ () => {onClick(option) }}
              >
                <label
                  className="select-box__option"
                  htmlFor={ option.value }
                >
                  { option.title }
                </label>
              </li>
            )
          })
        }
        </ul>
      </div>
    )
  }
}
