import React from 'react'
import PromotionRule from './promotion_rule'


function ruleValuesToMap(values){
  let map = new Map()

  for(const val of values){
    map.set({id: val.promotable_id, type: val.promotable_type}, {record_id: val.id, _destroy: val._destroy})
  }
  return map
}

export default class PromotionRules extends React.Component {
  constructor(props){
    super(props)

    this.state={
      rules: (this.props.rules || []).map(rule => {
        return {
          id: rule.id,
          type: rule.type,
          values: ruleValuesToMap(rule.values),
          _destroy: rule._destroy
        }
      })
    }

    this.handleFormSubmit =  this.handleFormSubmit.bind(this)
  }

  componentDidMount(){
    document.querySelector(`form#${this.props.form_id}`).addEventListener('submit', this.handleFormSubmit)
  }

  handleFormSubmit(event){
    event.preventDefault()
    const htmlForm = document.querySelector(`form#${this.props.form_id}`)
    let fd = new FormData(htmlForm)

    const { rules } = this.state
    for (let i = 0; i < rules.length; i++) {
      if(rules[i].id > 0){
        fd.append(`saas_promotion[rules_attributes][${i}][id]`, rules[i].id)
      }

      fd.append(`saas_promotion[rules_attributes][${i}][type]`, rules[i].type)

      let j = 0;

      rules[i].values.forEach((details, promotable) => {
        if(details.record_id){
          fd.append(`saas_promotion[rules_attributes][${i}][values_attributes][${j}][id]`, details.record_id)
        }

        fd.append(`saas_promotion[rules_attributes][${i}][values_attributes][${j}][promotable_type]`, promotable.type)
        fd.append(`saas_promotion[rules_attributes][${i}][values_attributes][${j}][promotable_id]`, promotable.id)

        if(details._destroy){
          fd.append(`saas_promotion[rules_attributes][${i}][values_attributes][${j}][_destroy]`, details._destroy)
        }

        j++
      });

      if(rules[i]._destroy){
        fd.append(`saas_promotion[rules_attributes][${i}][_destroy]`, rules[i]._destroy)
      }
    }

    Rails.ajax({
      type: this.props.form_method,
      url: htmlForm.action,
      contentType: 'multipart/form-data',
      dataType: 'script',
      data: fd
    })
  }

  saveRule(id, type, values, _destroy){
    let stateRules = [...this.state.rules]

    if(typeof id === 'undefined'){
      stateRules.push({
        id: -stateRules.size,
        type: type,
        values: values
      })
    }
    else {
      const index = stateRules.findIndex(rule => rule.id === id)

      stateRules.splice(index, 1, {
        id: id,
        type: type,
        values: values,
        _destroy: _destroy
      })
    }

    this.setState({rules: stateRules})
  }

  destroyRule(id){
    let stateRules = [...this.state.rules]
    const index = stateRules.findIndex(rule => rule.id === id)

    if(id > 0){
      stateRules[index]._destroy = '1'
    }
    else{
      stateRules.splice(index, 1)
    }

    this.setState({rules: stateRules})
  }

  render(){
    const usedTypes = this.state.rules.filter(rule => !rule._destroy).map(rule => { return rule.type })

    return(
      <div className="card">
        <div className="card-content">
          {
            this.props.rule_types.length != usedTypes.length && this.props.allowEditing &&
            <PromotionRule
              isTemplate={ true }
              rule={ {
                id: undefined,
                type: undefined,
                values: new Map()
              } }
              rule_types={ this.props.rule_types.filter(type => !usedTypes.includes(type[0])) }
              rule_value_labels={ this.props.rule_value_labels}
              saveRule={ this.saveRule.bind(this) }
            />
          }
          <div id="rule-list" className="card-body">
          {
            this.state.rules.map((rule, index) => {
              if(rule._destroy){ return null }

              return(
                <PromotionRule
                  destroyRule={ this.destroyRule.bind(this) }
                  disabled={ !this.props.allowEditing }
                  key={index}
                  rule={ rule }
                  rule_types={ this.props.rule_types.filter(type => !usedTypes.includes(type[0]) || type[0] == rule.type) }
                  rule_value_labels={ this.props.rule_value_labels}
                  saveRule={ this.saveRule.bind(this) }
                />
              )
            })
          }
          </div>
        </div>
      </div>
    )
  }
}