import React from "react"

export default function ActiveFilters({
  appProps, appliedFilterOptions, productFilters, submitFilterOption, clearAllFilters, currency
}) {

  return (
    <div className="active-filters_wrapper">
      {
        Object.keys(appliedFilterOptions).map((optKey, optIndex) => {
          return appliedFilterOptions[optKey].map((option, index) => {
            let optionTitle = ''
            let onClickRemoveFilter

            if(productFilters) {
              if(optKey === 'brand' && productFilters.brandFilters) {
                Object.keys(productFilters.brandFilters).map(k => {
                  const filterValues = JSON.parse(k)
                  if(filterValues[1] === option) {
                    optionTitle = filterValues[0]
                  }
                })

                onClickRemoveFilter = () => {
                  submitFilterOption('brand', option)
                }
              } else if(optKey === 'on_sale') {
                optionTitle = appProps.translations.products.on_sale
                onClickRemoveFilter = () => {
                  submitFilterOption('on_sale', 'true')
                }
              } else if(optKey === 'price_range') {
                optionTitle = `${appProps.translations.products.price_range}: `
                if (option.lowerPriceLimit !== "" && option.upperPriceLimit !== ""){
                  const lowerPriceValue = `${currency.symbol_first ? currency.symbol + option.lowerPriceLimit : option.lowerPriceLimit + currency.symbol}` 
                  const upperPriceValue = `${currency.symbol_first ? currency.symbol + option.upperPriceLimit : option.upperPriceLimit + currency.symbol}` 
                  optionTitle +=  lowerPriceValue + " - " + upperPriceValue
                } else if (option.lowerPriceLimit === "" && option.upperPriceLimit !== "") {
                  optionTitle += `${appProps.translations.products.until_price} ${currency.symbol_first ? currency.symbol + option.upperPriceLimit : option.upperPriceLimit + currency.symbol}` 
                } else if (option.lowerPriceLimit !== "" && option.upperPriceLimit === "") {
                  optionTitle += `${appProps.translations.products.from_price} ${currency.symbol_first ? currency.symbol + option.lowerPriceLimit : option.lowerPriceLimit + currency.symbol}` 
                }
                
                onClickRemoveFilter = () => {
                  submitFilterOption('price_range', {lowerPriceLimit: "", upperPriceLimit: ""})
                } 
              } else {
                if(productFilters.optionFilters[optKey]) {
                  Object.keys(productFilters.optionFilters[optKey]).map(k => {
                    const filterValues = JSON.parse(k)
                    if(filterValues[1] === option) {
                      optionTitle = filterValues[0]
                    }
                  })
                }

                onClickRemoveFilter = () => {
                  submitFilterOption(optKey, option)
                }
              }
            }

            if(optionTitle) {
              let title
              if(optionTitle[0] === '{') {
                let filterTranslations = JSON.parse(optionTitle)
                title = filterTranslations[appProps.currentLocale] && filterTranslations[appProps.currentLocale] !== '' ? filterTranslations[appProps.currentLocale] : filterTranslations[appProps.defaultLocale]
              } else {
                title = optionTitle
              }

              return (
                <div key={index} className="filter pointer" onClick={onClickRemoveFilter}>
                  <div className="option">{title}</div>
                  <div className="remove"/>
                </div>
              )
            } else {
              return null
            }
          })
        })
      }

      { Object.keys(appliedFilterOptions).length > 0 &&
        <div className="filter pointer" onClick={() => {
          clearAllFilters()
        }}>
          <div className="option">{ appProps.translations.products.clear_all_filters }</div>
          <div className="remove" />
        </div>
      }
    </div>
  )
}
