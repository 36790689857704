import React, { useState, useEffect } from 'react'
import ProductSearch from './ProductSearch'

export default function GlobalProductSearch({ appProps }) {
  const [showOverlay, setShowOverlay] = useState(false)

  useEffect( () => {
    document.addEventListener('keydown', event => {
      if (event.which === 27) {
        setShowOverlay(false)
      }
    })
  }, [])

  return (
    <>
      { appProps.controllerName != 'checkouts' &&
        <li>
          { appProps.headerVersion != '6' &&
            <div className='search-wrapper'>
              <div id='search-button' onClick={ () => setShowOverlay(true) }/>
              { showOverlay &&
                <div id='search-overlay-wrapper'>
                  <div
                    id='search-close'
                    className='close_icon'
                    onClick={ () => setShowOverlay(false) }
                  />
                  <div className='product-search'>
                    <ProductSearch
                      appProps={ appProps }
                      autoFocus
                    />
                  </div>
                </div>
              }
            </div>
          }
        </li>
      }
    </>
  )
}
