import React from "react"
import Rails from "rails-ujs"
import ReactDOM from "react-dom"

import TopBannerFullWidth from "../shared/TopBannerFullWidth"
import MiniCart from "../header/MiniCart"
import ProductPresentableTitle from '../shared/ProductPresentableTitle'
import moment from 'moment'

import { addToCartAnalytics } from '../../helpers/analytics'
import { addToCartLegacyAnalytics } from '../../helpers/legacy_analytics'

export default function AddToCart({
  appProps, currency, selectedOptionsIds, productId, quantity,
  addToCartUrl, addToCartUrlMethodType, adminClass, addCartText, product,
  isBundle
}) {

  const { flashMessage, currentLocale, translations, googleMeasurementId, stockAllowed } = appProps
  const orderStatus = product.productOrderStatus || {}
  const backorderOutOfStock = stockAllowed && !product.inStock && orderStatus.canBackorder

  function addToCart() {
    //Remove error messages from previous click
    document.querySelectorAll('[id$="-error-message"]').forEach(errorElement => errorElement.remove())
    document.querySelectorAll('input').forEach(element => element.style.borderColor = '')

    let fd = new FormData()
    fd.append("product_id", productId)
    fd.append("quantity", quantity)

    if(selectedOptionsIds) {
      fd.append("selected_options_ids", JSON.stringify(selectedOptionsIds))
    }

    if(isBundle) {
      fd.append('is_bundle', true)
    }

    Rails.ajax({
      type: addToCartUrlMethodType || "POST",
      url: addToCartUrl || '/populate',
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      data: fd,
      success: res => {
        if(res.missing_options){
          const missing_options = res.missing_options
          for(let i=0; i<missing_options.length; i++){
            const element = document.querySelector(`div#option_type_${missing_options[i].id}.option-types-${missing_options[i].input_type}-container`)
            if(element) renderErrorMessage(element)
          }
          return
        }

        if (res.message.failure) {
          if (flashMessage) {
            flashMessage.show(res.message.failure, 'error')
          }
          return
        }

        let target = document.getElementById('mini-cart-ajax-container')
        if(target) ReactDOM.render(
          <MiniCart
            appProps={ appProps }
            lineItemsCount={ res.order.lineItems.length }
          />, target
        )

        target = document.getElementById('top-banner-full-width-ajax-container')
        if(target) {
          if(res.message.success) {
            const localePrefix = currentLocale ? `/${ currentLocale }` : ''
            ReactDOM.render(
              <TopBannerFullWidth
                appProps={ appProps }
                infoMessage={ `${ translations.cart.added_to_cart }: ${ res.quantity } x ` }
                linkTitle={ translations.checkout.proceed_to_checkout }
                linkTo={ `${ localePrefix }/checkout` }
                order={res.order}
                currency={res.order.currency.iso_code}
                InfoComponent={ ProductPresentableTitle }
                infoComponentProps={{
                  entity: product,
                  appProps: appProps
                }} />,
              target
            )
          } else {
            ReactDOM.render(null, target)
          }
        }

        if(res.order_path) {
          window.location.href = res.order_path
        }

        scrollTo({
          top: 0,
          behavior: 'smooth',
          block: 'start'
        })

      },
      error: res => {
      }
    })
  }

  function renderErrorMessage(element){
    let errorMessage = document.createElement("div")
    errorMessage.id = `${element.id}-error-message`,
    errorMessage.classList.add("option-error");
    let errorChild1 = document.createElement("div")
    errorMessage.appendChild(errorChild1)

    element.parentNode.querySelector('.option-type-title-wrapper').appendChild(errorMessage)

    return null
  }

  if (product.showPrice) {
    return (
      <a href=''
        value={ '' }
        className={ `button ${ adminClass || '' } ${ (orderStatus.availableOn || backorderOutOfStock) ? 'tooltip-button' : '' }` }
        rel="nofollow"
        onClick={(e) => {
          e.preventDefault()
          addToCart()
          googleMeasurementId ? addToCartAnalytics(product, appProps) : addToCartLegacyAnalytics(product, appProps)
        }}
      >
        { addCartText }

        { !product.inStock && translations && orderStatus.canPreorder && orderStatus.availableOn &&
          <div className='tooltip'>
            { translations.products.available_on }: { moment(new Date(orderStatus.availableOn)).format('DD/MM/YYYY') }
          </div>
        }

        { translations && backorderOutOfStock &&
          <div className='tooltip'>
            { translations.products.out_of_stock }
          </div>
        }
      </a>
    )
  }
  return <div></div>
}
