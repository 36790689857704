import React from "react"
import Select from "react-select"
import { customReactSelectTheme } from "../../../helpers/utils"
import { formatPriceWithCurrency } from "../../../helpers/utils"
import { Loader } from '@reactiveonline/frontend_shared_components'

// flexWrap: 'nowrap' must be added to valueContainer to avoid a visual bug with the select component
// helpers/utils.js custormReactSelectStyles remains untouched in case this small change breaks other parts of the app
const customReactSelectStyles = {
  option: (provided, state) => {
    let color = '#222222'
    let background = '#ffffff'
    if(state.isSelected) {
      color = '#ffffff'
      background = '#666666'
    } else if(state.isFocused) {
      background = '#f7f7f7'
    }

    return {
      ...provided,
      color: color,
      background: background
    }
  },
  control: (provided, state) => ({
    ...provided,
    height: 40,
    minHeight: 40,
    border: '1px solid hsl(0,0%,80%)',
    boxShadow: state.isFocused ? 0 : provided.boxShadow,
    '&:hover': {
       border: '1px solid hsl(0,0%,80%)'
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 40,
    flexWrap: 'nowrap'
  })
}

export default class ShippingMethod extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      courierSmartpoints: [],
      reactSelectOptions: [],
      showSmartpoints: !!props.selectedSmartpoint,
      selectedSmartpoint: props.selectedSmartpoint,
    }
  }

  componentDidMount() {
    if (this.props.shippingMethod.api_integration_entity?.value === 'acs') {
      const countryCode = this.props.address.country
      let country = null

      if (countryCode === 300) {
        country = 'GR'  
      }
      if (countryCode === 196) {
        country = 'CY'
      }

      if (country) {
        let data = new FormData()
        data.append('country', country)

        Rails.ajax({
          type: 'GET',
          url: `${this.props.acsSmartpointsPath}?country=${country}`,
          dataType: 'json',
          success: res => {
            const options = res.smartpoints.map(el => ({
              value: el,
              label: `${el.ACS_SHOP_STATION_DESCR.split('(')[0]} (${el.ACS_SHOP_ADDRESS}, ${el.ACS_SHOP_ZIPCODE})`
            }))
            this.setState({ courierSmartpoints: res.smartpoints, reactSelectOptions: options })
          }
        })
      }
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.selectedSmartpoint !== this.state.selectedSmartpoint) {
      this.props.handleSelectShippingMethod(this.props.shippingMethod, this.state.selectedSmartpoint)
    }
  }

  render() {
    const { shippingMethod, selectedMethod, translations, currency, multipleShipmentsOptionEnabled, handleSelectShippingMethod } = this.props

    const selected = selectedMethod.id === shippingMethod.id
    const selectClass = "checked_icon selected"
    const shippingMethodGrossCost = multipleShipmentsOptionEnabled ? shippingMethod.multiple_shipments_gross_cost : shippingMethod.gross_cost

    return(
      <div
        className={ `option ${ selected && selectClass } ${ shippingMethodGrossCost === null ? 'unavailable' : '' }` }
        onClick={ () => shippingMethodGrossCost !== null && handleSelectShippingMethod(shippingMethod, this.state.selectedSmartpoint) }
      >
        { this.props.shippingMethod.api_integration_entity?.value === 'acs' &&
          this.state.reactSelectOptions.length === 0 &&
          selected
          ? <Loader 
            size={ 'large' }
            position={ 'center' }
          />
          : <>
            <div className="name">
              { shippingMethod.title }
            </div>

            <div dangerouslySetInnerHTML={{__html: shippingMethod.description}} className="description"></div>

            {
              shippingMethod.gross_cost !== null && shippingMethod.gross_cost > 0 &&
                <div className="cost">
                  { `+ ${formatPriceWithCurrency(shippingMethod.gross_cost.toFixed(2), currency)}` }
                </div>
            }
            { shippingMethod.gross_cost === null && shippingMethod.missing_amount &&
                <div className="cost">
                  { `${translations.checkout.shipping_method_missing_amount} ${formatPriceWithCurrency(shippingMethod.missing_amount.toFixed(2), currency)}` }
                </div>
            }
            { this.state.courierSmartpoints.length > 0 && selected && 
              <>
                <div>
                  <div style={{ marginTop: 50 }}>
                    <div className="switch-wrapper">
                      <div className="flex-box items-center">
                        <label className="switch">
                          <input
                            id="smartpoints-toggle"
                            type="checkbox"
                            name="smartpointsToggle"
                            value="1"
                            defaultChecked={ this.state.showSmartpoints }
                            onChange ={ () => this.setState(prevState => ({ ...prevState, showSmartpoints: !prevState.showSmartpoints, selectedSmartpoint: null })) }
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="switch-text">
                        { this.props.translations.orders.pickup_from_smartpoint }
                      </div>
                    </div>
                    { this.state.showSmartpoints && 
                      <Select
                        id="courier-smartpoints"
                        theme={ customReactSelectTheme }
                        styles={ customReactSelectStyles }
                        isSearchable={ true }
                        options={ this.state.reactSelectOptions }
                        value={ this.state.selectedSmartpoint }
                        onChange={ (option) => { this.setState({ selectedSmartpoint: option }) } }
                      />
                    }
                  </div>
                </div>
              </>
            }
          </>
        }
      </div>
    )

  }
}
