import React, { useState, useEffect } from 'react'
import { SelectInput } from '@reactiveonline/frontend_shared_components'

export default function CountrySelector({
  styles, setSettings, countriesPath, selectedCountry, selectedState,
  countryLabel, stateLabel, disabled, setSelectedCountryData
}) {
  const [availableCountries, setAvailableCountries] = useState([])
  const [availableStates, setAvailableStates] = useState([])

  useEffect(() => {
    Rails.ajax({
      type: "GET",
      url: countriesPath,
      dataType: "json",
      success: res => {
        if (res.available_countries) {
          setAvailableCountries(res.available_countries)
        }
      }
    })
  }, [])

  useEffect(() => {
    if (selectedCountry) {
      Rails.ajax({
        type: "GET",
        url: `${ countriesPath }/${ selectedCountry }/states`,
        dataType: "json",
        success: res => {
          if (res.available_states) {
            setAvailableStates(res.available_states)
          }
        }
      })

      Rails.ajax({
        type: "GET",
        url: `${ countriesPath }/${ selectedCountry }`,
        dataType: "json",
        success: res => {
          if (res.country) {
            setSelectedCountryData(res.country)
          }
        }
      })
    }
  }, [selectedCountry])

  function handleCountryChange(country){
    setSettings({ country: country.value, state: null })
  }

  return (
    <>
      <SelectInput
        wrapperClasses={ styles || 'col l3 m3 s12' }
        label={ countryLabel }
        options={ [{ value: null, title: '-' }, ...availableCountries] }
        currentOption={ availableCountries.length > 0 ? selectedCountry : null }
        setCurrentOption={ (option) => handleCountryChange(option) }
        disabled={ disabled }
      />

      { selectedCountry &&
        <SelectInput
          wrapperClasses={ styles || 'col l3 m3 s12' }
          label={ stateLabel }
          options={ [{ value: null, title: '-' }, ...availableStates] }
          currentOption={ availableStates.length > 0 ? selectedState : null }
          setCurrentOption={ (option) => setSettings({ state: option.value }) }
          disabled={ disabled }
        />
      }
    </>
  )
}
