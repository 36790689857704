import React from "react"
import { Loader, AuthorizableLink } from '@reactiveonline/frontend_shared_components'

import Cart from "./Cart"
import IncompleteStep from "./steps/IncompleteStep"
import CompletedStep  from "./steps/CompletedStep"
import EmailStep      from "./steps/EmailStep"
import DeliveryStep   from "./steps/DeliveryStep"
import ShippingStep   from "./steps/ShippingStep"
import BillingStep    from "./steps/BillingStep"
import Coupons from './Coupons'
import DisableableField from '../shared/DisableableField'
import LineItemErrorsModal from './LineItemErrorsModal'

import { calculateCartSubTotal, calculateCartTax, calculateCartWeight } from './helpers/calculators'
import { createOrderForm } from './helpers/orderUpdateForm'
import { urlParamsAsObject, formatPriceWithCurrency } from '../../helpers/utils'
import PendingOrderModal from "./PendingOrderModal"

const status = ["incomplete", "completed"]

export default class Checkouts extends React.Component {

  constructor(props) {
    super(props)

    const deliveryEnabled = this.props.deliveryOptions.includes("delivery")
    const deliveryStepNextStep = deliveryEnabled ? "ShippingStep" : "BillingStep"
    let deliveryOption = this.props.deliveryOptions[0]
    let billingProps = {}, deliveryProps = {}, selectedShippingMethod = {}, selectedPaymentMethod = {}, acceptedTerms = false

    const { email_step, delivery_step, shipping_method, payment_step, change_details } = this.props.appProps.translations.checkout
    const stepsArray = [
      { stepName: "EmailStep",    stepTitle: `${email_step}`,      nextStep: "DeliveryStep",       changeText: `${change_details}`, status: status[0], visible: true },
      { stepName: "DeliveryStep", stepTitle: `${delivery_step}`,   nextStep: deliveryStepNextStep, changeText: `${change_details}`, status: status[0], visible: true },
      { stepName: "ShippingStep", stepTitle: `${shipping_method}`, nextStep: "BillingStep",        changeText: `${change_details}`, status: status[0], visible: deliveryEnabled },
      { stepName: "BillingStep",  stepTitle: `${payment_step}`,    nextStep: undefined,            changeText: `${change_details}`, status: status[0], visible: true }
    ]
    let currentStep = stepsArray[0]

    let addressFallbackProps = {
      address:    "",
      area:       "",
      city:       "",
      country:    "",
      mobile:     "",
      fullName:   "",
      phone:      "",
      postalCode: "",
      countryAlpha2Code: "",
      stateLabel: ""
    }

    if (this.props.order.email) {
      acceptedTerms = true
      currentStep = stepsArray[1]
      stepsArray[0].status = status[1]
    }

    if(Object.keys(this.props.orderDeliveryAddress).length > 0){
      deliveryProps = this.props.orderDeliveryAddress
      deliveryOption = this.props.orderDeliveryAddress.address ? this.props.deliveryOptions.find(option => option === 'delivery') : this.props.deliveryOptions.find(option => option === 'pickup')

      if (!this.props.orderDeliveryAddress.isFromPreviousOrder && (deliveryOption !== 'pickup' || deliveryProps.storeId)) { // when entering checkout for the first time then show the delivery address step sto the user can validate their info
        currentStep = stepsArray.find(step => step.stepName === deliveryStepNextStep)
        stepsArray[1].status = status[1]
      }
    }else{
      deliveryProps = { ...addressFallbackProps, storeId: null }
    }

    if (deliveryEnabled && this.props.selectedShippingMethod) {
      selectedShippingMethod = this.props.selectedShippingMethod

      if (deliveryOption === "delivery" && selectedShippingMethod.type !== 'LocalPickup') {
        currentStep = stepsArray[3]
        stepsArray[2].status = status[1]

      } else if (deliveryOption === "pickup" && deliveryProps.storeId) {
        currentStep = stepsArray[3]
        stepsArray[2].visible = false
      }
    }

    if(Object.keys(this.props.orderBillingAddress).length > 0){
      billingProps = { ...this.props.companyBillingAddress, ...this.props.orderBillingAddress } // use companyBillingAddress so we can populate the company name for the invoice
    }else{
      billingProps = addressFallbackProps
    }

    if (this.props.selectedPaymentMethod) {
      selectedPaymentMethod = this.props.paymentMethods.find(method => method.id == this.props.selectedPaymentMethod)
    } else {
      selectedPaymentMethod = this.props.paymentMethods[0]
    }

    this.state = {
      loading: false,
      cartLoading: false,
      casLoading: false,
      showPaymentIframe: false,
      selectedStoreId: '',
      currentStep: currentStep || stepsArray[0],
      steps: stepsArray,
      user: this.props.appProps.user ? this.props.appProps.user : {},
      emailProps: {
        email:         this.props.order.email || this.props.appProps.currentUserEmail || '',
        acceptedTerms: acceptedTerms
      },
      deliveryProps: {
        deliveryOption: deliveryOption,
        orderNotes: this.props.order.special_instructions || "",
        ...deliveryProps
      },
      shippingProps: {
        selectedMethod: !deliveryEnabled && Object.keys(this.props.localPickup).length > 0 ? this.props.localPickup : selectedShippingMethod,
        multipleShipmentsOptionEnabled: this.props.multipleShipmentsOptionEnabled,
        selectedSmartpoint: null
      },
      billingProps: {
        selectedMethod: (currentStep.stepName === "BillingStep") && selectedPaymentMethod ? selectedPaymentMethod : {},
        vatNumber: '',
        companyName: '',
        additionalInfo: '',
        ...billingProps
      },
      cart: this.initiateCartState(this.props.products, this.props.order),
      contactlessEnabled: this.props.order.contactless_delivery,
      isDeliveryAddressUsedAsBilling: !this.props.companyCheckout && deliveryEnabled && deliveryOption === "delivery",
      unavailableLineItems: [],
      revaluatedLineItems: [],
      isCartEditable: true,
      submitStripeCard: false,
      stripePaymentIntentClientSecret: null,
      stripePaymentIntentActionType: null,
      stripeSetupIntentClientSecret: null,
      stripeSetupIntentActionType: null,
      couponAdjustments: this.props.order.couponAdjustments,
      totalWithAdjustments: this.props.order.totalWithAdjustments,
      installments: 1,
      showInvoiceForm: this.props.companyCheckout,
      lineItemErrors: [],
      automationErrors: [],
      paymentMethods: this.props.paymentMethods,
      userConfirmed: false,
      showPendingOrderModal: this.props.newOrderId && this.props.newOrderId !== this.props.order.id
    }
  }

  componentDidMount() {
    let urlParams = urlParamsAsObject(window.location.search)

    if (urlParams.shipping_error) {
      this.props.appProps.flashMessage.show(this.props.appProps.translations.flash_messages[urlParams.shipping_error], 'error')
    }
    if (urlParams.payment_error) {
      this.props.appProps.flashMessage.show(this.props.appProps.translations.flash_messages[urlParams.payment_error], 'error')
    }
    if (urlParams.magic_link_error) {
      this.props.appProps.flashMessage.show(this.props.appProps.translations.flash_messages.magic_link_error, 'error')
    }

    if(this.props.userNeedsEmailConfirmation) {
      this.setState({casLoading: true})

      Rails.ajax({
        type: 'GET',
        url: this.props.appProps.getAccountInfoPath,
        dataType: 'json',
        success: res => {
          if(res.account) {
            this.setState({userConfirmed: res.account.confirmed, casLoading: false})
          } else {
            this.setState({casLoading: false})
          }
        },
        error: res => {
          this.setState({casLoading: false})
        }
      })
    }

    if (this.state.couponAdjustments.length) {
      this.setLoading(true)
      let fd = new FormData()
      if (this.props.adminOrderNumber) fd.append('order_id', this.props.order.id)

      Rails.ajax({
        url: '/checkout/check_and_update_coupons',
        type: 'POST',
        dataType: 'json',
        data: fd,
        success: res => {
          this.couponsValidationAfterAction(res)
        },
        error: res => {
          this.setState({ loading: false })
        }
      })
    }

    const shippingStep = this.state.steps.find(step => step.stepName == "ShippingStep")
    if (shippingStep && shippingStep.visible && shippingStep.status == status[1]){
      this.reviseStep("ShippingStep")
    }

  }

  initiateCartState(products, order) {
    return {
      products:     products,
      shippingCost: order.shippingCost,
      shippingCostNet: order.shippingCostNet,
      paymentCost:  order.handlingFeeGross,
      paymentCostNet: order.handlingFeeNet,
      subTotal:     calculateCartSubTotal(products),
      // tax:          calculateCartTax(products),
      tax:          order.vatTotal,
      total:        order.total,
      weight:       calculateCartWeight(products)
    }
  }

  isContactlessAvailable(){
    const { contactlessOptionAvailable }  = this.props
    const { deliveryProps, billingProps } = this.state

    return contactlessOptionAvailable && deliveryProps.deliveryOption !== "pickup" && billingProps.selectedMethod.type && billingProps.selectedMethod.type !== "CashPayment" && billingProps.selectedMethod.type !== "CardOnDeliveryPayment"
  }

  renderStep(stepName, index, googleAnalyticsId, googleMeasurementId){
    switch( stepName ){
      case "EmailStep":
        return (
          <EmailStep
            key                = { index }
            acceptedTerms      = { this.state.emailProps.acceptedTerms      }
            email              = { this.state.emailProps.email              }
            proceedToNextStep  = { this.proceedToNextStep.bind(this)        }
            updateStep         = { this.updateStep.bind(this)               }
            legalTerms         = { this.props.legalTerms                    }
            appProps           = { this.props.appProps                      }
          />
        )
      case "DeliveryStep":
        return (
          <DeliveryStep
            key                  = { index                                }
            appProps             = { this.props.appProps                             }
            address              = { this.state.deliveryProps.address     }
            area                 = { this.state.deliveryProps.area        }
            city                 = { this.state.deliveryProps.city        }
            country              = { this.state.deliveryProps.country     }
            fullName             = { this.state.deliveryProps.fullName    }
            phone                = { this.state.deliveryProps.phone       }
            mobile               = { this.state.deliveryProps.mobile      }
            postalCode           = { this.state.deliveryProps.postalCode  }
            deliveryOption       = { this.state.deliveryProps.deliveryOption }
            options              = { this.props.deliveryOptions              }
            orderNotes           = { this.state.deliveryProps.orderNotes     }
            storeId              = { this.state.deliveryProps.storeId        }
            proceedToNextStep    = { this.proceedToNextStep.bind(this)       }
            updateStep           = { this.updateDeliveryStep.bind(this)      }
            translations         = { this.props.appProps.translations        }
            toggleShippingStepVisibility = { this.toggleShippingStepVisibility.bind(this) }
            adminOrderNumber     = { this.props.adminOrderNumber }
            googleAnalyticsId    = { googleAnalyticsId           }
            googleMeasurementId  = { googleMeasurementId         }
            currentLocale        = { this.props.currentLocale    }
            requireMobilePhone   = { this.props.requireMobilePhone }
          />
        )
      case "ShippingStep":
        const { contactlessEnabled, deliveryOption, orderNotes, ...addressParams } = this.state.deliveryProps

        return (
          <ShippingStep
            key                    = { index                                   }
            cartSubTotal           = { this.state.cart.subTotal                }
            cartWeight             = { this.state.cart.weight                  }
            selectedMethod         = { this.state.shippingProps.selectedMethod }
            multipleShipmentsOptionEnabled = { this.state.shippingProps.multipleShipmentsOptionEnabled }
            proceedToNextStep      = { this.proceedToNextStep.bind(this)       }
            updateSelectedShipping = { this.updateSelectedShipping.bind(this)  }
            updateMultipleShipmentsOption={ this.updateMultipleShipmentsOption.bind(this) }
            cartLoading            = { this.state.cartLoading                  }
            setCartLoader          = { this.setCartLoader.bind(this)           }
            translations           = { this.props.appProps.translations        }
            addressParams          = { addressParams                           }
            adminOrderNumber       = { this.props.adminOrderNumber             }
            currency               = { this.props.order.currency               }
            googleAnalyticsId      = { googleAnalyticsId                       }
            googleMeasurementId    = { googleMeasurementId                     }
            acsSmartpointsPath     = { this.props.acsSmartpointsPath           }
            selectedSmartpoint     = { this.state.shippingProps.selectedSmartpoint }
          />
        )
      case "BillingStep":
        return (
          <BillingStep
            key                             = { index                                       }
            appProps                        = { this.props.appProps                         }
            deliveryProps                   = { this.state.deliveryProps                    }
            billingProps                    = { this.state.billingProps                     }
            paymentMethods                  = { this.state.paymentMethods                   }
            proceedToNextStep               = { this.proceedToNextStep.bind(this)           }
            selectedMethod                  = { this.state.billingProps.selectedMethod      }
            shippingMethod                  = { this.state.shippingProps.selectedMethod     }
            updateSelectedPayment           = { this.updateSelectedPayment.bind(this)       }
            updateStep                      = { this.updateStep.bind(this)                  }
            useDeliveryAddressAsBilling     = { this.useDeliveryAddressAsBilling.bind(this) }
            isDeliveryAddressUsedAsBilling  = { this.state.isDeliveryAddressUsedAsBilling   }
            translations                    = { this.props.appProps.translations            }
            contactlessEnabled              = { this.state.deliveryProps.contactlessEnabled }
            showContactless                 = { this.isContactlessAvailable()               }
            updateContactless               = { this.updateContactless.bind(this)           }
            adminOrderNumber                = { this.props.adminOrderNumber                 }
            stripePublishableKey            = { this.props.stripePublishableKey             }
            submitStripeCard                = { this.state.submitStripeCard                 }
            stripePaymentIntentClientSecret = { this.state.stripePaymentIntentClientSecret  }
            stripePaymentIntentActionType   = { this.state.stripePaymentIntentActionType    }
            stripeSetupIntentClientSecret   = { this.state.stripeSetupIntentClientSecret    }
            stripeSetupIntentActionType     = { this.state.stripeSetupIntentActionType      }
            setStripeError                  = { this.setStripeError.bind(this)              }
            completeOrder                   = { this.completeOrder.bind(this)               }
            loading                         = { this.state.loading                          }
            setLoading                      = { this.setLoading.bind(this)                  }
            currency                        = { this.props.order.currency                   }
            googleAnalyticsId               = { googleAnalyticsId                           }
            googleMeasurementId             = { googleMeasurementId                         }
            installments                    = { this.state.installments                     }
            updateInstallments              = { this.updateInstallments.bind(this)          }
            invoice                         = { this.props.order.invoice                    }
            canIssueInvoice                 = { this.props.canIssueInvoice                  }
            showInvoiceForm                 = { this.state.showInvoiceForm                  }
            updateShowInvoiceForm           = { this.updateShowInvoiceForm.bind(this)       }
            orderId                         = { this.props.order.id                         }
            type                            = { this.props.type                             }
            companyCheckout                 = { this.props.companyCheckout                  }
            vatNumber                       = { this.state.billingProps.vatNumber           }
            companyName                     = { this.state.billingProps.companyName         }
            additionalInfo                  = { this.state.billingProps.additionalInfo      }
            requireMobilePhone              = { this.props.requireMobilePhone }
            cardsPath                       = { this.props.cardsPath }
            setCurrentCardPath              = { this.props.setCurrentCardPath }
            stripeBillingDetails            = { {
              address: {
                city: this.state.deliveryProps.city,
                country: this.state.deliveryProps.countryAlpha2Code,
                line1: this.state.deliveryProps.address,
                postal_code: this.state.deliveryProps.postalCode,
                state: this.state.deliveryProps.stateLabel
              },
              email: this.state.emailProps.email,
              name: this.state.deliveryProps.fullName,
              phone: this.state.deliveryProps.mobile || this.state.deliveryProps.phone
            } }
          />
        )
    }
  }

  stepDetails(stepName) {
    const { appProps } = this.props
    let deliveryDetails = this.state.deliveryProps
    let deliveryInfo = `${this.props.appProps.translations.checkout.full_name}: ${deliveryDetails.fullName},
                        ${this.props.appProps.translations.checkout.address}, ${deliveryDetails.address},
                        ${this.props.appProps.translations.checkout.city}, ${deliveryDetails.city},
                        ${this.props.appProps.translations.checkout.postal_code} ${deliveryDetails.postalCode},
                        ${this.props.appProps.translations.checkout.phone} ${deliveryDetails.phone} ...`
    let pickupInfo   = `${this.props.appProps.translations.checkout.full_name}: ${deliveryDetails.fullName},
                        ${this.props.appProps.translations.checkout.phone} ${deliveryDetails.phone}`
    let shippingMethodGrossCost = this.state.shippingProps.multipleShipmentsOptionEnabled ? this.state.shippingProps.selectedMethod.multiple_shipments_gross_cost : this.state.shippingProps.selectedMethod.gross_cost
    let details = {
      EmailStep: this.state.emailProps.email,
      DeliveryStep: ` ${ deliveryDetails.address && deliveryDetails.city && deliveryDetails.postalCode ? deliveryInfo : pickupInfo }`,
      ShippingStep: shippingMethodGrossCost !== null && shippingMethodGrossCost !== undefined && `${this.state.shippingProps.selectedMethod.title}: ${formatPriceWithCurrency(shippingMethodGrossCost.toFixed(2), this.props.order.currency)}`,
      BillingStep: this.state.billingProps.selectedMethod.presentation
    }
    return details[stepName]
  }

  proceedToNextStep() {
    const { user, emailProps, deliveryProps, shippingProps, billingProps, isDeliveryAddressUsedAsBilling, contactlessEnabled, currentStep, showInvoiceForm } = this.state

    const form = createOrderForm(
      user,
      emailProps.email,
      deliveryProps,
      shippingProps,
      billingProps,
      isDeliveryAddressUsedAsBilling,
      undefined,
      contactlessEnabled,
      null,
      showInvoiceForm
    )

    form.append("current_step", currentStep.stepName.split('Step')[0].toLowerCase())
    form.append('handling_fee_net', billingProps.selectedMethod.net_handling_fee)

    this.setState({loading: true})

    Rails.ajax({
      url: this.props.adminOrderNumber ? `/admin/${ReactiveCore.locale}/checkout/${this.props.adminOrderNumber}/save_step` : `/${this.props.currentLocale}/checkout/save_step`,
      type: 'PATCH',
      dataType: 'json',
      data: form,
      success: res => {
        this.setState( prevState => {
          return {
            cart: {
              ...prevState.cart,
              paymentCost: res.handlingFee,
              paymentCostNet: res.handlingFeeNet,
              tax: res.vatTotal,
              shippingCost: res.shippingCost,
              shippingCostNet: res.shippingCostNet,
              total: res.total
            }
          }
        })

        if (currentStep.stepName !== 'BillingStep') {
          this.couponsValidationAfterAction(res)
        }

        if (res.paymentMethods) {
          let selectedMethod = res.paymentMethods.find(method => method.id == billingProps.selectedMethod.id)
          this.setState(prevState => ({
            paymentMethods: res.paymentMethods,
            billingProps: {
              ...prevState.billingProps,
              selectedMethod: selectedMethod || {}
            }
          }))
        }

        if(res.errors.length) {
          this.handleErrors(res.errors)
        } else {
          this.calculateNextStep()
        }
      }
    })
  }

  calculateNextStep() {
    let { steps, currentStep } = this.state

    let nextStep = steps.filter(step => step.stepName === currentStep.nextStep)[0]
    const currentStepIndex = steps.indexOf(currentStep)

    steps[currentStepIndex].status = status[1]

    if( nextStep !== undefined ) {
      this.setState({
        steps: steps,
        currentStep: nextStep
      })
      return;
    }

    const incomplete = steps.filter(step => step.visible === true ).filter(step => step.status == status[0])[0]

    if(incomplete) {
      this.setState({
        steps: steps,
        currentStep: incomplete
      })
    } else if(this.state.billingProps.selectedMethod.type === "StripePayment") {
      this.setState({loading: true, submitStripeCard: true})
    } else {
      this.completeOrder()
    }
  }

  completeOrder(stripePaymentMethodId = null) {
    if(!this.state.loading) {
      this.setState({loading: true})
    }
    const { user, emailProps, deliveryProps, shippingProps, billingProps, cart, isDeliveryAddressUsedAsBilling, contactlessEnabled, installments, showInvoiceForm } = this.state

    const form = createOrderForm(
      user,
      emailProps.email,
      deliveryProps,
      shippingProps,
      billingProps,
      isDeliveryAddressUsedAsBilling,
      cart,
      contactlessEnabled,
      installments,
      showInvoiceForm,
      stripePaymentMethodId
    )
    form.append('order_total', this.state.cart.total)
    form.append('shipping_cost', this.state.cart.shippingCost)

    Rails.ajax({
      url: this.props.completionPath,
      type: 'POST',
      dataType: 'json',
      data: form,
      success: res => {
        if(res.redirect_iframe_url) {
          this.setState({showPaymentIframe: true})
          document.getElementById('payment-iframe').src = res.redirect_iframe_url

        } else if(res.redirect_form) {
          document.body.innerHTML += res.redirect_form
          const form = document.getElementById('payment-redirect-form')
          form.submit()

        } else if(res.redirect_url) {
          window.location.href = res.redirect_url

        } else if((res.requires_action || res.requires_confirmation) && (res.payment_intent_client_secret || res.setup_intent_client_secret)) {
          if (res.payment_intent_client_secret) {
            this.setState({
              stripePaymentIntentClientSecret: res.payment_intent_client_secret,
              stripePaymentIntentActionType: res.requires_action ? 'requires_action' : 'requires_confirmation',
              stripeSetupIntentClientSecret: null,
              stripeSetupIntentActionType: null,
              submitStripeCard: false
            })
          } else {
            this.setState({
              stripePaymentIntentClientSecret: null,
              stripePaymentIntentActionType: null,
              stripeSetupIntentClientSecret: res.setup_intent_client_secret,
              stripeSetupIntentActionType: (res.requires_action ? 'requires_action' : 'requires_confirmation'),
              submitStripeCard: false
            })
          }

        } else if (res.couponsNotice) {
          this.couponsValidationAfterAction(res)

        } else {
          if (res.changedLineItems || res.changedAutomations) {
            this.setState({ lineItemErrors: res.changedLineItems, automationErrors: res.changedAutomations })
          }

          if (res.storeNotFound) {
            this.reviseStep('DeliveryStep')
            this.setState({ deliveryProps: { ...this.state.deliveryProps, storeId: null } })

            this.props.appProps.flashMessage.show(this.props.appProps.translations.flash_messages.store_not_found, 'error')
          }

          if (res.error) {
            this.props.appProps.flashMessage.show(res.error, 'error')
          }

          this.setState({loading: false, submitStripeCard: false})
        }
      },
      error: res => {
        this.props.appProps.flashMessage.show(res.error, 'error')
        this.setState({loading: false, submitStripeCard: false})
      }
    })
  }

  reviseStep(stepName){
    let steps = this.state.steps
    const newCurrentStepStatusIndex = steps.findIndex(step => step.stepName === stepName)
    if( newCurrentStepStatusIndex === -1 ) return;

    for (let i = newCurrentStepStatusIndex; i < this.state.steps.length; i++) {
      steps[i].status = status[0]
    }

    this.setState({
      steps: steps,
      currentStep: steps[newCurrentStepStatusIndex]
    })

  }

  toggleShippingStepVisibility(showShippingBool){
    let { steps } = this.state
    const shippingStepIndex = steps.findIndex(step => step.stepName === "ShippingStep")
    const deliveryStepIndex = steps.findIndex(step => step.stepName === "DeliveryStep")

    steps[deliveryStepIndex].nextStep = showShippingBool ? "ShippingStep" : "BillingStep"
    steps[shippingStepIndex].visible = showShippingBool

    this.setState({steps: steps})
  }

  updateStep(propName, key, value){
    let args = this.state[propName]
    if( Object.keys(args).indexOf(key) === -1 ) return;

    let kv = {}
    args[key] = value
    kv[propName] = args
    this.setState(kv)
  }

  updateDeliveryStep(key, value){
    let { deliveryProps } = this.state
    if( Object.keys(deliveryProps).indexOf(key) === -1 ) return;

    let kv = {}
    deliveryProps[key] = value
    kv["deliveryProps"] = deliveryProps

    this.setState(kv, () => {
      if (key === "deliveryOption" && value === "pickup"){
        this.updateSelectedShipping(this.props.localPickup)
        this.useDeliveryAddressAsBilling(false)
      }
    })
  }

  updateSelectedShipping(selectedShippingMethod, selectedSmartpoint){
    this.setState({cartLoading: true}, () => {
      let shippingTax = 0.0, shippingGross = 0.0
      let paymentTax = 0.0, paymentGross = 0.0

      if(Object.keys(selectedShippingMethod).length > 0){
        shippingTax = selectedShippingMethod.tax_cost
        shippingGross = this.state.shippingProps.multipleShipmentsOptionEnabled ? selectedShippingMethod.multiple_shipments_gross_cost : selectedShippingMethod.gross_cost
      }

      this.setState(prevState => {
        return {
          cartLoading: false,
          shippingProps: {
            selectedMethod: selectedShippingMethod,
            multipleShipmentsOptionEnabled: this.state.shippingProps.multipleShipmentsOptionEnabled,
            selectedSmartpoint: selectedSmartpoint
          },
          contactlessEnabled: this.isContactlessAvailable() ? prevState.contactlessEnabled : false
        }
      })
    })
  }

  updateMultipleShipmentsOption(enabled) {
    this.setState(prevState => {
      return {
        shippingProps: {
          ...prevState.shippingProps,
          multipleShipmentsOptionEnabled: enabled
        }
      }
    })
  }

  updateSelectedPayment(selectedPaymentMethod){
    if(!selectedPaymentMethod) {
      this.setLoading(false)
      return
    }

    const { billingProps, shippingProps} = this.state
    billingProps.selectedMethod = selectedPaymentMethod

    let shippingTax = 0.0, shippingGross = 0.0
    let paymentTax = 0.0, paymentGross = 0.0

    if(Object.keys(shippingProps.selectedMethod).length > 0){
      shippingTax = shippingProps.selectedMethod.tax_cost
      shippingGross = shippingProps.multipleShipmentsOptionEnabled ? shippingProps.selectedMethod.multiple_shipments_gross_cost : shippingProps.selectedMethod.gross_cost

      if(shippingProps.selectedMethod.type !== "LocalPickup" && selectedPaymentMethod.gross_handling_fee){
        paymentTax = selectedPaymentMethod.tax_handling_fee
        paymentGross = selectedPaymentMethod.gross_handling_fee
      }
    }

    this.setLoading(true)
    let fd = new FormData()
    fd.append('handling_fee_net', selectedPaymentMethod.net_handling_fee)
    fd.append('handling_fee_tax', selectedPaymentMethod.tax_handling_fee)
    fd.append('payment_method_id', selectedPaymentMethod.id)
    if (this.props.adminOrderNumber) fd.append('order_id', this.props.order.id)

    Rails.ajax({
      url: '/checkout/update_payment_method_and_handling_fee',
      type: 'POST',
      dataType: 'json',
      data: fd,
      success: res => {
        if (res.errors.length) {
          this.handleErrors(res.errors)
        } else {
          this.setState(prevState => {
            return {
              billingProps: billingProps,
              cart: {
                ...prevState.cart,
                paymentCost: res.handlingFee,
                paymentCostNet: res.handlingFeeNet,
                tax: res.vatTotal,
                shippingCost: res.shippingCost,
                shippingCostNet: res.shippingCostNet,
                total: res.total
              },
              contactlessEnabled: this.isContactlessAvailable() ? prevState.contactlessEnabled : false,
              totalWithAdjustments: res.newTotal,
              installments: 1
            }
          })
        }
        this.setLoading(false)
      },
      error: res => {
        this.setLoading(false)
      }
    })
  }

  updateContactless(toggleValue){
    this.setState({contactlessEnabled: toggleValue})
  }

  updateShowInvoiceForm(toggleValue) {
    this.setState({showInvoiceForm: toggleValue})
  }

  useDeliveryAddressAsBilling(checkboxValue){
    this.setState({ isDeliveryAddressUsedAsBilling: checkboxValue })
  }

  updateInstallments(value) {
    this.setState({installments: value})
  }

  setLoading(value) {
    this.setState({ loading: value })
  }

  removeFromCart(line_item_id){
    this.setState({ cartLoading: true, loading: true })
    Rails.ajax({
      url: `/line_items/${line_item_id}/delete`,
      type: 'POST',
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      success: res => {
        location.reload()
      }
    })
  }

  updateLineItemQuantity(line_item_id, quantity){
    this.setState({ cartLoading: true, loading: true })
    let data = new FormData()
    data.append("quantity", quantity)

    Rails.ajax({
      url: `/line_items/${line_item_id}/update_quantity`,
      type: 'PATCH',
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      data: data,
      success: res => {
        location.reload()
      },
      error: res => {
        this.props.appProps.flashMessage.show(res.error, 'error')
        setTimeout( () => {
          location.reload()
        }, 2000)
      }
    })
  }

  setCartLoader(status){
    if(typeof status === 'boolean') this.setState({cartLoading: status})
  }

  setStripeError() {
    this.setState({
      loading: false,
      submitStripeCard: false,
      stripePaymentIntentClientSecret: null,
      stripePaymentIntentActionType: null,
      stripeSetupIntentClientSecret: null,
      stripeSetupIntentActionType: null
    })
  }

  setLoading(newState) {
    this.setState({ loading: newState })
  }

  setCouponsAdjustments(coupons) {
    this.setState({ couponAdjustments: coupons })
  }

  setTotalWithAdjustments(totalWithAdjustments){
    this.setState({ totalWithAdjustments: totalWithAdjustments })
  }

  couponsValidationAfterAction(result) {
    result.messages.forEach( message => {
      if (message.type === 'failure') {
        this.props.appProps.flashMessage.show(`${ message.value } ${ result.deletedCoupons.join(', ') }`, 'error')
      } else if (message.type === 'notice') {
        this.props.appProps.flashMessage.show(`${ message.value } ${ result.updatedCoupons.join(', ') }`, 'notice')
      }
    })

    this.setState({
      couponAdjustments: result.couponAdjustments,
      totalWithAdjustments: result.newTotal,
    })

    if (this.state.currentStep.nextStep !== 'BillingStep') {
      this.setState({ loading: false })
    }
  }

  handleErrors(errors) {
    this.setLoading(true)

    let errorParams = []
    let shippingMethodNotFoundError = errors.filter(err => err.key === 'shipping_method_not_found')[0]
    let paymentMethodNotFoundError = errors.filter(err => err.key === 'payment_method_not_found')[0]
    let shippingCostHasChangedError = errors.filter(err => err.key === 'shipping_cost_has_changed')[0]
    let paymentMethodCostHasChangedError = errors.filter(err => err.key === 'payment_method_cost_has_changed')[0]

    if (shippingMethodNotFoundError) errorParams.push('shipping_error=shipping_method_not_found')
    if (paymentMethodNotFoundError) errorParams.push('payment_error=payment_method_not_found')
    if (shippingCostHasChangedError) errorParams.push('shipping_error=shipping_cost_has_changed')
    if (paymentMethodCostHasChangedError) errorParams.push('payment_error=payment_method_cost_has_changed')

    if (errorParams.length) {
      let path = this.props.adminOrderNumber ? `/admin/${ ReactiveCore.locale }/orders/${ this.props.adminOrderNumber }/customer_info` : '/checkout'
      window.location.href = `${ path }?${ errorParams.join('&') }`
    } else {
      errors.map(err => this.props.appProps.flashMessage.show(err, 'error'))
    }
  }

  handleLineItemErrors() {
    const shippingStep = this.state.steps.find(step => step.stepName == 'ShippingStep')
    if (shippingStep && shippingStep.visible && shippingStep.status == status[1]) {
      this.reviseStep('ShippingStep')
    }

    window.location.reload()
  }

  resendConfirmation() {
    Rails.ajax({
      type: 'POST',
      url: this.props.resendConfirmationPath,
      dataType: 'json',
      success: result => {
        if (result.success) {
          this.props.appProps.flashMessage.show(this.props.appProps.translations.account.email_confirmation_sent)
        }
      }
    })
  }

  closePendingOrderModal() {
    this.setState({ showPendingOrderModal: false })
  }

  render() {
    const { appProps, disableCheckout, legalTerms, userNeedsEmailConfirmation } = this.props
    let steps = this.state.steps.filter(step => step.visible === true ).map((step, index) => {
      if( this.state.currentStep && this.state.currentStep.stepName === step.stepName ){
        return this.renderStep(step.stepName, index, appProps.googleAnalyticsId, appProps.googleMeasurementId)
      }
      else if( step.status == status[0]){
        return(
          <IncompleteStep
            key={ index }
            stepTitle={ step.stepTitle }
          />
        )
      }
      else{
        return(
          <CompletedStep
            key={ index }
            stepName={ step.stepName }
            stepTitle={ step.stepTitle }
            reviseStep={ this.reviseStep.bind(this) }
            changeText={ step.changeText }
            selectedValues={ this.stepDetails(step.stepName) }
          />
        )
      }
    })

    return (
      <div className='main-container'>
        <div className='checkouts-wrapper'>
          { !disableCheckout && (!userNeedsEmailConfirmation || (userNeedsEmailConfirmation && this.state.userConfirmed)) &&
            <>
              <div className="cart-container">
                <Cart
                  appProps                = { appProps                                          }
                  products                = { this.state.cart.products                          }
                  shippingCost            = { this.state.cart.shippingCost                      }
                  shippingCostNet         = { this.state.cart.shippingCostNet                   }
                  paymentCost             = { this.state.cart.paymentCost                       }
                  paymentCostNet          = { this.state.cart.paymentCostNet                    }
                  subTotal                = { this.state.cart.subTotal                          }
                  tax                     = { this.state.cart.tax                               }
                  total                   = { this.state.cart.total                             }
                  weight                  = { this.state.cart.weight                            }
                  removeFromCart          = { this.removeFromCart.bind(this)                    }
                  updateLineItemQuantity  = { this.updateLineItemQuantity.bind(this)            }
                  cartEditable            = { this.state.currentStep.stepName === "EmailStep" || this.state.isCartEditable }
                  loading                 = { this.state.cartLoading                            }
                  currency                = { this.props.order.currency                         }
                >
                  { this.state.currentStep.stepName != 'EmailStep' &&
                    <Coupons
                      appProps={ appProps }
                      couponAdjustments={ this.state.couponAdjustments }
                      setCouponsAdjustments={ this.setCouponsAdjustments.bind(this) }
                      orderTotal={ this.state.totalWithAdjustments }
                      setTotalWithAdjustments={ this.setTotalWithAdjustments.bind(this) }
                      translations={ appProps.translations }
                      setLoading={ this.setLoading.bind(this) }
                      orderId={ this.props.order.id }
                    />
                  }
                </Cart>
              </div>

              <div className="checkout-container">
                <div className="checkout-headers">
                  {!this.props.hideHeadingTitle && <h3>{ appProps.translations.checkout.order_details }</h3>}
                </div>

                <div className="checkout-steps">
                  { steps }
                </div>

                { (this.state.lineItemErrors.length > 0 || this.state.automationErrors.length > 0) &&
                  <LineItemErrorsModal
                    translations={ appProps.translations }
                    lineItemErrors={ this.state.lineItemErrors }
                    automationErrors={ this.state.automationErrors }
                    handleLineItemErrors={ this.handleLineItemErrors.bind(this) }
                  />
                }

                { this.state.showPendingOrderModal &&
                  <PendingOrderModal
                    translations={ appProps.translations }
                    newOrderEnc={ this.props.newOrderEnc }
                    closePendingOrderModal={ this.closePendingOrderModal.bind(this) }
                  />
                }

                { this.state.loading &&
                  <Loader
                    absolute
                    size={ 'large' }
                    position={ 'center' }
                    fullPage
                  />
                }
                { this.state.showPaymentIframe &&
                  <div className="payment-iframe-overlay">
                    <iframe id="payment-iframe" src="" width="500" height="500"> </iframe>
                  </div>
                }
              </div>

              { this.state.loading &&
                <Loader
                  absolute
                  size={ 'large' }
                  position={ 'center' }
                  fullPage
                />
              }
            </>
          }

          { disableCheckout &&
            <div
              className='not-found-wrapper'
              style={{ margin: '0 auto' }}
            >
              <h1 style={{ textAlign: 'center' }}>
                { appProps.translations.general.checkout_closed }
              </h1>
            </div>
          }

          { !disableCheckout && userNeedsEmailConfirmation && appProps.user && !this.state.userConfirmed && !this.state.casLoading &&
            <div
              className='not-found-wrapper'
              style={{ margin: '0 auto' }}
            >
              <h1 style={{ textAlign: 'center' }}>
                { appProps.translations.account.email_not_confirmed }
              </h1>

              <button
                className='button'
                style={{ marginTop: 30, fontSize: 20 }}
                onClick={ () => this.resendConfirmation() }
              >
                { appProps.translations.account.resend_email_confirmation }
              </button>
            </div>
          }

          { !disableCheckout && userNeedsEmailConfirmation && this.state.casLoading &&
            <div
              className='not-found-wrapper'
              style={{ margin: '0 auto' }}
            >
              <Loader
                absolute
                size={ 'large' }
                position={ 'center' }
              />
            </div>
          }

          { !disableCheckout && userNeedsEmailConfirmation && !appProps.user && !this.state.casLoading &&
            <div
              className='not-found-wrapper'
              style={{ margin: '0 auto' }}
            >
              <h1 style={{ textAlign: 'center' }}>
                { appProps.translations.checkout.login_warning }
              </h1>

              { appProps.userFormLoginType &&
                <div
                  className='flex-box items-center content-center'
                  style={{ marginTop: 30 }}
                >
                  <AuthorizableLink
                    appProps={ appProps }
                    linkRef={ appProps.redirectAfterAuthPath }
                    authorizableScreen='register'
                    linkContent={(
                      <button
                        type='button'
                        className='button inverted'
                        style={{ fontSize: 20 }}
                      >
                        { appProps.translations.account.register_button }
                      </button>
                    )}
                  />

                  <AuthorizableLink
                    appProps={ appProps }
                    linkRef={ appProps.redirectAfterAuthPath }
                    authorizableScreen='login'
                    linkContent={(
                      <button
                        type='button'
                        className='button'
                        style={{ marginLeft: 20, fontSize: 20 }}
                      >
                        { appProps.translations.account.login_button }
                      </button>
                    )}
                  />
                </div>
              }

              { !appProps.userFormLoginType &&
                <a
                  href={ appProps.accountPath }
                  className='button'
                  style={{ marginTop: 30, fontSize: 20 }}
                >
                  { appProps.translations.account.login_button }
                </a>
              }
            </div>
          }
        </div>
      </div>
    )
  }
}
