import React, { useState, useEffect } from 'react'
import ProductItem from '../../products/ProductItem'
import ProductListSlider from './product_list/ProductListSlider'

export function ProductList({ appProps, item }) {
  const [loading, setLoading] = useState([])
  const [products, setProducts] = useState([])
  const settings = item.contentSettings

  useEffect( () => {
    if (settings.selectedProductItems !== 'custom' || (settings.selectedProductItems === 'custom' && settings.selectedProducts.length)) {
      setLoading(true)

      $.ajax({
        type: 'GET',
        url: appProps.selectedProductsPath,
        data: {
          items: JSON.stringify(settings.selectedProducts),
          selected_product_items: settings.selectedProductItems,
          categories: JSON.stringify(settings.selectedCategories),
          product_limit: settings.productLimit,
          filtering: settings.filtering
        },
        dataType: 'json',
        success: res => {
          setProducts(res.items)
          setLoading(false)
        },
        error: res => {
          setLoading(false)
        },
      })
    }
  }, [])

  function getProductsPerRowClass() {
    switch (settings.productsPerRow) {
      case '1':
      case 1:
        return 'one_item'

      case '2':
      case 2:
        return 'two_items'

      case '3':
      case 3:
        return 'three_items'

      case '4':
      case 4:
        return 'four_items'

      case '5':
      case 5:
        return 'five_items'

      case '6':
      case 6:
        return 'six_items'
    }
  }

  return (
    <>
      { settings.viewType !== 'slider' &&
        <div className={`items-list ${settings.viewType}`}>
          { products.map( (product, index) => (
            <ProductItem
              key={ index }
              appProps={ appProps }
              product={ product }
              quickViewActions={ appProps.productQuickActions }
              productsPerRowClass={ getProductsPerRowClass() }
            />
          ))}
        </div>
      }

      { settings.viewType === 'slider' &&
        <ProductListSlider
          appProps={ appProps }
          settings={ settings }
          products={ products }
          productsPerRowClass={ getProductsPerRowClass() }
        />
      }
    </>
  )
}
