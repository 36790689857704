import { Product } from './elements/Product'
import { ProductList } from './elements/ProductList'

export function additionalElements(props) {
  return {
    product: {
      component: Product,
      props: props
    },
    product_list: {
      component: ProductList,
      props: props
    }
  }
}
