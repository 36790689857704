import React, { useState, useEffect } from 'react'
import { InputField, Loader } from '@reactiveonline/frontend_shared_components'
import CountrySelector from '../helpers/CountrySelector'

export default function Billing({
  appProps, company, companyState, setCompanyState, setCompanyAddress,
  countriesPath, saveSettings, loading, setLoading, requirePhone, requireMobilePhone
}) {
  const [lockVatField, setLockVatField]       = useState(false)
  const [vatValidity, setVatValidity]         = useState('')
  const [selectedAddress, setSelectedAddress] = useState(companyState.billingAddress || null)
  const [selectedCountryData, setSelectedCountryData] = useState(null)

  let canSave = true
  if(company) {
    canSave = (company.billingAddress.phone != companyState.billingAddress.phone) ||
              (company.billingAddress.mobilePhone != companyState.billingAddress.mobilePhone) ||
              (company.billingAddress.address != companyState.billingAddress.address) ||
              (company.billingAddress.city != companyState.billingAddress.city) ||
              (company.billingAddress.postalCode != companyState.billingAddress.postalCode) ||
              (company.billingAddress.country != companyState.billingAddress.country) ||
              (company.billingAddress.state != companyState.billingAddress.state) ||
              (company.vat != vatNumberValue(companyState.vat))
  }

  const canEditFields = !company || (company && appProps.user.isCompanyMaster && !appProps.user.companyActive)

  function stepValidations() {
    if( (requirePhone && (!companyState.billingAddress.phone || companyState.billingAddress.phone.trim() === '')) ||
        (requireMobilePhone && (!companyState.billingAddress.mobilePhone || companyState.billingAddress.mobilePhone.trim() === '')) ||
        (!vatNumberValue(companyState.vat) || vatNumberValue(companyState.vat).trim() === '')) {
      appProps.flashMessage.show(appProps.translations.flash_messages.fill_all_required_fields, 'error')
    } else {
      saveSettings()
    }
  }

  function vatNumberValue(value) {
    if(!selectedCountryData || !value) {
      return value
    }

    let eligibleCountryCode = selectedCountryData.alpha2_code === 'GR' ? 'EL' : selectedCountryData.alpha2_code

    if(value.startsWith(eligibleCountryCode)) {
      return value.substring(eligibleCountryCode.length, value.length)
    } else {
      return value
    }
  }

  function vatValidation(){
    setLoading(true)
    setLockVatField(true)

    let data = new FormData()
    data.append("vat_number", vatNumberValue(companyState.vat))
    data.append("country_code", (selectedCountryData && selectedCountryData.numeric_code) || selectedAddress.country)

    Rails.ajax({
      url: `/checkout/vat_validation`,
      type: 'PATCH',
      dataType: 'json',
      data: data,
      success: res => {
        setLoading(false)
        setVatValidity('valid')
        if(res.companyName) {
          setCompanyState({ name: res.companyName })
        }
      },
      error: res => {
        setLoading(false)
        setVatValidity('invalid')
      }
    })
  }

  return (
    <>
      <div className="heading" style={{ marginBottom: 20 }}>
        <h2>{ appProps.translations.company.billing_address }</h2>
        <div>{ appProps.user.isCompanyMaster && appProps.translations.company.billing_tab_subtitle }</div>
      </div>

      <div className="flex-box flex-wrap billing-address-container">
        <InputField
          classes='field'
          label={ `${ appProps.translations.checkout.phone } ${requirePhone ? '*' : ''}` }
        >
          <input
            type='text'
            value={ companyState.billingAddress.phone || '' }
            disabled={ !canEditFields }
            onChange={ event => setCompanyAddress({ phone: event.target.value }) }
          />
        </InputField>

        <InputField
          classes='field'
          label={ `${ appProps.translations.checkout.mobile_phone } ${requireMobilePhone ? '*' : ''}` }
        >
          <input
            type='text'
            value={ companyState.billingAddress.mobilePhone || '' }
            disabled={ !canEditFields }
            onChange={ event => setCompanyAddress({ mobilePhone: event.target.value }) }
          />
        </InputField>

        <InputField
          classes='field'
          label={ `${ appProps.translations.checkout.address } *` }
        >
          <input
            type='text'
            value={ companyState.billingAddress.address || '' }
            disabled={ !canEditFields }
            onChange={ event => setCompanyAddress({ address: event.target.value }) }
          />
        </InputField>

        <InputField
          classes='field'
          label={ `${ appProps.translations.checkout.city } *` }
        >
          <input
            type='text'
            value={ companyState.billingAddress.city || '' }
            disabled={ !canEditFields }
            onChange={ event => setCompanyAddress({ city: event.target.value }) }
          />
        </InputField>

        <InputField
          classes='field'
          label={ `${ appProps.translations.checkout.postal_code } *` }
        >
          <input
            type='text'
            value={ companyState.billingAddress.postalCode || '' }
            disabled={ !canEditFields }
            onChange={ event => setCompanyAddress({ postalCode: event.target.value }) }
          />
        </InputField>

        <CountrySelector
          styles='flex-1'
          setSettings={ setCompanyAddress }
          countriesPath={ countriesPath }
          selectedCountry={ companyState.billingAddress.country }
          selectedState={ companyState.billingAddress.state }
          countryLabel={ `${ appProps.translations.checkout.country} *` }
          stateLabel={ appProps.translations.checkout.state }
          disabled={ !canEditFields }
          setSelectedCountryData={ setSelectedCountryData }
        />
      </div>

      <div className="field" style={vatValidity === 'invalid' ? {marginTop: 0} : {}}>
        <div className='flex-box content-space-between' style={{ position: 'relative', paddingLeft: 5, paddingRight: 5 }}>
          <label style={{ display: 'inline-block' }}>
            { `${ appProps.translations.company.vat } *` }
            { vatValidity === 'invalid' && <span style={{ fontSize: 12, color: '#575757' }}>&nbsp;{appProps.translations.invoices.invalidVat}</span> }
          </label>
          { loading &&
            <Loader
              absolute
              size={ 'small' }
              position={ 'right' }
            />
          }
        </div>
        <div className='input-field' style={{ position: 'relative', paddingLeft: 5, paddingRight: 5 }}>
          <input
            id="vat-number"
            type="text"
            disabled={ lockVatField || !canEditFields }
            style={ selectedCountryData && selectedCountryData.region === 'EU' ? {position: 'relative', paddingLeft: 23} : {} }
            className={ lockVatField ? 'disabled' : '' }
            value={ vatNumberValue(companyState.vat) || '' }
            onChange={ event => setCompanyState({ vat: event.target.value }) }>
          </input>
          { canEditFields && selectedCountryData && selectedCountryData.region === 'EU' &&
            <>
              <div style={{ fontSize: 12, position: 'absolute', left: 10, top: '50%', color: '#575757', textTransform: 'uppercase', marginTop: '-9px' }}>{ selectedCountryData.alpha2_code === 'GR' ? 'EL' : selectedCountryData.alpha2_code }</div>
              { vatValidity === '' && vatNumberValue(companyState.vat) && vatNumberValue(companyState.vat).length >= 9 &&
                <div onClick={ ()=> vatValidation() } className='pointer' style={{ fontSize: 12, position: 'absolute', right: 20, top: '50%', marginTop: '-9px' }}>{ appProps.translations.invoices.validate }</div>
              }
              <div
                className={`flex-box items-center ${vatValidity === 'invalid' ? 'close_icon invalid' : '' } ${vatValidity === 'valid' ? 'check_icon valid' : '' } `}
                style={{ position: 'absolute', top: '50%', right: 20, fontSize: 13, marginTop: '-9px' }}
              >
                { (vatValidity === 'invalid' || vatValidity === 'valid') &&
                  <>
                    <div className='divider'/>
                    <b
                      className='pointer'
                      onClick={ ()=> {
                         setCompanyState({ vat: '' })
                         setLockVatField(false)
                         setVatValidity('')
                      }}
                    >
                      { appProps.translations.company.clear }
                    </b>
                  </>
                }
              </div>
            </>
          }
        </div>
      </div>

      { canEditFields && company &&
          <div className="button-wrapper" style={{ marginTop: 30 }}>
          { canSave ?
            <a className='button' onClick={ () => stepValidations() }>
              { appProps.translations.general.save }
            </a>
            :
            <div className='button disabled'>
              { appProps.translations.general.save }
            </div>
          }
        </div>
      }
    </>
  )
}
