import React from "react"

export default class RequiredField extends React.Component {
  render () {
  	const { message } = this.props

    return (
      <span style={{color: "red"}}>{ message }</span>
    )
  }
}
