import React, { useState } from "react"
import ProductPresentableTitle from "../shared/ProductPresentableTitle"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { Loader, StripeCheckoutForm } from "@reactiveonline/frontend_shared_components"

export default function ShowCredit({
  appProps, order, credit, paymentMethodTitle, validToken, payCreditPath, paymentFailed, stripePublishableKey, type, cardsPath, setCurrentCardPath
}){
  const [showStripeForm, setShowStripeForm] = useState(false)
  const [submitStripeCard, setSubmitStripeCard] = useState(false)
  const [stripePaymentIntentClientSecret, setStripePaymentIntentClientSecret] = useState(null)
  const [stripePaymentIntentActionType, setStripePaymentIntentActionType] = useState(null)
  const [stripeSetupIntentClientSecret, setStripeSetupIntentClientSecret] = useState(null)
  const [stripeSetupIntentActionType, setStripeSetupIntentActionType] = useState(null)
  const [loading, setLoading] = useState(false)

  const stripePromise = stripePublishableKey && loadStripe(stripePublishableKey)

  function setStripeError() {
    setSubmitStripeCard(false)
    setStripePaymentIntentClientSecret(null)
    setStripePaymentIntentActionType(null)
    setStripeSetupIntentClientSecret(null)
    setStripeSetupIntentActionType(null)
  }

  function paymentHandler(paymentMethodId = null) {
    let url = payCreditPath
    if (credit.payment_method.type === "StripePayment"){
      if (!showStripeForm){
        setShowStripeForm(true)
        return
      } else if(!submitStripeCard){
        setSubmitStripeCard(true)
        return
      } else {
        url += `?stripe_payment_method_id=${paymentMethodId}`
      }
    }

    setLoading(true)

    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: res => {
        if (res.redirect_form) {
          document.body.innerHTML += res.redirect_form
          const form = document.getElementById('payment-redirect-form')
          form.submit()
        } else if (res.redirect_url) {
          window.location.href = res.redirect_url
        } else if ((res.requires_action || res.requires_confirmation) && (res.payment_intent_client_secret || res.setup_intent_client_secret)) {
          if (res.payment_intent_client_secret) {
            setSubmitStripeCard(false)
            setStripePaymentIntentActionType(res.requires_action ? 'requires_action' : 'requires_confirmation')
            setStripePaymentIntentClientSecret(res.payment_intent_client_secret)
            setStripeSetupIntentClientSecret(null)
            setStripeSetupIntentActionType(null)
          } else {
            setSubmitStripeCard(false)
            setStripePaymentIntentClientSecret(null)
            setStripePaymentIntentActionType(null)
            setStripeSetupIntentActionType(res.requires_action ? 'requires_action' : 'requires_confirmation')
            setStripeSetupIntentClientSecret(res.setup_intent_client_secret)
          }
        }

        setLoading(false)
      },
      error: _res => {
        setSubmitStripeCard(false)
        setLoading(false)
      }
    })
  }

  let titleMessage, subtitleMessage
  if (credit?.status === "open"){
    if (paymentFailed){
      titleMessage = appProps.translations.credits.credit_payment_failed
      subtitleMessage = appProps.translations.credits.credit_payment_failed_message
    } else {
      titleMessage = appProps.translations.credits.new_credit_occured
      subtitleMessage = `${appProps.translations.credits.new_credit_amount} ${credit.display_amount}`
    }
  } else {
    titleMessage = appProps.translations.credits.credit_arranged
    subtitleMessage = appProps.translations.credits.credit_paid
  }

  return (
    <div className="main-container">
      { validToken ?
        <>
          <div className="flex-box flex-column items-center" style={{marginTop: 10, marginBottom: 10}}>
            <h1>{ titleMessage }</h1>
            <h4>{ subtitleMessage }</h4>
          </div>

          <div className="flex-box flex-column content-end" style={{ width: '100%' }}>
            <div style={{ marginBottom: 20 }}>
              <b>{ appProps.translations.credits.credit_informations }</b>
            </div>
            <div className="flex-box content-space-between">
              <div>{ appProps.translations.orders.payment_method }:</div>
              <div>{ paymentMethodTitle }</div>
            </div>
            <div className="flex-box content-space-between">
              <div>{ appProps.translations.credits.amount }:</div>
              <div>{ credit.display_amount }</div>
            </div>
          </div>

          <div style={{ width: '100%', margin: '20px 0px' }}>
            <div style={{ marginBottom: 20 }}>
              <b>{ appProps.translations.orders.order_contents }</b>
            </div>

            { order.line_items.map((line_item, index) => {
                return (
                  <div key={index}>
                    <div className='flex-box' style={{ padding: '20px 0px' }}>
                      <div className="item-contents product-image flex-1">
                        <img style={{ height: 60 }} src={ line_item.sellable.images.length > 0 ? line_item.sellable.images[0].thumbnail : "https://storage.googleapis.com/reactive_files_eu/no-image.jpg" } />
                      </div>
                      <div className="flex-1 flex-box flex-column">
                        <div>
                          <ProductPresentableTitle appProps={appProps} entity={line_item.sellable} entityIndex={index} />
                        </div>
                        { line_item.sellableType == "Shop::Bundle" &&
                          <div>
                            { line_item.sellable.bundleItems.map((bundle_item, index) => {
                              return (
                                <div key={ index }>
                                  <div className="flex-1">
                                    {`${bundle_item.quantity} x `}
                                    <ProductPresentableTitle appProps={appProps} entity={bundle_item} entityIndex={index} />
                                  </div>
                                </div>
                              )})
                            }
                          </div>
                        }
                      </div>
                      <div className="flex-1">
                        <ul>
                          { line_item.options.map( (option, index) => {
                            return (
                              <li key={index}>
                                { option.optionTypeName || '-' }:
                                <b>{ option.name }</b>
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                      <div className="flex-1">
                        x { line_item.quantity }
                      </div>
                      <div className="flex-1">
                        { line_item.displayUnitGross }
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>

          <div className="flex-box flex-column content-end" style={{ width: '100%', marginBottom: 10 }}>
            <div style={{ marginBottom: 20 }}>
              <b>{ appProps.translations.orders.order_total }</b>
            </div>
            <div className="flex-box content-space-between">
              <div>{ appProps.translations.cart.subtotal }:</div>
              <div>{ order.subtotal }</div>
            </div>
            <div className="flex-box content-space-between">
              <div>{ appProps.translations.cart.vat }:</div>
              <div>{ order.vat_total }</div>
            </div>
            { order.shipping_cost &&
              <div className="flex-box content-space-between">
                <div>{ appProps.translations.cart.delivery_cost }:</div>
                <div>{ order.shipping_cost }</div>
              </div>
            }
            { order.handling_fee &&
              <div className="flex-box content-space-between">
                <div>{ appProps.translations.cart.handling_fee }:</div>
                <div>{ order.handling_fee }</div>
              </div>
            }

            { order.couponAdjustments.length > 0 ?
              <>
                <div className="flex-box items-center content-space-between">
                  <div className='flex-box'>
                    <div>{ appProps.translations.checkout.coupons_title }</div>
                    { order.couponAdjustments.map( couponAdjustment => (
                      <div key={ couponAdjustment.orderAdjustmentId } style={{ marginLeft: 5 }}>
                        ({ couponAdjustment.code })
                      </div>
                    ))}
                  </div>
                  <div>
                    { order.couponAdjustments.map( couponAdjustment => (
                      <div key={ couponAdjustment.orderAdjustmentId }>
                        -{ couponAdjustment.discount }
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex-box items-center content-space-between" style={{ marginTop: 10 }}>
                  <div><b>{ appProps.translations.cart.total }:</b></div>
                  <div><b>{ order.totalWithAdjustments }</b></div>
                </div>
              </>
            :
              <div className="flex-box items-center content-space-between" style={{ marginTop: 10 }}>
                <div><b>{ appProps.translations.cart.total }:</b></div>
                <div><b>{ order.total }</b></div>
              </div>
            }
          </div>

          { credit.payment_method.type === "StripePayment" && showStripeForm &&
            <Elements stripe={stripePromise}>
              <StripeCheckoutForm
                appProps={ appProps }
                submitStripeCard={ submitStripeCard }
                setStripeError={ setStripeError }
                completeOrder={ paymentHandler }
                stripePaymentIntentClientSecret={ stripePaymentIntentClientSecret }
                stripePaymentIntentActionType={ stripePaymentIntentActionType }
                orderId={ order.id }
                type={ type }
                creditToken={ credit.payment_token }
                stripeSetupIntentClientSecret={stripeSetupIntentClientSecret}
                stripeSetupIntentActionType={stripeSetupIntentActionType}
                paymentMethodId={ credit.payment_method.id }
                cardsPath = { cardsPath }
                setIsLoading = { setLoading }
                setCurrentCardPath = { setCurrentCardPath }
                billingDetails={ {
                  address: {
                    city: order.billing_address.city,
                    country: order.billing_address.countryAlpha2Code,
                    line1: order.billing_address.address,
                    postal_code: order.billing_address.postalCode,
                    state: order.billing_address.stateLabel
                  },
                  email: order.email,
                  name: order.billing_address.fullName,
                  phone: order.billing_address.mobile || order.billing_address.phone
                } }
              />
            </Elements>
          }

          { credit.status === "open" &&
            <div className="flex-box content-center" style={{marginBottom: 10}}>
              <button className="button" onClick={ paymentHandler }>
                  { appProps.translations.credits.pay }
                </button>
            </div>
          }
        </>
      :
        <>
          <div className="flex-box flex-column items-center" style={{marginTop: 10, marginBottom: 10}}>
            <h1>{ appProps.translations.credits.invalid_token }</h1>
            <h4>{ appProps.translations.credits.credit_cannot_be_paid }</h4>
          </div>

          { credit.status === "open" &&
            <div className="flex-box content-center" style={{marginBottom: 10}}>
              <button className="button" onClick={ () => { window.location.href = appProps.routePath } }>
                { appProps.translations.credits.home }
              </button>
            </div>
          }
        </>
      }

      { loading && 
        <Loader
          absolute
          size={ 'large' }
          position={ 'center' }
          fullPage
        />
      }
    </div>
  )
}
