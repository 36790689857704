import ReactPixel from "react-facebook-pixel"
import ReactGA from "react-ga4"
import cookie from "react-cookies"
import { getTranslatableField } from "./utils"

const marketingCookies  = cookie.load("cookieSettings") && cookie.load("cookieSettings").marketingCookies === true
const statisticsCookies = cookie.load("cookieSettings") && cookie.load("cookieSettings").statisticsCookies === true

export function loadProductListAnalytics(products, appProps) {
  if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
    ReactPixel.trackCustom('ViewContent', {
      name: [ `${ products.map((product) => `${product.title}`)}`],
    })
  }
}

export function loadProductViewAnalytics(product, appProps) {
  if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
    ReactPixel.trackCustom('ViewContent', {
      name:     getTranslatableField(product, 'title', appProps.currentLocale, false),
      value:    product.totalPrice,
      currency: appProps.currency.symbol
    })
  }
}

export function initiateCheckoutAnalytics(order, event, appProps) {
  if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
    ReactPixel.trackCustom('InitiateCheckout', {
      content_name: 'Initiated Checkout',
      value:    `${order.totalValue}`,
      items:    `${order.lineItems.length}`,
      products: `${order && order.lineItems.map((p) => {
          return `${p.sellable.title}, price: ${p.totalGross}, category: ${p.sellable.prototypeName ? p.sellable.prototypeName : ''}`
        })
      }`,
      orderNo:  `${order.number}`,
      currency: `${appProps.currency}`,
    });
  }
  if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
    ReactGA.event({
      category: 'Click Checkout',
      action: 'Initiated Checkout',
      label: event.target.href, // optional
      value: `${order.totalValue}`, // optional, must be a number
      transport: "xhr", // optional, beacon/xhr/image
    });
  }
}

export function addToCartAnalytics(product, appProps){
  if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
    ReactPixel.trackCustom('AddToCart', {
      content_name: `${ product.title }`,
      content_ids: product.id,
      content_type: "product",
      content_category: `${ product.prototypeName ? product.prototypeName : '' }`,
      value: `${product.totalPrice }`,
      currency: `${appProps.currency}`
    })
  }

  if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
    ReactGA.event({
      category: `${ product.prototypeName ? product.prototypeName : 'No Product Category' }`,
      action: 'Add to cart',
      label: `${ product.title }`, // optional
      value: `${ product.totalPrice }`,
      transport: "xhr", // optional, beacon/xhr/image
    });
  }
}

export function checkoutEmailAnalytics(){
  if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
    ReactGA.event({
      category: 'Checkout',
      action: 'Customer email added',
      label: 'Customer (hidden)', // optional
      transport: "xhr", // optional, beacon/xhr/image
    });
  }
}

export function checkoutDeliveryMethodAnalytics(deliveryOption){
  if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
    ReactGA.event({
      category: 'Checkout',
      action: 'Delivery method selected',
      label: `${deliveryOption}`, // optional
      transport: "xhr", // optional, beacon/xhr/image
    });
  }
}

export function checkoutShippingMethodAnalytics(selectedMethod){
  if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
    ReactGA.event({
      category: 'Checkout',
      action: 'Shipping method selected',
      label: `${selectedMethod.type}`, // optional
      transport: "xhr", // optional, beacon/xhr/image
    });
  }
}

export function checkoutPaymentMethodAnalytics(selectedMethod){
  if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
    ReactGA.event({
      category: 'Checkout',
      action: 'Payment method selected',
      label: `${selectedMethod.title}`, // optional
      transport: "xhr", // optional, beacon/xhr/image
    });
  }
}
