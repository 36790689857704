import React from "react"

import { getTranslatableField, constructTranslatableProductLink } from "../../helpers/utils"

export default function ProductPresentableTitle({ entity, entityIndex, appProps }) {

  let productTitle = getTranslatableField(entity, 'title', appProps.currentLocale, false)
  let linkableTitle =
    <a style={{ color: appProps.frontendStyles.general.bodyText }} href={ constructTranslatableProductLink(entity, appProps.currentLocale, appProps.defaultLocale, appProps.localeUrlPresent) } rel='nofollow noindex'>
      { `${productTitle} ${entity.sku ? '(' + entity.sku + ')' : ''}` }
    </a>
  let fallbackTitle = appProps.translations.cart.product + (entity.sku ? ' (' + entity.sku + ')' : ` ${entityIndex+1}`)

  return productTitle !== '' ? linkableTitle : fallbackTitle
}
