import React from "react"
import { initiateCheckoutAnalytics } from './../../helpers/analytics'
import { initiateLegacyCheckoutAnalytics } from './../../helpers/legacy_analytics'

export default function TopBannerFullWidth({
  appProps, order, currency, infoMessage, linkTitle, linkTo, InfoComponent, infoComponentProps
}) {
  return (
    <div className="top-banner-full-width animated fadeIn inner-box-shadow">
      <div className="main-container">
        <div className="top-banner-content flex-box items-center">
          { infoMessage &&
            <div className="message">
              { infoMessage }
              { InfoComponent && <InfoComponent { ...infoComponentProps } /> }
            </div>
          }
          { linkTo &&
            <div className="flex-box button-wrapper content-end">
              <a
                className="button"
                href={ linkTo }
                onClick={ (event) => {
                  appProps.googleMeasurementId ? initiateCheckoutAnalytics(order, event, appProps) : initiateLegacyCheckoutAnalytics(order, event, appProps)
                }}
              >
                { linkTitle }
              </a>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
