import React from 'react'
import InformationIcon from './InformationIcon'

export default function DisableableField({ disabled, informationText, children }) {
  return (
    <div className={ disabled ? 'disabled-field-wrapper' : '' }>
      { children }
      { disabled &&
        <div className='disabled-field'>
          { informationText &&
            <InformationIcon text={ informationText }/>
          }
        </div>
      }
    </div>
  )
}
