import React, { useState, useEffect, useCallback } from "react"
import { Content } from "@reactiveonline/frontend_shared_components/builder"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import ProductDescription from "./ProductDescription"
import Attributes from "./Attributes"
import StoreLocations from "./StoreLocations"
import BurgerOpener from "./BurgerOpener"

import { getTranslatableField } from "../../helpers/utils"

export default function ProductShowBottom({
  appProps, product, category, productPageBottomLayout, hasBuilderContent,
  getBuilderContentUrl, isBundleItem, hasContainer
}) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [showDescription, setShowDescription] = useState(false)
  const [showAttributes, setShowAttributes] = useState(false)
  const [showStoreLocations, setShowStoreLocations] = useState(false)
  const productDescription = getTranslatableField(product, 'description', appProps.currentLocale, false)

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)
    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [])

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      setMenuOpen(false)
      setShowDescription(false)
      setShowAttributes(false)
      setShowStoreLocations(false)
    }
  }, [])

  function renderDefaultBottomSection() {
    return (
      <>
        <div id='product_page_above_description'></div>
        <div className={ `product-show-bottom-wrapper ${productPageBottomLayout}` }>
          { productDescription && productDescription != '<p></p>' &&
            <div className="section-wrapper">
              <div className="section description main-container">
                <div className="section-content">
                  <h3>{ appProps.translations.products.description }</h3>
                  <ProductDescription
                    description={ productDescription }
                  />
                </div>
              </div>
            </div>
          }
          <div id='product_page_above_product_specs'></div>
          { product.attributes.length > 0 && product.attributes.filter(attribute => {
              let attributeTitle = getTranslatableField(attribute, 'title', appProps.currentLocale, false)
              return attributeTitle && attributeTitle !== ''
            }).length > 0 &&
              <div className="section-wrapper">
                <div className="section attributes main-container">
                  <div className="section-content">
                    <h3>{ appProps.translations.products.attributes }</h3>
                    <Attributes
                      appProps={ appProps }
                      product={ product }
                      category={ category }
                    />
                  </div>
                </div>
              </div>
          }

          { appProps.showStoreLocations && !isBundleItem && product.storeLocations && product.storeLocations.length > 0 &&
            <div className="section-wrapper">
              <div className="section description main-container">
                <div className="section-content">
                  <h3>{ appProps.translations.products.store_availability }</h3>
                  <StoreLocations
                    appProps={ appProps }
                    storeLocations={ product.storeLocations }
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </>
    )
  }

  function renderTabbedBottomSection() {
    return (
      <div className={ `product-show-bottom-wrapper ${productPageBottomLayout}` }>
        <div className={`product-tabs ${hasContainer ? 'main-container': ''}`}>
          <Tabs>
            <TabList>
              { productDescription && productDescription != '<p></p>' &&
                <Tab>{ appProps.translations.products.description }</Tab>
              }
              { product.attributes.length > 0 && product.attributes.find( attribute => getTranslatableField(attribute, 'title', appProps.currentLocale, false) ) &&
                <Tab>{ appProps.translations.products.attributes }</Tab>
              }
              { appProps.showStoreLocations && !isBundleItem && product.storeLocations && product.storeLocations.length > 0 &&
                <Tab>{ appProps.translations.products.store_availability }</Tab>
              }
            </TabList>

            { productDescription && productDescription != '<p></p>' &&
              <TabPanel>
                <div id='product_page_above_description'></div>
                <ProductDescription
                  description={ productDescription }
                />
              </TabPanel>
            }
            <div id='product_page_above_product_specs'></div>
            { product.attributes.length > 0 && product.attributes.find( attribute => getTranslatableField(attribute, 'title', appProps.currentLocale, false) ) &&
              <TabPanel>
                <Attributes
                  appProps={ appProps }
                  product={ product }
                  category={ category }
                />
              </TabPanel>
            }

            { appProps.showStoreLocations && !isBundleItem && product.storeLocations && product.storeLocations.length > 0 &&
              <TabPanel>
                <StoreLocations
                  appProps={ appProps }
                  storeLocations={ product.storeLocations }
                />
              </TabPanel>
            }
          </Tabs>
        </div>
      </div>
    )
  }

  function renderOpenerBottomSection() {
    return (
      <div className={ `product-show-bottom-wrapper main-container ${productPageBottomLayout}` }>
        <div className='buttons-wrapper'>
          { productDescription && productDescription != '<p></p>' &&
            <button className='button' onClick={ () => {
                setMenuOpen(true)
                setShowDescription(true)
              }}>
              { appProps.translations.products.description }
            </button>
          }
          { product.attributes.length > 0 && product.attributes.find( attribute => getTranslatableField(attribute, 'title', appProps.currentLocale, false) ) &&
            <button className='button' onClick={ () => {
                setMenuOpen(true)
                setShowAttributes(true)
              }}>
              { appProps.translations.products.attributes }
            </button>
          }
          { appProps.showStoreLocations && product.storeLocations && product.storeLocations.length > 0 &&
            <button className='button' onClick={ () => {
                setMenuOpen(true)
                setShowStoreLocations(true)
              }}>
              { appProps.translations.products.store_availability }
            </button>
          }
        </div>
        <BurgerOpener
          appProps={ appProps }
          product={ product }
          showDescription={ showDescription }
          setShowDescription={ setShowDescription }
          showAttributes={ showAttributes }
          setShowAttributes={ setShowAttributes }
          showStoreLocations={ showStoreLocations }
          setShowStoreLocations={ setShowStoreLocations }
          menuOpen={ menuOpen }
          setMenuOpen={ setMenuOpen }
          category={ category }
        />
      </div>
    )
  }

  let productBottomSection = null
  switch(productPageBottomLayout) {
    case 'tabbed':
      productBottomSection = renderTabbedBottomSection()
      break
    case 'opener':
      productBottomSection = renderOpenerBottomSection()
      break
    case 'none':
      productBottomSection = <div className={ `product-show-bottom-wrapper ${productPageBottomLayout}` }></div>
      break
    default:
      productBottomSection = renderDefaultBottomSection()
  }

  return (
    <>
      <div id='product_page_above_builder_content'></div>
      { hasBuilderContent &&
        <Content
          appProps={ appProps }
          getBuilderContentUrl={ getBuilderContentUrl }
        />
      }

      { productBottomSection }
    </>
  )
}
