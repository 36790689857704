import React from 'react'
import { Modal } from '@reactiveonline/frontend_shared_components'

export default function LineItemErrorsModal({ translations, lineItemErrors, handleLineItemErrors, automationErrors }) {

  function getErrorTranslationKey(lineItemError) {
    if (lineItemError.removed) {
      return 'line_item_removed'
    } else if (lineItemError.previousQuantity) {
      return 'line_item_changed_quantity'
    } else if (lineItemError.previousPrice) {
      return 'line_item_changed_price'
    } else if (lineItemError.changedTimesApplied) {
      return 'automation_changed_times_applied'
    } else if (lineItemError.removedAutomation) {
      return 'automation_removed'
    } else if (lineItemError.addedAutomation) {
      return 'automation_added'
    } 
  }

  return (
    <Modal
      visible
      mode='small'
      acceptAction={ handleLineItemErrors }
      saveText={ translations.general.confirm }
    >
      <div className='flex-box flex-column items-center'>
        { lineItemErrors.map( (lineItemError, index) => (
          <div
            key={ index }
            className='flex-box flex-column items-center error-wrapper'
          >
            <div className='error-heading'>
              { translations.checkout[getErrorTranslationKey(lineItemError)] }
            </div>

            <div className='flex-box'>
              <div className='error-title'>
                { lineItemError.title }
              </div>

              { lineItemError.previousQuantity &&
                <div className='error-info'>
                  ({ lineItemError.previousQuantity } <i className='fa-light fa-arrow-right'/> { lineItemError.currentQuantity })
                </div>
              }

              { lineItemError.previousPrice &&
                <div className='error-info'>
                  ({ lineItemError.previousPrice } <i className='fa-light fa-arrow-right'/> { lineItemError.currentPrice })
                </div>
              }
            </div>
          </div>
        ))}
        { automationErrors.map( (automationError, index) => (
          <div
            key={ index }
            className='flex-box flex-column items-center error-wrapper'
          >
            <div className='error-heading'>
              { translations.checkout[getErrorTranslationKey(automationError)] }
            </div>
          </div>
        ))}
      </div>
    </Modal>
  )
}
