import React from "react"
import ProductDescription from "./ProductDescription"
import Attributes from "./Attributes"
import StoreLocations from "./StoreLocations"
import { getTranslatableField } from "../../helpers/utils"

export default function BurgerOpener({
  appProps, product, showDescription, setShowDescription, showAttributes, setShowAttributes,
  showStoreLocations, setShowStoreLocations, menuOpen, setMenuOpen, category
}) {
  const productDescription = getTranslatableField(product, 'description', appProps.currentLocale, false)

  return (
    <>
      { menuOpen &&
        <>
          { menuOpen &&
            <div className='burger-menu-overlay'
                 onClick={ () => {
                   setMenuOpen(false)
                   setShowDescription(false)
                   setShowAttributes(false)
                   setShowStoreLocations(false)
                 }}
            />
          }
          <div className='burger-menu'>
            <div onClick={ () => {
                  setMenuOpen(false)
                  setShowDescription(false)
                  setShowAttributes(false)
                  setShowStoreLocations(false)
                 }}
                 className='pointer close_icon flex-box items-center content-center' />
            <div>
              <div id='product_page_above_description'></div>
              { showDescription &&
                <div className="section description">
                  <div className="title">
                    { appProps.translations.products.description }
                  </div>
                  <ProductDescription
                    description={ productDescription }
                  />
                </div>
              }
              <div id='product_page_above_product_specs'></div>
              { showAttributes &&
                <div className="section attributes">
                  <div className="title">
                    { appProps.translations.products.attributes }
                  </div>
                  <Attributes
                    appProps={ appProps }
                    product={ product }
                    category={ category }
                  />
                </div>
              }

              { showStoreLocations &&
                <div className="section store_locations">
                  <div className="title">
                    { appProps.translations.products.store_availability }
                  </div>
                  <StoreLocations
                    appProps={ appProps }
                    storeLocations={ product.storeLocations }
                  />
                </div>
              }
            </div>
          </div>
        </>
      }
    </>
  )
}
