import React from "react"

export default class IncompleteStep extends React.Component {


  render(){
    const { stepTitle } = this.props

    return(
      <div className="checkout-selected-step bullet_icon incomplete">
        <div className="selection-name">
          { stepTitle }
        </div>
        <div className="selection-option">
          <div>
          </div>
          <div className="change-option">
          </div>
        </div>
      </div>
    )
  }
}
