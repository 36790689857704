import React from "react"

export default function Image({ image, linkTo, extraClass }) {
  return (
    <div>
      { linkTo ?
        <a href={ linkTo }>
          <img  src={ image ? image.attachment_source : 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg' }
                className={ extraClass || '' }
                alt={ image ? image.attachment_alt : 'no image'}
          />
        </a> :
        <img  src={ image ? image.attachment_source : 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg' }
              className={extraClass || ''}
              alt={ image ? image.attachment_alt : 'no image' }/>
      }
    </div>
  )
}
