import React, { useState, useEffect } from 'react'
import ProductItem from '../../products/ProductItem'

export function Product({ appProps, item }) {
  const [loading, setLoading] = useState([])
  const [products, setProducts] = useState([])
  const settings = item.contentSettings

  useEffect( () => {
    if (settings.selectedProducts.length) {
      setLoading(true)

      $.ajax({
        type: 'GET',
        url: appProps.selectedProductsPath,
        data: {
          items: JSON.stringify(settings.selectedProducts)
        },
        dataType: 'json',
        success: res => {
          setProducts(res.items)
          setLoading(false)
        },
        error: res => {
          setLoading(false)
        },
      })
    }
  }, [])

  return (
    <div className={`builder-product ${settings.viewType}`}>
      { products.map( (product, index) => (
        <ProductItem
          appProps={ appProps }
          key={ index }
          product={ product }
        />
      ))}
    </div>
  )
}
