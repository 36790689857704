import React, { useState, useEffect } from "react"
import Select from "react-select"
import { customReactSelectStyles, customReactSelectTheme, getTranslatableField } from "../../helpers/utils"

export default function Options({
  appProps, loading, setLoading, productId, updateStateProduct, updateStateSelectedOptionsIds, optionTypes, fallbackTranslations, lineItemOptions
}) {

  const [selectedOptions, setSelectedOptions] = useState([])
  const optionTypeClasses = {
    0: 'size',
    1: 'radio',
    2: 'checkbox',
    3: 'dropdown'
  }

  useEffect(() => {
    let selectedOptions = []

    if(lineItemOptions){
      lineItemOptions.map(option => {
        const optionType = optionTypes.filter( optionType => optionType.name === option.optionTypeName)[0]
        let defaultOption = optionType.options.filter(opt => opt.id == option.id)[0]
        if(defaultOption) {
          selectedOptions.push(defaultOption)
        }
      })
    }
    else{
      optionTypes.map(optionType => {
        if(!optionType.doNotPreSelectDefaultValue) {
          let defaultOption = optionType.options.filter(option => option.default)[0]
          if(defaultOption) {
            selectedOptions.push(defaultOption)
          }
        }
      })
    }

    if(selectedOptions.length > 0) {
      adjustProductPrice(selectedOptions)
    }
    updateStateSelectedOptionsIds(selectedOptions.map(opt => opt.id))
    setSelectedOptions(selectedOptions)
  }, []);

  function setSelected(option, onlyOneAllowed, optionIds) {
    if(loading) return false
    let _selectedOptions = [...selectedOptions]
    if(isSelected(option)) {
      if(!onlyOneAllowed) {
        _selectedOptions = _selectedOptions.filter(sopt => option.id !== sopt.id)

      }
    } else {
      if(onlyOneAllowed) {
        _selectedOptions = _selectedOptions.filter(sopt => optionIds.indexOf(sopt.id) < 0)
      }
      _selectedOptions.push(option)
    }

    adjustProductPrice(_selectedOptions)
    updateStateSelectedOptionsIds(_selectedOptions.map(opt => opt.id))
    setSelectedOptions(_selectedOptions)
  }

  function isSelected(option) {
    return !!selectedOptions.filter(sopt => option.id === sopt.id)[0]
  }

  function adjustProductPrice(selectedOptions) {
    setLoading()

    let fd = new FormData()
    fd.append("appliedOptions", JSON.stringify(selectedOptions))

    Rails.ajax({
      type: "POST",
      url: `/products/${productId}/adjust_price`,
      contentType: "application/json; charset=UTF-8",
      dataType: "json",
      data: fd,
      success: res => {
        updateStateProduct(res)
      },
      error: res => {

      }
    })
  }

  function renderOptions(optionType) {
    const optionTypeOptionIds = optionType.options.map(opt => opt.id)

    switch(optionType.inputType) {
      case 0:
        return (
          <React.Fragment>
            {
              optionType.options.map((option, optionIndex) => {
                return renderSizeOption(option, optionIndex, true, optionTypeOptionIds)
              })
            }
          </React.Fragment>
        )
      case 1:
        return (
          <React.Fragment>
            {
              optionType.options.map((option, optionIndex) => {
                return renderRadioOption(option, optionIndex, true, optionTypeOptionIds)
              })
            }
          </React.Fragment>
        )
      case 2:
        return (
          <React.Fragment>
            {
              optionType.options.map((option, optionIndex) => {
                return renderCheckboxOption(option, optionIndex, false, optionTypeOptionIds)
              })
            }
          </React.Fragment>
        )
      case 3:
        return renderDropdownOptions(optionType.doNotPreSelectDefaultValue, optionType.options, optionTypeOptionIds)
    }
  }

  function renderSizeOption(option, index, onlyOneAllowed, optionIds) {
    return (
      <div className={`product-option option-${isSelected(option) ? '' : 'un'}checked`} key={index} onClick={() => {
        setSelected(option, onlyOneAllowed, optionIds)
      }}>
        { renderOptionName(option) }
      </div>
    )
  }

  function renderRadioOption(option, index, onlyOneAllowed, optionIds) {
    return (
      <div className="product-option" key={index} onClick={() => {
        setSelected(option, onlyOneAllowed, optionIds)
      }}>
        <div className={`option-${isSelected(option) ? '' : 'un'}checked`}></div>
        {renderOptionName(option)}
      </div>
    )
  }

  function renderCheckboxOption(option, index, onlyOneAllowed, optionIds) {
    return (
      <div className="product-option" key={index} onClick={() => {
        setSelected(option, onlyOneAllowed, optionIds)
      }}>
        <div className={`option-${isSelected(option) ? '' : 'un'}checked`}></div>
        {renderOptionName(option)}
      </div>
    )
  }

  function renderDropdownOptions(doNotPreSelectDefaultValue, options, optionIds) {
    let defaultValue = {value: null, label: '...'}
    if(!doNotPreSelectDefaultValue) {
      let defaultOption = options.filter(option => option.default)[0]
      if(defaultOption) {
        defaultValue = {value: defaultOption.id, label: renderOptionName(defaultOption)}
      }
    }

    return (
      <Select
        theme={customReactSelectTheme}
        styles={customReactSelectStyles}
        isSearchable={ false }
        options={options.map(o => {
          return {value: o.id, label: renderOptionName(o)}
        })}
        defaultValue={defaultValue}
        onChange={(selected) => {
          let _selectedOptions = [...selectedOptions]
          if(!!_selectedOptions.filter(sopt => selected.value === sopt.id)[0]) {
            _selectedOptions = _selectedOptions.filter(sopt => selected.value !== sopt.id)
          } else {
            _selectedOptions = _selectedOptions.filter(sopt => optionIds.indexOf(sopt.id) < 0)
            _selectedOptions.push(options.filter(opt => opt.id === selected.value)[0])
          }

          if(_selectedOptions.length > 0) {
            adjustProductPrice(_selectedOptions)
          }
          updateStateSelectedOptionsIds(_selectedOptions.map(opt => opt.id))
          setSelectedOptions(_selectedOptions)
        }}
      />
    )
  }

  function renderOptionName(option) {
    if(option.priceValue > 0) {
      return `${getTranslatableField(option, 'title', appProps.currentLocale, fallbackTranslations)} (${option.displayPriceValue})`
    } else {
      return getTranslatableField(option, 'title', appProps.currentLocale, fallbackTranslations)
    }
  }

  return (
    <div className="product-options">
      {
        optionTypes.map((optionType, index) => {
          return (
            <div className="product-option-wrapper" key={index}>
              <div className="option-type-title-wrapper">
                { getTranslatableField(optionType, 'title', appProps.currentLocale, fallbackTranslations) }
              </div>
              <div id={`option_type_${optionType.id}`} className={`option-types-${optionTypeClasses[optionType.inputType]}-container`}>
                {
                  renderOptions(optionType)
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}
