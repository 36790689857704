import React, { useEffect } from "react"
import ReactGA from "react-ga4"

export default function GoogleTracking({ order, currency }) {
  useEffect( ()=> {
    ReactGA.event('purchase', {
      transaction_id: order.number,
      value: order.total_value,
      tax: order.vat_total_value,
      shipping: order.shipping_cost ? order.shipping_cost : 0,
      currency: currency,
      items: [
        order.line_items.map( (lineItem, index) => {
          return (
            {
              item_id: lineItem.sellable.sku,
              item_name: lineItem.sellable.presentation,
              index: index,
              item_brand: "Google",
              item_category: lineItem.sellable.prototypeName,
              price: lineItem.totalGross,
              quantity: lineItem.quantity
            }
          )
        })
      ]
    });
  },[])

  return(
    <React.Fragment/>
  )

}
