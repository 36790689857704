import React from 'react'

export default function InformationIcon({ text }) {

  return (
    <div className='information-text'>
      { text }
    </div>
  )
}
