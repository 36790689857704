import React from "react"

export default class CompletedStep extends React.Component {
  render(){
    const { stepName, stepTitle, reviseStep, changeText, selectedValues } = this.props

    return(
      <div className="checkout-selected-step checked_icon" onClick={()=> { reviseStep(stepName)}} >
        <div className="selection-name flex-box flex-column">
          <div>{ stepTitle }</div>
          <div style={{ fontSize: 12, fontWeight: 300 }}>
            { selectedValues }
          </div>
        </div>
        <div className="selection-option content-end">
          <div className="change-option">
            { changeText }
          </div>
        </div>
      </div>
    )
  }
}
