import React, { useState, useEffect, lazy, Suspense } from 'react'
import { Loader } from '@reactiveonline/frontend_shared_components'
import General from '../companies/steps/General'
import Billing from '../companies/steps/Billing'

export default function CreateCompany({
  appProps, screenClass, pageName, pageTitle, countriesPath, createCompanyPath,
  requirePhone, requireMobilePhone
}) {
  const [companyState, _setCompanyState] = useState({billingAddress: {}})
  const [loading, setLoading] = useState(false)

  function setCompanyState(updatedState) {
    _setCompanyState({ ...companyState, ...updatedState })
  }

  function setCompanyAddress(updatedAddress) {
    const newAddress = { ...companyState.billingAddress, ...updatedAddress }
    setCompanyState({ billingAddress: newAddress })
  }

  function stepValidations() {
    if( (requirePhone && (!companyState.billingAddress.phone || companyState.billingAddress.phone.trim() === '')) ||
        (requireMobilePhone && (!companyState.billingAddress.mobilePhone || companyState.billingAddress.mobilePhone.trim() === '')) ||
        (!companyState.name || companyState.name.trim() === '') ||
        (!companyState.vat || companyState.vat.trim() === '')) {
      appProps.flashMessage.show(appProps.translations.flash_messages.fill_all_required_fields, 'error')
    } else {
      save()
    }
  }

  function save() {
    setLoading(true)
    let formData = new FormData()
    formData.append('company[name]', companyState.name)
    formData.append('company[vat]', companyState.vat || '')
    formData.append('company[internal_notes]', companyState.internalNotes || '')

    if (companyState.billingAddress) {
      formData.append('company[address_attributes][full_name]', companyState.name || '')
      formData.append('company[address_attributes][street]', companyState.billingAddress.address || '')
      formData.append('company[address_attributes][phone]', companyState.billingAddress.phone || '')
      formData.append('company[address_attributes][mobile_phone]', companyState.billingAddress.mobilePhone || '')
      formData.append('company[address_attributes][city]', companyState.billingAddress.city || '')
      formData.append('company[address_attributes][postal_code]', companyState.billingAddress.postalCode || '')
      formData.append('company[address_attributes][country_numeric_code]', companyState.billingAddress.country || '')
      formData.append('company[address_attributes][state_region_code]', companyState.billingAddress.state || '')
      formData.append('company[address_attributes][usage]', 'billing')
    }

    Rails.ajax({
      type: 'POST',
      url: createCompanyPath,
      data: formData,
      dataType: 'json',
      success: res => {
        if (res.redirectUrl) {
          window.location.href = res.redirectUrl
        } else {
          setLoading(false)
        }
      },
      error: res => {
        setLoading(false)
        if (res.error) {
          appProps.flashMessage.show(res.error, 'error')
        }
      }
    })
  }

  return (
    <>
      <div className='section-title'>
        <h3>{ pageTitle }</h3>
      </div>

      <div className={ `companies-wrapper ${pageName}` }>
        <div className="card">
          <General
            appProps={ appProps }
            company={ null }
            companyState={ companyState }
            setCompanyState={ setCompanyState }
            loading={ loading }
            setLoading={ setLoading }
          />

          <Billing
            appProps={ appProps }
            company={ null }
            companyState={ companyState }
            setCompanyState={ setCompanyState }
            setCompanyAddress={ setCompanyAddress }
            countriesPath={ countriesPath }
            loading={ loading }
            requirePhone={ requirePhone }
            requireMobilePhone={ requireMobilePhone }
            setLoading={ setLoading }
          />

          <div className="button-wrapper" style={{ marginTop: 30, marginLeft: 5 }}>
            <a className='button' onClick={ () => stepValidations() }>
              { appProps.translations.company.create_company }
            </a>
          </div>

          { loading &&
            <Loader
              absolute
              size={ 'large' }
              position={ 'center' }
              fullPage
            />
          }
        </div>
      </div>
    </>
  )
}
