import debounce from "lodash.debounce"

export function getCookieExpirationDate(days) {
  const expiration = new Date()
  expiration.setDate(expiration.getDate() + days)
  return expiration
}

export function truncateString(str, num) {
  if(!str) {
    return ''
  }

  if(str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}

export function numberValidator(value) {
  const regex = /^[0-9()+-]+$/g
  return value.match(regex)
}

export const customReactSelectStyles = {
  option: (provided, state) => {
    let color = '#222222'
    let background = '#ffffff'
    if(state.isSelected) {
      color = '#ffffff'
      background = '#666666'
    } else if(state.isFocused) {
      background = '#f7f7f7'
    }

    return {
      ...provided,
      color: color,
      background: background
    }
  },
  control: (provided, state) => ({
    ...provided,
    height: 40,
    minHeight: 40,
    border: '1px solid hsl(0,0%,80%)',
    boxShadow: state.isFocused ? 0 : provided.boxShadow,
    '&:hover': {
       border: '1px solid hsl(0,0%,80%)'
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 40
  })
}

export function customReactSelectTheme(theme) {
  return {
    ...theme,
    colors: {
    ...theme.colors,
      primary50: '#f7f7f7'
    }
  }
}

export function blogImageUrls(imagesArray) {
  const blogImages = imagesArray.length > 0 ? imagesArray.map( image => {
      return {
        original: image.source,
        thumbnail: '',
        fullscreen: '',
      }
    }
    ) : [{
      original: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
      thumbnail: '',
      fullscreen: ''
    }]
  return ( blogImages )
}

export function constructProductsListUrl(params, categorySlug, appliedFilterOptions, locale = null) {
  const localePrefix = locale ? `/${locale}` : ''
  let url = `${localePrefix}/products`

  let objectKeys = []
  for (let key in appliedFilterOptions) {
    objectKeys.push(key)
  }

  let objectValues = objectKeys.map(key => {
    return appliedFilterOptions[key]
  })

  const formattedValues = objectValues.map(value => {
    return value.join('+')
  }).join('++')

  const formattedKeys = objectKeys.join('+')

  if(categorySlug) {
    url += `/c/${categorySlug}`
  }

  if(formattedValues !== '' && formattedKeys !== '') {
    url += `/f/${formattedValues}/${formattedKeys}`
  }

  url += '?'

  for(let k in params) {
    if(params[k]) {
      url += `${k}=${params[k]}&`
    }
  }

  if(url[url.length-1] === '&') {
    url = url.slice(0, -1)
  }

  return url
}

export function debounceEventHandler(...args) {
  const debounced = debounce(...args)
  return (e) => {
    if(typeof e.persist === 'function') e.persist()
    return debounced(e)
  }
}

export function formatPriceWithCurrency(amount, currency) {
  if(currency && currency.symbol_first) {
    return `${currency.symbol}${amount}`
  } else if(currency) {
    return `${amount} ${currency.symbol}`
  } else {
    return `€${amount}`
  }
}

export function urlParamsAsObject(queryString) {
  const urlParams = new URLSearchParams(queryString)
  const entries = urlParams.entries()

  let params = {}
  for(const entry of entries) {
    params[entry[0]] = entry[1]
  }

  return params
}

function rangeNumbers(from, to) {
  let result = []
  for (let i = from; i <= to; i++) {
    result.push(i)
  }
  return result
}

// EXISTS FOR FRONTEND COMPONENTS | DUPLICATE ON SHARED ADMIN PACKAGES
export function generateYoutubeLinkWithAttributes(videoId, videoSettings) {
  let link = `https://www.youtube.com/embed/${ videoId }?`
  Object.keys(videoSettings).map( attribute => {
    switch (attribute) {
      case 'showRelated':
        link += `&rel=${ videoSettings[attribute] ? 1 : 0 }`
        break
      case 'showCaptions':
        link += videoSettings[attribute] ? '&cc_load_policy=1&cc_lang_pref=en' : ''
        break
      case 'autoplay':
        link += `&autoplay=${ videoSettings[attribute] ? 1 : 0 }`
        break
      case 'loop':
        link += videoSettings[attribute] ? `&loop=1&playlist=${ videoId }` : ''
        break
      case 'mute':
        link += `&mute=${ videoSettings[attribute] ? 1 : 0 }`
        break
      case 'controls':
        link += `&controls=${ videoSettings[attribute] ? 1 : 0 }`
        break
      case 'showFullscreen':
        link += `&fs=${ videoSettings[attribute] ? 1 : 0 }`
        break
      case 'showLogo':
        link += `&modestbranding=${ !videoSettings[attribute] ? 1 : 0 }`
        break
    }
  })

  return link
}

export function getInitialTranslations(item) {
  let initialTranslations = {}
  item.translations.map( translation => {
    initialTranslations[translation.locale] = translation
  })

  return initialTranslations
}

export function getTranslatableField(entity, field, currentLocale, fallback = false) {
  if(entity.translations) {
    let currentTranslatableFields
    if(currentLocale) {
      currentTranslatableFields = entity.translations.filter(t => t.locale === currentLocale)[0]
    } else {
      currentTranslatableFields = entity.translations[0]
    }

    const firstTranslatableFields = entity.translations.filter(t => t[field])[0]

    if(!fallback) {
      if(currentTranslatableFields) {
        return currentTranslatableFields[field] || ''
      } else {
        return ''
      }
    } else {
      if(currentTranslatableFields && currentTranslatableFields[field]) {
        return currentTranslatableFields[field] || ''
      } else if(firstTranslatableFields) {
        return firstTranslatableFields[field] || ''
      }
    }
  }

  return ''
}

export function constructTranslatableProductLink(entity, currentLocale, localeUrlPresent) {
  return `${localeUrlPresent ? '/' + currentLocale : ''}/products/${getTranslatableField(entity, 'slug', currentLocale, false)}`
}
