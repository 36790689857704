import React, { useEffect } from "react"
import cookie from "react-cookies"

export default function ListStyles({ productsPerRow }) {

  useEffect(() => {
    if ( productsPerRow == 1 ){
      let item = document.querySelectorAll('div.product-item');
      for (let elem of item) {
        elem.classList.add('list');
      }
    }
  }, []);

  let productListStyles = null
    if ( productsPerRow == 1) {
      productListStyles =
      <style dangerouslySetInnerHTML={{__html: `
        .product-list .product-item {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          width: 100%;
        }

        .product-list .product-item .product-image {
          width: 30%;
          margin-right: 5%;
        }
        .product-item .product-details {
          width: 65%;
        }

        @media screen and (min-width: 600px) {
          .product-list .product-item .product-image {
            width: 40%;
          }
          .product-item .product-details {
            width: 60%;
          }
        }
      `}} />
    } else if ( productsPerRow == 2) {
      productListStyles =
      <style dangerouslySetInnerHTML={{__html: `
        @media screen and (min-width:600px) {
          .product-list .product-item {
            width: calc( 95% / 2 );
            margin-right: calc( 5% / 2 );
          }
          .product-list .product-item:last-child {
            margin-right: 0px;
          }
        }
      `}} />
    } else if ( productsPerRow == 3) {
      productListStyles =
      <style dangerouslySetInnerHTML={{__html: `
        @media screen and (min-width:600px) {
          .product-list .product-item {
            width: calc( 95% / 2 );
            margin-right: calc( 5% / 2 );
          }
          .product-list .product-item:last-child {
            margin-right: 0px;
          }
        }
        @media screen and (min-width:850px) {
          .product-list .product-item {
            width: calc( 95% / 3 );
            margin-right: calc( 5% / 3 );
          }
          .product-list .product-item:last-child {
            margin-right: 0px;
          }
        }
      `}} />
    } else if ( productsPerRow == 4) {
      productListStyles =
      <style dangerouslySetInnerHTML={{__html: `
        @media screen and (min-width:600px) {
          .product-list .product-item {
            width: calc( 95% / 2 );
            margin-right: calc( 5% / 2 );
          }
          .product-list .product-item:last-child {
            margin-right: 0px;
          }
        }
        @media screen and (min-width:768px) {
          .product-list .product-item {
            width: calc( 95% / 3 );
            margin-right: calc( 5% / 3 );
          }
          .product-list .product-item:last-child {
            margin-right: 0px;
          }
        }
        @media screen and (min-width:992px) {
          .product-list .product-item {
            width: calc( 95% / 3 );
            margin-right: calc( 5% / 3 );
          }
          .product-list .product-item:last-child {
            margin-right: 0px;
          }
        }
        @media screen and (min-width:1200px) {
          .product-list .product-item {
            width: calc( 95% / 4 );
            margin-right: calc( 5% / 4 );
          }
          .product-list .product-item:last-child {
            margin-right: 0px;
          }
        }
      `}} />
    } else if ( productsPerRow == 5) {
      productListStyles =
      <style dangerouslySetInnerHTML={{__html: `
        @media screen and (min-width:600px) {
          .product-list .product-item {
            width: calc( 95% / 2 );
            margin-right: calc( 5% / 2 );
          }
          .product-list .product-item:last-child {
            margin-right: 0px;
          }
        }
        @media screen and (min-width:768px) {
          .product-list .product-item {
            width: calc( 95% / 3 );
            margin-right: calc( 5% / 3 );
          }
          .product-list .product-item:last-child {
            margin-right: 0px;
          }
        }
        @media screen and (min-width:992px) {
          .product-list .product-item {
            width: calc( 95% / 4 );
            margin-right: calc( 5% / 4 );
          }
          .product-list .product-item:last-child {
            margin-right: 0px;
          }
        }
        @media screen and (min-width:1200px) {
          .product-list .product-item {
            width: calc( 95% / 5 );
            margin-right: calc( 5% / 5 );
          }
          .product-list .product-item:last-child {
            margin-right: 0px;
          }
        }
      `}} />
    }

  return ( productListStyles )

}
