import React, { useState } from 'react'
import { InputField } from '@reactiveonline/frontend_shared_components'

export default function General({
  appProps, company, companyState, setCompanyState, saveSettings, loading
}) {
  let canSave = true
  if(company) {
    canSave = (company.name != companyState.name) || (company.internalNotes != companyState.internalNotes)
  }
  const canEditFields = !company || (company && appProps.user.isCompanyMaster && !appProps.user.companyActive)

  function stepValidations() {
    if (companyState.name && companyState.name.trim() !== '') {
      saveSettings()
    } else {
      appProps.flashMessage.show(appProps.translations.flash_messages.fill_all_required_fields, 'error')
    }
  }

  return (
    <>
      <div className="heading" style={{ marginBottom: 20 }}>
        <h2>{ appProps.translations.company.general_tab }</h2>
        <div>{ appProps.user.isCompanyMaster && appProps.translations.company.general_tab_subtitle }</div>
      </div>

      <div>
       <InputField label={ `${ appProps.translations.company.name } *` }>
         <input
           type="text"
           value={ companyState.name || '' }
           disabled={ !canEditFields }
           onChange={ (event) => setCompanyState({ name: event.target.value }) }
         />
       </InputField>

       <InputField label={ appProps.translations.company.notes }>
         <textarea
           type='text'
           className={ 'custom-textarea' }
           value={ companyState.internalNotes || '' }
           disabled={ !canEditFields }
           onChange={ event => setCompanyState({ internalNotes: event.target.value }) }
         />
       </InputField>
      </div>

      { canEditFields && company &&
        <div className="button-wrapper" style={{ marginTop: 30 }}>
          { canSave ?
            <a className='button' onClick={ () => stepValidations() }>
              { appProps.translations.general.save }
            </a>
            :
            <div className='button disabled'>
              { appProps.translations.general.save }
            </div>
          }
        </div>
      }
    </>
  )
}
