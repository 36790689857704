import React from "react"
import TrackOrder from "../../orders/TrackOrder"

export default function OrderTracking({ pageName, pageTitle, appProps }) {

  return (
    <>
      <div className={ pageName }>
        <div className='section-title'>
          <h3>{ pageTitle }</h3>
        </div>
        <TrackOrder
          appProps={ appProps }
          isAccountScreen={ true }
        />
      </div>
    </>
  )
}
