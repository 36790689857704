import React from "react"

import { constructProductsListUrl } from "../../helpers/utils"
import { getPaginationOptions } from '@reactiveonline/reactive_admin_shared-packages/utils'

export default function Pagination({
  appProps, productParams, categorySlug
}) {

  function getPagesListItems(listItemsArray) {
    const currentPage = parseInt(productParams.page)

    return (listItemsArray.map( itemPage => {
      const urlParams = {
        keyword: productParams.keyword,
        order_by: productParams.orderBy,
        order: productParams.order,
        page: itemPage,
        per_page: productParams.perPage
      }
      if (productParams.upperPriceLimit) {
        urlParams.upper_price_limit = productParams.upperPriceLimit
      }
      if (productParams.lowerPriceLimit) {
        urlParams.lower_price_limit = productParams.lowerPriceLimit
      }
      
      return (
        <li key={ itemPage }>
          { itemPage > 0 && itemPage === currentPage &&
            <span className="page_numbers current-page">{ currentPage }</span>
          }

          { itemPage > 0 && itemPage !== currentPage &&
            <a className="page_numbers"
                href={ constructProductsListUrl(urlParams, productParams.categoryMenusSlugs, productParams.filters, appProps.localeUrlPresent ? appProps.currentLocale : null) }>
              { itemPage }
            </a>
          }

          { itemPage < 0 &&
            <span className="page_numbers">...</span>
          }
        </li>
      )
    }))
  }

  const currentPage = parseInt(productParams.page)
  let pagesListItems = getPagesListItems(getPaginationOptions(currentPage, productParams.totalPages))

  let pageList =
    <ul className="page_numbers">
      { pagesListItems }
    </ul>

  return (
    <section className="page-pagination">
      <nav className="page-navigation">
        { productParams.totalPages > 1 && pageList}
      </nav>
    </section>
  )
}
