import React from 'react'
import { InputField } from '@reactiveonline/frontend_shared_components'

export default function Files({
  appProps, companyState, files, setFiles, saveSettings, loading, setLoading, fileInputRef, destroyFilePath, deleteFile
}) {
  const canEditFields = appProps.user.isCompanyMaster && !appProps.user.companyActive

  return (
    <>
      <div className="heading" style={{ marginBottom: 20 }}>
        <h2>{ appProps.translations.company.files_tab }</h2>
        <div>{ appProps.translations.company.files_tab_subtitle }</div>
      </div>

      <h3>{ appProps.translations.company.uploaded }</h3>
      <div className="flex-box flex-column files-list">
        { companyState.files.length > 0 && companyState.files.map((file, index) => (
          <div key={ index } className='file-wrapper flex-box items-center content-space-between'>
            <div className="file-item">
              { file.title }
            </div>
            { canEditFields && <div className='remove_icon pointer' onClick={ ()=> deleteFile(file.id) }></div> }
          </div>
        ))}
      </div>

      <InputField>
        <input
          type='file'
          accept="application/pdf"
          multiple
          ref={ fileInputRef }
          disabled={ !canEditFields }
          onChange={ event => setFiles(event.target.files) }
        />
      </InputField>

      { canEditFields &&
        <div className="button-wrapper">
          { files.length > 0 ?
            <a className='button' onClick={ () => saveSettings() }>
              { appProps.translations.general.save }
            </a>
            :
            <div className='button disabled'>
              { appProps.translations.general.save }
            </div>
          }
        </div>
      }
    </>
  )

}
