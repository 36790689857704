import ReactPixel from "react-facebook-pixel"
import ReactGA from "react-ga"
import cookie from "react-cookies"
import { getTranslatableField } from "./utils"

const marketingCookies  = cookie.load("cookieSettings") && cookie.load("cookieSettings").marketingCookies === true
const statisticsCookies = cookie.load("cookieSettings") && cookie.load("cookieSettings").statisticsCookies === true

export function initiateLegacyCheckoutAnalytics(order, event, appProps) {
  if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
    ReactPixel.trackCustom('InitiateCheckout', {
      content_name: 'Initiated Checkout',
      value:    `${order.totalValue}`,
      items:    `${order.lineItems.length}`,
      products: `${order && order.lineItems.map((p) => {
          return `${p.sellable.title}, price: ${p.totalGross}, category: ${p.sellable.prototypeName ? p.sellable.prototypeName : ''}`
        })
      }`,
      orderNo:  `${order.number}`,
      currency: `${appProps.currency}`,
    });
  }
  if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
    ReactGA.ga('send', 'event', {
      eventCategory: 'Click Checkout',
      eventAction: 'Initiated Checkout',
      eventLabel: event.target.href
    });
  }
}

export function addToCartLegacyAnalytics(product, appProps){
  if ( cookie.load("cookieConsent") === "YES" && marketingCookies ) {
    ReactPixel.trackCustom('AddToCart', {
      content_name: `${ product.title }`,
      content_ids: product.id,
      content_type: "product",
      content_category: `${ product.prototypeName ? product.prototypeName : '' }`,
      value: `${product.totalPrice }`,
      currency: `${appProps.currency}`
    })
  }

  if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
    ReactGA.ga('require', 'ec');
    ReactGA.ga('ec:addProduct', {
      'name': `${ product.title }`,
      'category': `${ product.prototypeName ? product.prototypeName : '' }`,
      'id': `${product.id}`,
      'value': `${ product.totalPrice }`,
    });
    ReactGA.ga('ec:setAction', 'add');
    ReactGA.ga('send', 'event', 'Add to cart', 'click', 'addToCart');
  }
}

export function checkoutEmailLegacyAnalytics(){
  if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
    ReactGA.ga('send', {
      hitType: 'event',
      eventCategory: 'Checkout',
      eventAction: 'Customer email added',
      eventLabel: 'Customer (hidden)'
    })
  }
}

export function checkoutDeliveryMethodLegacyAnalytics(deliveryOption){
  if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
    ReactGA.ga('send', {
      hitType: 'event',
      eventCategory: 'Checkout',
      eventAction: 'Delivery method selected',
      eventLabel: `${deliveryOption}`
    })
  }
}

export function checkoutShippingMethodLegacyAnalytics(selectedMethod){
  if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
    ReactGA.ga('send', {
      hitType: 'event',
      eventCategory: 'Checkout',
      eventAction: 'Shipping method selected',
      eventLabel: `${selectedMethod.type}`
    })
  }
}

export function checkoutPaymentMethodLegacyAnalytics(selectedMethod){
  if ( cookie.load("cookieConsent") === "YES" && statisticsCookies ) {
    ReactGA.ga('send', {
      hitType: 'event',
      eventCategory: 'Checkout',
      eventAction: 'Payment method selected',
      eventLabel: `${selectedMethod.title}`
    })
  }
}
